import SocialConnectButton from "@pages/auth/influencer-onboarding/social/SocialConnectButton";
import React from "react";

interface TiktokConnectButtonProps {
  isConnected: boolean;
  iconOnly?: boolean;
  onClick: () => void;
  onConnect?: () => void;
}

const TiktokConnectButton = ({
  isConnected,
  iconOnly,
  onClick,
  onConnect,
}: TiktokConnectButtonProps) => {

  const TIKTOK_CLIENT_KEY =  import.meta.env.VITE_TIKTOK_CLIENT_KEY; 

  const connectTiktok = async () => {
    try {
      // Step 1: Get the current page URL to pass as originUrl

      // Step 2: Redirect the user to Tiktoks's OAuth page to authorize your app
      window.location.href = `https://www.tiktok.com/v2/auth/authorize?client_key=${TIKTOK_CLIENT_KEY}&scope=user.info.basic%2Cuser.info.username%2Cuser.info.stats%2Cuser.info.profile%2Cuser.account.type%2Cuser.insights%2Cvideo.list%2Cvideo.insights%2Ccomment.list%2Ccomment.list.manage%2Cvideo.publish%2Cvideo.upload%2Cbiz.spark.auth%2Cbiz.creator.info%2Cbiz.creator.insights%2Ctcm.order.update&response_type=code&redirect_uri=https%3A%2F%2Fapi.fohr.co%2Fapi%2Fv1%2Fwebhooks%2Ftiktok%2Fauthorization`

    } catch (error) {
      console.error("Failed to connect to Tiktok:", error);
    }
  };

  return (
    <SocialConnectButton
      onClick={() =>
        onConnect ? onConnect() : isConnected ? onClick() : connectTiktok()
      }
      name="Tiktok"
      icon="tiktok"
      iconOnly={iconOnly}
      isConnected={isConnected}
    />
  );
};

export default TiktokConnectButton;
