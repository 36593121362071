import * as React from "react";
import { useState, useEffect, useRef } from "react";

import placeholderImage from "@images/image_placeholder.png";
import FDropdown from "@components/FDropdown";
import FIcon from "@components/FIcon";

const BriefProductPost = ({
  product,
  editProduct = null,
  deleteProduct = null,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [hasReadMore, setHasReadMore] = useState<boolean>(false);
  const productNoteRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkOverflow = () => {
      if (productNoteRef.current) {
        const isOverflowing =
          productNoteRef.current.offsetHeight <
          productNoteRef.current.scrollHeight;
        setHasReadMore(isOverflowing);
      }
    };

    // Check overflow after the component mounts and images are loaded
    window.addEventListener("load", checkOverflow);
    checkOverflow(); // Also check on initial mount

    return () => {
      window.removeEventListener("load", checkOverflow);
    };
  }, []);

  const toggleExpand = (e) => {
    e.preventDefault();
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      className="border flex-col cursor-pointer flex w-full border-default_weak hover:border-neutral_600 mb-4 rounded-lg bg-white shadow-sm hover:shadow-md hover:border-black transition-all duration-300"
      onClick={() => editProduct(product)}
    >
      <div className="flex flex-row items-center justify-between px-6 py-4 pb-3 border-b border-default_weak">
        <div className="flex flex-row gap-2 items-center">
          <h4 className="block truncate text-xl">{product.name}</h4>
          <a
            href={
              product.url?.startsWith("http")
                ? product.url
                : `https://${product.url}`
            }
            target="_blank"
            className="block hover:underline text-dark_night_sky text-[22px] overflow-hidden text-ellipsis whitespace-nowrap flex-1"
          >
            <FIcon
              icon="external-link"
              size={12}
              color="black"
              className="-mt-1"
            />
          </a>
        </div>
        {editProduct && deleteProduct ? (
          <div>
            <FDropdown
              width="auto"
              height="30px"
              options={[
                { label: "Edit", value: "Edit" },
                { label: "Delete", value: "Delete" },
              ]}
              onChange={(event) => {
                if (event === "Edit" && editProduct) {
                  editProduct(product);
                } else if (event === "Delete" && deleteProduct) {
                  deleteProduct(product);
                }
              }}
              iconOnly={true}
              iconColor="#000F45"
              icon="ellipsis"
            />
          </div>
        ) : null}
      </div>
      <div className="flex flex-row gap-4 p-6">
        <div
          className="bg-cover bg-center bg-highlight_red h-[200px] w-[200px] min-w-[200px] rounded-lg"
          style={{
            backgroundImage: `url(${
              product.image_url ? `${product.image_url}` : placeholderImage
            })`,
          }}
        />
        <div className="flex flex-col justify-start w-full relative w-full">
          <div
            ref={productNoteRef}
            className={`overflow-hidden flex-col gap-0 ${
              isExpanded ? "" : "max-h-[200px]"
            }`}
          >
            <div
              className="text-dark_night_sky text-[15px]"
              dangerouslySetInnerHTML={{ __html: product.notes }}
            ></div>
          </div>
          {hasReadMore && (
            <div className="flex justify-center bg-[rgba(255,255,255,0.8)] -mt-[40px] py-[10px]">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  toggleExpand(e);
                }}
                className="text-black text-[15px]"
              >
                {isExpanded ? "Show less" : "Show more"}
              </button>
            </div>
          )}

          {/* <div className="flex flex-row justify-end gap-2 absolute -bottom-[10px] right-0">
					<button
						onClick={() => editProduct(product)}
						className="text-red  flex flex-row text-xs justify-center  w-fit px-2 py-1 items-center rounded gap-1 text-[15px] mt-2"
					>
						Delete
					</button>

					<button
						onClick={() => deleteProduct(product)}
						className="text-dark_night_sky  flex flex-row text-xs justify-center border border-light_border w-fit px-2 py-1 items-center rounded gap-1 text-[15px] mt-2"
					>
						Edit
					</button>
				</div> */}
        </div>
      </div>
    </div>
  );
};

export default BriefProductPost;
