import FSubNav from "@components/FSubNav";
import Heading from "@components/Heading";
import AmbassadorQuestions from "@pages/ambassadors/ambassador-questions";

import AmbassadorsOnboardingBrandSettings from "./Branding";
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { capitalize } from "lodash";
import FIcon from "@components/FIcon";

const NAV_ITEMS = [
  {
    value: "branding",
  },
  {
    value: "questions",
  },
];

const AmbassadorsOnboardingSettings = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const handleNavChange = (newValue: string) =>
    navigate(
      `/ambassadors/onboarding-settings${newValue === NAV_ITEMS[0].value ? "" : `/${newValue}`}`
    );

  const selectedNav =
    NAV_ITEMS.find(({ value }) => location.pathname.includes(value))?.value ||
    NAV_ITEMS[0].value;

  return (
    <div>
      <Link className="flex items-center mb-4" to={`/ambassadors`}>
        <FIcon icon="back" size={18} color="#000F45" />
        Go back to ambassadors
      </Link>
      <div className="flex items-center justify-between mb-8">
        <Heading title="Ambassador onboarding settings" />
      </div>
      <FSubNav
        items={NAV_ITEMS.map(({ value }) => ({
          label: capitalize(value),
          value,
          active: value === selectedNav,
        }))}
        selectedValue={selectedNav}
        onChange={handleNavChange}
      />
      <div className="pt-8">
        <Routes>
          <Route index element={<AmbassadorsOnboardingBrandSettings />} />
          <Route
            path="/questions/:question_id?"
            element={<AmbassadorQuestions />}
          />
        </Routes>
      </div>
    </div>
  );
};

export default AmbassadorsOnboardingSettings;
