import React, { useContext, useState } from "react";
import { useParams, Link } from "react-router-dom";

import FButton from "@components/FButton";
import { CampaignContext } from "@contexts/index";

import { getCampaignBrief } from "@api/CampaignBriefs/CampaignBriefs";

import AddParticipantsModal from "@pages/campaigns/components/AddParticipantsModal";

import ParticipantsByGroup from "./components/ParticipantsByGroup";
import UpdateParticipantGroupModal from "./components/UpdateParticipantGroupModal";
import { Campaign, ParticipantGroup } from "@types";
import toast from "react-hot-toast";
import FIcon from "@components/FIcon";

const CampaignParticipants = () => {
  const { campaign, setBriefs, setCampaign } = useContext(CampaignContext);
  const { campaign_id } = useParams<{ campaign_id: string }>();

  const [selectedItems, setSelectedItems] = useState([]);

  const [isCreateGroupModalVisible, setIsCreateGroupModalVisible] =
    useState(false);

  const [showAddParticipantsModal, setShowAddParticipantsModal] =
    useState(false);

  const [addingParticipantsForGroup, setAddingParticipantsForGroup] =
    useState(null);

  const { participants = [] } = campaign || {};

  const handleCreateParticipantGroup = async (updates: Partial<Campaign>) => {
    const campaignBriefs = await getCampaignBrief(campaign_id);

    setCampaign({ ...campaign, ...updates });
    setBriefs(campaignBriefs);

    toast.success("Participant group created");

    setIsCreateGroupModalVisible(false);
  };

  return (
    <div className="pt-[40px]">
      <div className="flex justify-between items-center pb-10">
        <h2 className="text-2xl">Participants ({participants.length})</h2>
        <div className="flex gap-4">
          <Link to={`/campaigns/${campaign_id}/participant-review`}>
            {participants.length > 0 ? (
              <FButton
                width="100%"
                label="Review"
                icon={{
                  name: "fancy-arrow-right",
                  size: 14,
                  color: "#000F45",
                  align: "left",
                  className: "ml-2",
                }}
              />
            ) : null}
          </Link>
          <FButton
            label="Create Group"
            primary={false}
            onClick={() => setIsCreateGroupModalVisible(true)}
          />
          <FButton
            label="Add Participants"
            primary
            iconLeft={{
              name: "plus",
              size: 12,
              color: "white",
              className: "-mt-1 mr-1",
            }}
            onClick={() =>
              setShowAddParticipantsModal(!showAddParticipantsModal)
            }
          />
        </div>
      </div>
      <div className="flex flex-col w-full">
        <ParticipantsByGroup
          campaign={campaign}
          onUpdateCampaign={(updates) =>
            setCampaign({ ...campaign, ...updates })
          }
          selectedItems={selectedItems}
          onChangeSelectedItems={setSelectedItems}
          onAddParticipants={(groupId: number) => {
            setAddingParticipantsForGroup(groupId);
            setShowAddParticipantsModal(true);
          }}
        />
        <div
          className="flex justify-center rounded-lg p-4 pb-3 bg-[#f5f0ee] shadow-sm text-center flex flex-row items-center justify-center gap-2 mb-4"
          onClick={() => setIsCreateGroupModalVisible(true)}
          role="button"
        >
          <FIcon icon="plus" size={12} color="black" className="-mt-1" />
          Create Participant Group
        </div>
      </div>
      {showAddParticipantsModal ? (
        <AddParticipantsModal
          defaultParticipantGroupId={addingParticipantsForGroup}
          onClose={() => setShowAddParticipantsModal(false)}
        />
      ) : null}
      {isCreateGroupModalVisible ? (
        <UpdateParticipantGroupModal
          campaign={campaign}
          group={{} as ParticipantGroup}
          onClose={() => setIsCreateGroupModalVisible(false)}
          onUpdated={handleCreateParticipantGroup}
        />
      ) : null}
    </div>
  );
};

export default CampaignParticipants;
