import React from "react";
import { capitalize } from "@utils/string_utilities";
import StackedBarChart from "@components/Graphs/StackedBarChart";
import { formatNumber } from "@utils/number_utilities";

interface ContentTypeBreakdownProps {
  posts: Record<string, number>;
  chartData: Array<{
    name: string;
    value: number;
  }>;
  parentLoading?: boolean;
}

const ContentTypeBreakdown = ({
  posts,
  chartData,
  parentLoading = false,
}: ContentTypeBreakdownProps) => {
  if (parentLoading) return null;

  const getHighestPostType = () => {
    return Object.entries(posts).reduce<string>((highest, [key, value]) => {
      if (!highest || posts[highest] < value) {
        return key;
      }
      return highest;
    }, "");
  };

  return (
    <div className="pr-8 mt-8">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-medium">Content Types</h2>
      </div>
      <div className="border border-light_border rounded-lg bg-light_red shadow-md">
        <div className="p-4 flex justify-between items-center border-b border-light_border">
          <h3 className="text-lg font-medium">Content Distribution</h3>
          <p className="text-lg">{capitalize(getHighestPostType())}</p>
        </div>
        <div className="space-y-4 p-4">
          <div className="flex justify-between">
            {Object.entries(posts).map(([key, value]) => (
              <div key={key} className="flex flex-col items-center">
                <span className="text-sm text-neutral_700">
                  {capitalize(key)}
                </span>
                <span className="text-sm font-medium">{ formatNumber(value)}</span>
              </div>
            ))}
          </div>
          <StackedBarChart
            data={chartData}
            showLegend={false}
            height={8}
            colors={["#47B37F", "#E47667"]}
          />
        </div>
      </div>
    </div>
  );
};

export default ContentTypeBreakdown;
