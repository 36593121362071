import React, { useContext, useState } from "react";
import { FSidebar } from "../components/FSidebar";
import { useLocation } from "react-router-dom";
import { LayoutContext, UserContext } from "@contexts/index";

import { Permission, Role } from "@constants/roles";

import Toaster from "@components/Toaster";
import { isEmpty } from "lodash";

export default function MainLayout({ children }) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const location = useLocation();

  const isFullScreenRoute = [
    "/card/",
    "/brief-preview/",
    "/participants/",
    "/brief/",
    "/bulletins/",
  ].some((path) => location.pathname.startsWith(path));

  const switchCollapsed = (state) => {
    setIsCollapsed(state);
  };

  const { can, user } = useContext(UserContext);

  const ambassadorSection = () => {
    const { email } = user;

    const items = [
      can(Permission.VIEW_AMBASSADORS_ACCEPTED) && {
        icon: "audience",
        name: "Collaborators",
        href: "/collaborators",
      },
      can(Permission.VIEW_AMBASSADORS_APPLICANTS) && {
        icon: "content",
        name: "Ambassadors",
        href: "/ambassadors",
        isSubmenuItem: true,
        isDefault: email && !email.endsWith("fohr.co"),
      },
    ].filter(Boolean);

    if (isEmpty(items)) {
      return null;
    }

    return {
      fixedBottom: false,
      collapsible: true,
      mainLink: {
        icon: "crown",
        name: "Community",
      },
      items,
    };
  };

  const { brand_id, memberships } = user;

  const currentMembership = memberships.find(
    (membership) => membership.brand_id === brand_id
  );

  const { role } = currentMembership || {};

  const settingsSection = {
    fixedBottom: true,
    collapsible: false,
    items: [
      [Role.FOHR_ADMIN, Role.SUPER_ADMIN].includes(role as Role)
        ? { name: "Admin", href: "/admin", icon: "influencers" }
        : null,
    ].filter(Boolean),
  };

  return (
    <>
      <Toaster />
      <LayoutContext.Provider
        value={{
          collapsed: isCollapsed,
          setCollapsed: setIsCollapsed,
          fullNavWidth: "244px",
          collapsedNavWidth: "66px",
        }}
      >
        <div className="flex relative max-w-full overscroll-x-auto overscroll-none">
          <FSidebar
            className="static sm:sticky top-0 self-start z-20 relative"
            bubbleCollapsed={(state) => {
              switchCollapsed(state);
            }}
            sections={[
              {
                fixedBottom: false,
                items: [
                  { name: "Home", href: "/home", icon: "home" },
                  { icon: "search", name: "Search", href: "/search" },
                  can(Permission.VIEW_CAMPAIGNS)
                    ? {
                        icon: "bookmark",
                        name: "Campaigns",
                        href: "/campaigns",
                      }
                    : null,
                  // { icon: "bulletins", name: "Bulletins", href: "/opportunities" },
                  // { icon: "email", name: "Briefs", href: "/briefs" },
                ].filter(Boolean),
              },
              // discoverySection,
              //   campaignsSection(),
              ambassadorSection(),
              {
                fixedBottom: false,
                items: [
                  {
                    icon: "gifts",
                    name: "Bulletins",
                    href: "/bulletins",
                  },
                  can(Permission.VIEW_GROUPS) && {
                    icon: "bulletins",
                    name: "Groups",
                    href: "/groups",
                  },

                  can(Permission.VIEW_OUTREACH)
                    ? { icon: "email", name: "Outreach", href: "/outreach" }
                    : null,
                  can(Permission.VIEW_REPORTS)
                    ? {
                        icon: "graph",
                        name: "Reports",
                        href: `/reports`,
                      }
                    : null,
                  {
                    icon: "chat",
                    name: "Notifications",
                    href: `/notifications`,
                  },
                ].filter(Boolean),
              },
              settingsSection,
            ].filter(Boolean)}
          />
          <div
            className={`w-full overscroll-none ${
              isCollapsed
                ? "max-w-[calc(100vw-66px)]"
                : "max-w-[calc(100vw-244px)]"
            }`}
          >
            <div
              className={`w-full overscroll-none ${
                isFullScreenRoute ? "" : "px-[15px] sm:px-[40px] mt-[40px] z-10"
              }`}
            >
              {children}
            </div>
          </div>
        </div>
      </LayoutContext.Provider>
    </>
  );
}
