import FDropdown from "@components/FDropdown";
import STATUSES, { CONTENT_REVIEW_STATUS } from "../statuses";
import FButton from "@components/FButton";
import { useEffect, useState } from "react";
import { FTextarea } from "@components/FInputs";

import { addComment, getComments } from "@api/Campaign/ContentReview";
import useAuth from "@hooks/useAuth";
import {
  Content,
  ContentReviewAsset,
  ContentReviewComment,
  ContentReviewContent,
} from "@types";
import ContentReviewComments from "./ContentReviewComments";
import toast from "react-hot-toast";
import CommentInput from "../../../../components/comments/CommentInput";
import ModalContainer from "@components/Modals/ModalContainer";
import FIcon from "@components/FIcon";
import { ShowMore } from "@re-dev/react-truncate";

import { isVideoByExtension } from "@utils/file";
import ContentHighlight from "@components/media/content-highlight";
import { sortBy } from "lodash";
import Comment from "@components/comments/Comment";

const getAssetsAsContent = (assets: ContentReviewAsset[]): Partial<Content>[] =>
  assets.map((asset) => {
    const { file_url, filename } = asset;

    const isVideo = isVideoByExtension(file_url, filename);

    return {
      video_url: isVideo && file_url,
      photo_url: !isVideo && file_url,
    };
  });

interface ContentReviewModalProps {
  onClose: () => void;
  post: ContentReviewContent;
  onUpdateStatus: (status: CONTENT_REVIEW_STATUS) => void;
  onReject: ({
    mediaFeedback,
    captionFeedback,
  }: {
    mediaFeedback: string;
    captionFeedback: string;
  }) => void;
  rejecting: boolean;
}

const ContentReviewModal = ({
  onClose,
  post,
  onUpdateStatus,
  onReject,
  rejecting,
}: ContentReviewModalProps) => {
  const [isRejectFormVisible, setRejectFormVisible] = useState(false);

  const [mediaFeedback, setMediaFeedback] = useState("");
  const [captionFeedback, setCaptionFeedback] = useState("");

  const [activeAssetIndex, setActiveAssetIndex] = useState(0);

  const [pendingComment, setPendingComment] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [isDownloadingAsset, setIsDownloadingAsset] = useState(false);

  const [selectedRound, setSelectedRound] = useState(post.rounds[0].id);

  const [isLoadingComments, setLoadingComments] = useState(false);
  const [comments, setComments] = useState<ContentReviewComment[]>([]);

  const [showCaption, setShowCaption] = useState(true);

  const { user } = useAuth();

  const { campaignParticipant, rounds, status } = post;

  const { creator } = campaignParticipant;

  const { full_name, profile_image_url, slug } = creator;

  const { feedbacks } = rounds.find(({ id }) => id === selectedRound);

  useEffect(() => {
    const loadComments = async () => {
      setLoadingComments(true);

      const response = await getComments(post.id);

      const comments = response.data.comments;

      setLoadingComments(false);
      setComments(comments);
    };

    loadComments();
  }, []);

  const handleApprove = () => onUpdateStatus(CONTENT_REVIEW_STATUS.APPROVED);

  const handleAddComment = async () => {
    setIsSubmitted(true);

    if (!pendingComment) {
      return;
    }

    const response = await addComment({
      content_id: post.id,
      round_id: selectedRound,
      brand_user_id: user.id,
      body: pendingComment,
    });

    const newComment = response.data;

    setPendingComment("");

    toast.success("Comment added");

    setIsSubmitted(false);

    setComments([newComment, ...comments]);
  };

  const handleCommentDeleted = (commentId: number) => {
    const newComments = comments.filter(({ id }) => id !== commentId);

    setComments(newComments);
  };

  const handleCommentUpdated = (commentId: number, body: string) => {
    const newComments = comments.map((comment) => {
      if (comment.id !== commentId) {
        return comment;
      }

      return {
        ...comment,
        body,
      };
    });

    setComments(newComments);
  };

  const handleDownload = () => {
    setIsDownloadingAsset(true);

    fetch(post.file_url)
      .then((response) => {
        const contentType = response.headers.get("content-type");

        return response.blob().then((blob) => {
          const fileName = post.caption.split(" ")[0];

          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download =
            contentType === "video/quicktime" ? `${fileName}.mov` : fileName;

          link.click();
        });
      })
      .catch(console.error)
      .finally(() => {
        setIsDownloadingAsset(false);
      });
  };

  const commentsForRound = comments.filter(
    ({ round_id }) => round_id === selectedRound
  );

  console.log(feedbacks, "feedbacks");

  const { assets } = rounds.find(({ id }) => id === selectedRound);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const scrollTop = e.currentTarget.scrollTop;
    setShowCaption(scrollTop < 40);
  };

  return (
    <ModalContainer isOpen onClose={onClose} zIndex={100}>
      <div
        className={`bg-light_red rounded-2xl h-[calc(100vh-80px)] max-h-[calc(100vh-80px)] shadow-2xl overflow-hidden flex relative`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="w-[450px] h-[calc(100vh-80px)] max-h-[calc(100vh-80px)] pr-0 relative overflow-hidden flex items-center justify-center bg-highlight_red">
          <ContentHighlight
            data={getAssetsAsContent(sortBy(assets, "position"))}
            currentSlide={activeAssetIndex}
            highlightRight={false}
            isStatic
            height="calc(100vh-80px)"
            limit={null}
            onSlideChange={(index) => setActiveAssetIndex(index)}
            showVideoControls
            width={450}
          />
        </div>
        <div className="w-[550px] flex flex-col relative bg-light_red px-8 pt-4 pb-4">
          <div
            className="relative cursor-pointer flex justify-end mb-4 items-center gap-4"
            onClick={onClose}
          >
            <p className="text-sm font-medium text-black">Status:</p>
            <FDropdown
              options={STATUSES}
              onChange={(value) =>
                onUpdateStatus(value as CONTENT_REVIEW_STATUS)
              }
              selectedValue={status}
            />
            {post.status === CONTENT_REVIEW_STATUS.APPROVED ? (
              <FButton
                className=""
                iconLeft={{
                  color: "#FFFFFF",
                  name: "download",
                  size: 14,
                  className: "-mt-[2px] mr-1",
                }}
                primary
                label="Download"
                loading={isDownloadingAsset}
                onClick={handleDownload}
              />
            ) : null}
          </div>
          <div className="flex gap-4 pb-4 w-full border-b border-light_border">
            <a
              href={`/card/${slug}`}
              className="relative z-10 bg-white border border-light_border cursor-pointer decoration-none flex justify-center items-center h-[52px] w-[52px] rounded-full hover:opacity-80"
              onClick={(e) => e.stopPropagation()}
              target="_blank"
            >
              {profile_image_url ? (
                <div
                  className="bg-cover bg-center rounded-3xl"
                  style={{
                    backgroundImage: `url(${profile_image_url})`,
                    height: "52px",
                    width: "52px",
                  }}
                />
              ) : (
                <FIcon
                  icon="user-bold"
                  size={16}
                  color="#000F45"
                  className="inline-block vertical-align-middle"
                />
              )}
            </a>
            <div className="text-[15px] text-dark_night_sky  flex flex-col justify-center">
              <p className="font-medium">{full_name}</p>
              <a
                href={post.social_username}
                target="_blank"
                rel="noopener noreferrer"
                className="text-[13px] text-neutral_500 hover:underline"
                onClick={(e) => e.stopPropagation()}
              >
                {"@" + post.social_username}
              </a>
            </div>
          </div>
          <div
            className={`overflow-hidden transition-all duration-300 ease-in-out ${
              showCaption ? "opacity-100" : "max-h-0 opacity-0"
            }`}
          >
            <div className="py-4">
              <p className="text-sm font-medium text-black mb-2">Caption</p>
              <div className="p-4 bg-white rounded-lg border border-default_weak">
                <ShowMore
                  lines={2}
                  more="...Read more"
                  less="...Show less"
                  anchorClass="text-red"
                >
                  <p className="text-[15px]">{post.caption}</p>
                </ShowMore>
              </div>
            </div>
          </div>
          <div
            className="flex-1 flex flex-col overflow-auto"
            onScroll={handleScroll}
          >
            <div className="py-4 flex justify-between items-center">
              <div className="flex flex-col gap-1 w-full">
                <p className="text-sm font-medium text-black">Feedback</p>
                {!isRejectFormVisible ? (
                  <p className="text-sm text-[rgba(0,0,0,0.5)]">
                    Toggle the round to see feedback
                  </p>
                ) : (
                  <p className="text-sm text-[rgba(0,0,0,0.5)]">
                    Provide context for your rejection
                  </p>
                )}
              </div>
              {!isRejectFormVisible && (
                <div className="flex justify-end w-full">
                  <FDropdown
                    options={rounds.map(({ id, stage }) => ({
                      value: id,
                      label: `R${stage}`,
                    }))}
                    selectedValue={selectedRound}
                    onChange={(value) => setSelectedRound(value as number)}
                  />
                </div>
              )}
            </div>
            {isRejectFormVisible ? (
              <>
                <FTextarea
                  className="mb-4 w-full"
                  label="Request media changes"
                  value={mediaFeedback}
                  onChange={setMediaFeedback}
                  rows={3}
                  width="100%"
                />
                <FTextarea
                  className="mb-4 w-full"
                  label="Request caption changes"
                  value={captionFeedback}
                  onChange={setCaptionFeedback}
                  rows={3}
                  width="100%"
                />
              </>
            ) : (
              <>
                <div className="flex-1 overflow-y-auto" onScroll={handleScroll}>
                  {feedbacks?.length
                    ? [...feedbacks]
                        .sort(
                          (a, b) =>
                            new Date(b.updated_at).getTime() -
                            new Date(a.updated_at).getTime()
                        )
                        .map((feedback) => <Comment comment={feedback} />)
                    : null}
                  <div className="mb-6" />
                  {commentsForRound.length > 0 ? (
                    <ContentReviewComments
                      comments={commentsForRound}
                      onCommentDeleted={handleCommentDeleted}
                      onCommentUpdated={handleCommentUpdated}
                    />
                  ) : isLoadingComments ? null : (
                    <div className="flex items-center justify-center w-full my-8">
                      <span className="text-neutral_400">No comments yet</span>
                    </div>
                  )}
                </div>
                <div className="shadow-[0_-4px_6px_-1px_rgba(0,0,0,0.05)]">
                  <CommentInput
                    pendingComment={pendingComment}
                    isSubmitted={isSubmitted}
                    onChange={setPendingComment}
                    onSubmit={handleAddComment}
                  />
                </div>
              </>
            )}
          </div>
          <div className="flex items-center justify-end gap-2 mt-4">
            {isRejectFormVisible ? (
              <>
                <FButton
                  label="Confirm"
                  onClick={() => onReject({ mediaFeedback, captionFeedback })}
                  loading={rejecting}
                  primary
                />
                <FButton
                  label="Cancel"
                  onClick={() => setRejectFormVisible(false)}
                />
              </>
            ) : (
              <>
                {post.status === CONTENT_REVIEW_STATUS.APPROVED ? null : (
                  <FButton label="Approve" onClick={handleApprove} primary />
                )}
                <FButton
                  label="Reject"
                  onClick={() => setRejectFormVisible(true)}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default ContentReviewModal;
