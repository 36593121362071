import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

import { AmbassadorsContext } from "@contexts/index";

import { showBrand } from "@api/brands";
import useAuth from "@hooks/useAuth";
import { Brand } from "@types";
import LoadingSpinner from "@components/LoadingSpinner";

import { listAmbassadors } from "@api/Ambassadors/Ambassadors";

import AmbassadorsContent from "./AmbassadorsContent";
import AmbassadorsOnboardingSettings from "./settings";
import { searchCreators } from "@api/AmbassadorSearch";

const Ambassadors = () => {
  const [brand, setBrand] = useState<Brand>(null);
  const [isListEmpty, setListEmpty] = useState(true);

  const [overallTotals, setOverallTotals] = useState(null);

  const { user } = useAuth();

  useEffect(() => {
    const loadBrandAndTopAmbassadors = async () => {
      const brand = await showBrand(user.brand_id);

      const ambassadorsResponse = await listAmbassadors(1);

      const responseForTotals = await searchCreators({
        page_size: 1,
        p: 1,
        status: "interested",
      });

      const { overall_totals } = responseForTotals.data;

      setBrand(brand);
      setListEmpty(ambassadorsResponse.total_items === 0);
      setOverallTotals(overall_totals);
    };

    loadBrandAndTopAmbassadors();
  }, []);

  if (!brand) {
    return <LoadingSpinner className="h-screen" />;
  }

  return (
    <AmbassadorsContext.Provider
      value={{
        brand,
        isListEmpty,
        setBrand,
        setListEmpty,
        overallTotals,
        setOverallTotals,
      }}
    >
      <Routes>
        <Route
          path="onboarding-settings/*"
          element={<AmbassadorsOnboardingSettings />}
        />
        <Route path="/*" element={<AmbassadorsContent />} />
      </Routes>
    </AmbassadorsContext.Provider>
  );
};

export default Ambassadors;
