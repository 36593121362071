import React, { useCallback, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CampaignContext } from "@contexts/index";
import AddedNavLayout from "@layouts/AddedNavLayout";
import FullBrief from "./brief/FullBrief";
import { useBriefData } from "./hooks/useBriefData";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { useIntersectionObserver } from "./hooks/useIntersectionObserver";
import { TitleSection } from "./components/TitleSection";
import { getSubNavItems } from "./components/SubNavItems";
import FileUpload from "@components/FileUpload";
import apiClient from "@apiClient";
import { CampaignBrief } from "@types";
import useAuth from "@hooks/useAuth";
import { Permission } from "@constants/roles";
import BriefPreview from "./brief-preview/BriefPreview";
import { EVENTS } from "@utils/mixpanel_utilities";

const getTitleOptions = (briefs: CampaignBrief[], participantGroups) =>
  briefs?.map((b) => ({
    label: b.participant_group_id
      ? participantGroups?.find((group) => group.id === b.participant_group_id)
          ?.name || `Group ${b.participant_group_id}`
      : "Master Template",
    value: b.id,
  }));

export default function CampaignBriefPage() {
  const navigate = useNavigate();

  const { brief_id, campaign_id, participant_id } = useParams();

  const campaignContext = useContext(CampaignContext);
  const { trackEvent } = useContext(MixpanelContext);

  const { can } = useAuth();

  const {
    activeBrief,
    briefs = [],
    campaign,
    participantBriefs,
    setParticipantBriefs,
    setBriefs,
  } = campaignContext;

  const { participant_groups: participantGroups } = campaign;

  const { pageName, groupBriefDetails } = useBriefData(campaignContext, {
    brief_id,
    campaign_id,
    participant_id,
  });

  const { activeSection, sectionRefs, handleNavClick } =
    useIntersectionObserver();

  const handleTitleChange = useCallback(
    (selectedGroupId) => {
      const foundBrief = campaignContext.briefs?.find(
        (b) => b.id === selectedGroupId
      );

      if (foundBrief) {
        campaignContext.setActiveBrief(foundBrief);
        navigate(`/campaigns/${campaign_id}/brief/${foundBrief.id}`);
      }
    },
    [campaignContext, campaign_id, navigate]
  );

  const isSettings = location.pathname.includes("settings");

  const titleSection = activeBrief.loading ? null : (
    <div className="pt-10">
      <TitleSection
        participant_id={participant_id}
        titleOptions={getTitleOptions(briefs, participantGroups)}
        activeBrief={activeBrief}
        handleTitleChange={handleTitleChange}
        groupBriefDetails={groupBriefDetails}
        campaign_id={campaign_id}
        campaign={campaign}
      />
      {isSettings ? null : (
        <div className="mb-14">
          <h2 className="text-2xl mb-4 mt-8">Header Image</h2>
          <div
            className="w-full h-[220px] bg-contain bg-center border border-default_weak bg-light_pink shadow-sm bg-no-repeat relative overflow-hidden rounded-lg"
            style={{
              backgroundImage: `url("${activeBrief.cover_image?.original || activeBrief.cover_image?.large}")`,
            }}
          >
            <FileUpload
              showPreviewImage={false}
              type="image"
              url={
                activeBrief?.cover_image?.original ||
                activeBrief.cover_image?.large
              }
              id="cover_image_header"
              name="cover_image"
              apiFunction={apiClient.campaign_brief.upload}
              onUpdate={(cover_image, response) => {
                const updates = {
                  cover_image: (response as CampaignBrief).cover_image,
                };

                const isParticipant = !!participant_id;

                const getUpdatedBriefs = (briefs) =>
                  briefs.map((brief) =>
                    brief.id === activeBrief.id
                      ? { ...brief, ...updates }
                      : brief
                  );

                if (isParticipant) {
                  setParticipantBriefs(getUpdatedBriefs(participantBriefs));
                } else {
                  setBriefs(getUpdatedBriefs(briefs));
                }
              }}
              resourceName="campaign_brief"
              resourceId={activeBrief.id as number}
            />
          </div>
        </div>
      )}
    </div>
  );

  let subNavItemsToShow = getSubNavItems(campaign);

  subNavItemsToShow = subNavItemsToShow.filter(({ value }) => {
    if (value === "contract") {
      return can(Permission.VIEW_CAMPAIGN_BRIEFS_CONTRACTS);
    }

    if (value === "payment") {
      return can(Permission.VIEW_CAMPAIGN_BRIEFS_PAYMENT_TERMS);
    }

    return true;
  });

  if (
    !can(Permission.VIEW_CAMPAIGN_BRIEFS_CONTRACTS) &&
    !can(Permission.VIEW_CAMPAIGN_BRIEFS_PAYMENT_TERMS)
  )
    return (
      <BriefPreview
        hideHeader
        participant_id={parseInt(participant_id)}
        participant_group_id={activeBrief.participant_group_id}
      />
    );

  trackEvent(EVENTS.PAGE_VIEW.CAMPAIGN, {
    campaign: campaign.title,
    section: "brief",
  });

  return (
    <div className="pb-6">
      <AddedNavLayout
        activeBrief={activeBrief}
        subNavItems={subNavItemsToShow}
        titleSection={titleSection}
        activeSection={activeSection}
        onNavClick={handleNavClick}
      >
        <div className="w-full">
          <FullBrief
            pageName={pageName}
            subNavItems={subNavItemsToShow}
            sectionRefs={sectionRefs}
          />
        </div>
      </AddedNavLayout>
    </div>
  );
}
