import BriefProducts from "../brief/BriefProducts";
import BriefDeliverables from "../brief/BriefDeliverables";
import BriefGuidelines from "../brief/BriefGuidelines";
import BriefContract from "../brief/BriefContract";
import BriefPayment from "../brief/BriefPayment";
import BriefAbout from "../brief/BriefAbout";
import { Campaign, KIND } from "@types";
import { includes } from "lodash";
import BriefMoodboard from "../brief/BriefMoodboard";

export const SubNavItems = [
  {
    label: "About",
    value: "about",
    description:
      "Tell your influencers about the scope and purpose of the campaign.",
    component: BriefAbout,
  },
  {
    label: "Creative Direction",
    value: "creative_direction",
    description:
      "Empower your creators to do their best work with direction on style and content.",
    pageTitleCMSName: "creative_direction",
    customSectionName: "creative_direction",
  },
  {
    label: "Products",
    value: "products",
    description: "Share any products that are relevant to your campaign here.",
    pageTitleCMSName: "products",
    hasOwnWrapper: true,
    component: BriefProducts,
  },
  {
    label: "Deliverables",
    description:
      "Add deliverables that influencers will be responsible for on your campaign.",
    value: "deliverables",
    pageTitleCMSName: "deliverables",
    hasOwnWrapper: true,
    component: BriefDeliverables,
  },
  {
    label: "Guidelines",
    value: "guidelines",
    description:
      "What should your creators explicitly keep in mind while they participate.",
    component: BriefGuidelines,
  },
  {
    label: "Moodboard",
    value: "moodboard",
    description: "",
    hasOwnWrapper: true,
    component: BriefMoodboard,
  },
  {
    label: "Contract",
    value: "contract",
    description: "Share the contract that creators must adhere to.",
    component: BriefContract,
  },
  {
    label: "Payment",
    value: "payment",
    //  pageTitleCMSName: "payments",
    description:
      "Where you define explicit terms that follow how & when payments are made.",
    component: BriefPayment,
  },
];

export const getSubNavItems = (campaign: Campaign) =>
  SubNavItems.filter((item) => {
    if (campaign.kind === KIND.GIFTING) {
      return !includes(["contract", "payment"], item.value);
    }

    return true;
  });
