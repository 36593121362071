import React, { useState } from "react";
import { Link } from "react-router-dom";
import FDropdown from "../../../FDropdown";
import DecorationBadge from "../../CardAssets";
import FIcon from "../../../FIcon";
import { formatLargeNumber } from "../../../../utils/number_utilities";
import { FTooltip } from "../../../FTooltip";

const ParticipantCard = ({
  decoration,
  cardDropDownOptions,
  cardHeight,
  cardWidth,
  decorationPath,
  className,
  onOptionSelect,
  checkBoxClickList,
  onCheckBoxClick,
  ...props
}) => {
  const [hover, setHover] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  if (decorationPath) {
    decoration = props[decorationPath];
  }

  const formatSlug = (slug: string) => {
    if (!slug) return "";
    return slug
      .split(/[-_.]/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ")
      .trim();
  };

  const creator = props.creator || {};
  const ProfileImage = creator.profile_image_url;
  const shouldShowDefaultIcon = ProfileImage?.endsWith("user-icon-new.jpeg");
  const displayName =
    creator.full_name?.trim().toLowerCase() || formatSlug(creator.slug);
  const isSelected = checkBoxClickList && checkBoxClickList.includes(props.id);

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={`flex flex-col w-full cursor-pointer shadow-lg bg-highlight_red relative overflow-hidden h-full min-h-[400px] rounded-xl ${className} ${
        isSelected
          ? "border-2 border-dark_night_sky"
          : "border-1 border-light_red"
      } ${isDropdownOpen ? "z-50" : "z-10"}`}
      style={{
        minHeight: `${cardHeight}px`,
        minWidth: `${cardWidth}px`,
      }}
      key={props.id}
    >
      <Link
        to={`${props.id}`}
        className={`w-full h-full absolute top-0 left-0 right-0 bottom-0 z-[10]`}
        target="_blank"
        key={props.id}
      />

      <div className="w-full flex-grow relative overflow-hidden flex items-center justify-center">
        {ProfileImage && !shouldShowDefaultIcon ? (
          <div
            className={`w-full h-full bg-cover bg-center transition-all duration-500 ease-out ${
              hover ? "scale-105" : "scale-100"
            }`}
            style={{
              backgroundImage: `url(${ProfileImage})`,
            }}
          />
        ) : (
          <div className="w-[60px] h-[60px] m-auto bg-gray-100 flex items-center bg-[rgba(255,255,255,0.2)] justify-center p-10 border-2 border-[rgba(0,0,0,0.07)] rounded-full">
            <FIcon icon="user" size={30} color="rgba(0,0,0,0.16)" />
          </div>
        )}
      </div>

      {(hover || isSelected) && (
        <div className="absolute flex flex-row top-4 left-4 z-20">
          {onCheckBoxClick && (
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                className="w-5 h-5 text-white border-light_red focus:ring-black checked:bg-dark_night_sky focus:ring-none cursor-pointer hover:border-dark_night_sky"
                checked={isSelected}
                onChange={(e) => {
                  e.stopPropagation();
                  onCheckBoxClick && onCheckBoxClick(props.id);
                }}
              />
            </div>
          )}
        </div>
      )}

      {decoration && (
        <div className="absolute top-4 right-4 z-20">
          <DecorationBadge decoration={decoration} />
        </div>
      )}

      {isSelected && (
        <div
          className="absolute w-full h-full left-0 top-0"
          style={{
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: 11,
          }}
        />
      )}

      <div className="w-full relative mt-auto z-[10]">
        <div className="px-4 pt-[8px] pb-[10px] bottom-0 bg-white flex flex-col relative z-[2]">
          <div className="flex flex-row mb-[1px] justify-between items-center w-full">
            {displayName && (
              <p className="text-[18px] text-blue_night_sky capitalize truncate w-[95%]">
                {displayName}
              </p>
            )}
            {cardDropDownOptions && (
              <FDropdown
                options={cardDropDownOptions}
                onChange={(value) => onOptionSelect(`${value}-${props.id}`)}
                width="auto"
                height="28"
                iconOnly={true}
                menuUp
                iconColor="black"
                icon="ellipsis"
                className="ml-auto relative z-[20]"
                zIndex={999}
                onOpenChange={setIsDropdownOpen}
              />
            )}
          </div>

          <div className="flex flex-row justify-between relative items-center w-full">
            <FTooltip label="Total Followers">
              <div className="flex flex-row gap-1 items-center cursor-pointer z-[20] relative">
                <FIcon
                  size={20}
                  icon="audience-bold"
                  color="black"
                  className="-mt-[2px]"
                />
                <p className="text-sm truncate text-dark_night_sky whitespace-nowrap">
                  {formatLargeNumber(creator.overall_followers)}
                </p>
              </div>
            </FTooltip>

            {creator.socials && (
              <div className="flex flex-row items-center">
                {Object.values(creator.socials)
                  .filter((platform: any) => platform.followers_count > 0)
                  .sort(
                    (a: any, b: any) => b.followers_count - a.followers_count
                  )
                  .map((platform: any, index: number) => (
                    <div
                      className="flex items-center p-[5px] rounded-full border border-1 border-white bg-dark_night_sky -mr-1 last:mr-0"
                      key={platform.name}
                      style={{ zIndex: 9 - index }}
                    >
                      <FIcon size={12} icon={platform.name} color="white" />
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParticipantCard;
