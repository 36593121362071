import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import FIcon from "../FIcon";
import { FSidebarItem } from "../../types/types";

function FSidebarMenuItem({
  collapsed = false,
  mainLink,
  href,
  items = [],
  icon = "",
  name,
  target = "_self",
  upgrade_required_label = "",
  isExpanded,
  onToggle,
  activeLink,
  ...props
}: FSidebarItem & {
  isExpanded: boolean;
  onToggle: () => void;
  activeLink: string;
}) {
  const navigate = useNavigate();

  const handleLinkClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    if (onToggle && !props.isSubmenuItem) {
      onToggle();
    }

    if (items.length > 0) {
      const defaultItem = items.find(({ isDefault }) => isDefault) || items[0];

      navigate(defaultItem.href);
    } else if (href) {
      if (href.startsWith("http")) {
        window.open(href, "_blank");
      } else {
        navigate(href);
      }
    }
  };

  const isActive =
    (href === "/"
      ? activeLink === "/"
      : activeLink.startsWith(href + "/") || activeLink === href) ||
    items.some(
      (item) =>
        activeLink.startsWith(item.href + "/") || activeLink === item.href
    );

  const baseStyles = `cursor-pointer flex flex-row items-center h-[40px] text-[14px] font-normal no-underline rounded-[8px] ${
    collapsed ? "pl-[17px]" : "pl-[12px]"
  } justify-between`;

  const activeStyles = isActive
    ? collapsed
      ? " text-dark_night_sky text-bold"
      : " text-white text-bold"
    : "text-dark_night_sky";

  const disabledStyles = upgrade_required_label
    ? "appcue-upgrade pointer-events-none"
    : "";

  const combinedStyles = `${baseStyles} ${activeStyles} ${disabledStyles}`;

  const content = (
    <>
      <div className="flex items-center">
        {(icon || (mainLink && mainLink.icon)) && (
          <FIcon
            icon={icon || (mainLink && mainLink.icon)}
            size={16}
            className="-mt-[1px]"
            color="#5C6276"
          />
        )}
        {!collapsed && (
          <span
            className={`${
              props.isSubmenuItem ? "ml-[30px]" : "ml-[12px]"
            } no-underline text-dark_night_sky ${
              isActive ? "opacity-100" : "opacity-40"
            } hover:opacity-100 leading-[10px] -mt-[-3px]`}
          >
            {mainLink ? mainLink.name : name}
          </span>
        )}
      </div>
      {items.length > 0 && !collapsed && (
        <div
          className="p-[8px] mr-[8px] rounded-lg flex items-center z-0 relative"
          onClick={(e) => {
            e.preventDefault();
            handleLinkClick;
          }}
        >
          <FIcon
            icon="chevron"
            size={10}
            color={isActive ? "#000F45" : "#5C6276"}
            className={`${isExpanded ? "rotate-180" : ""}`}
          />
        </div>
      )}
      {upgrade_required_label && !collapsed && (
        <div className="text-[#e47667] text-[10px]">
          {upgrade_required_label}
        </div>
      )}
    </>
  );

  return (
    <>
      {href ? (
        <NavLink
          to={href}
          className={({ isActive: navLinkIsActive }) => {
            return `${combinedStyles} ${
              navLinkIsActive ? activeStyles : disabledStyles
            }`;
          }}
          target={target}
          onClick={handleLinkClick}
        >
          {content}
        </NavLink>
      ) : (
        <div className={baseStyles} onClick={handleLinkClick}>
          {content}
        </div>
      )}
      {/* submenu */}
      {items.length > 0 && isExpanded && !collapsed && (
        <div>
          {items.map((item, i) => (
            <FSidebarMenuItem
              key={i}
              active={activeLink === item.href}
              name={item.name}
              href={item.href}
              isSubmenuItem={true}
              target={item.target}
              upgrade_required_label={item.upgrade_required_label}
              isExpanded={isExpanded}
              onToggle={onToggle}
              activeLink={activeLink}
            />
          ))}
        </div>
      )}
    </>
  );
}

export default FSidebarMenuItem;
