import * as React from "react";
import { useCallback, useState, useEffect } from "react";
import LoadingSpinner from "@components/LoadingSpinner";
import { useNavigate } from "react-router-dom";

import NotificationSetting from "./NotificationSetting";
import FButton from "@components/FButton";

import {
  bulkCreateUserNotificationSetting,
  getUserNotificationSettings,
} from "@api/Notifications/UserNotificationSettings";
import FPagination from "@components/FPagination";

import toast from "react-hot-toast";

import Heading from "@components/Heading";
import BlankState from "@components/BlankState";
import Modal from "@components/Modals/Modal";
// import SelectCampaignTable from "@components/creator/SelectCampaignsTable";
import { Campaign } from "@types";
import { SETTINGS } from "./settings";

function NotificationSettings() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [subscriptions, setSubscriptions] = useState<any[]>([]);
  const [selectedCampaignIds, setSelectedCampaignIds] = useState<number[]>([]);

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const [isCreatingNotifications, setCreatingNotifications] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCampaigns, setSelectedCampaigns] = useState<Campaign[]>([]);

  const getNotifications = useCallback(async (page) => {
    setLoading(true);

    try {
      const response = await getUserNotificationSettings(page);

      const { user_notification_settings: campaigns, total_items: total } =
        response.data;

      setSubscriptions(campaigns);
      setTotal(total);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }, []);

  const handleSubscriptionDeleted = (id: number) =>
    setSubscriptions(
      subscriptions.filter((subscription) => subscription.id !== id)
    );

  const handleSubscriptionUpdated = (id: number, updates) =>
    setSubscriptions(
      subscriptions.map((subscription) =>
        subscription.id !== id ? subscription : { ...subscription, ...updates }
      )
    );

  const bulkCreateNotificationSettings = async () => {
    setCreatingNotifications(true);

    try {
      await bulkCreateUserNotificationSetting({
        campaign_ids: selectedCampaignIds,
        settings: SETTINGS.reduce(
          (result, item) => ({
            ...result,
            [item.value]: false,
          }),
          {}
        ),
      });
      getNotifications(page);

      toast.success(`Created ${selectedCampaignIds.length} notifications`);
    } catch (err) {
      toast.error("Failed to create notifications:", err.response?.data);
    } finally {
      setCreatingNotifications(false);
    }
  };

  const handleCampaignSelect = (campaigns: Campaign[]) => {
    setSelectedCampaigns(campaigns);
    setSelectedCampaignIds(campaigns.map((campaign) => campaign.id));
  };

  const handleAddCampaign = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedCampaigns([]);
  };

  useEffect(() => {
    getNotifications(page);
  }, [page, getNotifications]);

  return (
    <div className="max-w-[1000px] m-auto -mt-[40px] pb-[80px]">
      <div className="flex flex-col justify-between items-start sticky top-0 z-50 pt-10 bg-light_red_opacity backdrop-blur-sm">
        <Heading
          title="Notification Settings"
          subTitle="Manage your notification settings for different campaigns"
          className="w-full"
          headerButtons={
            <div className="flex flex-row gap-2">
              <FButton
                iconLeft={{
                  name: "fancy-arrow-left",
                  size: 16,
                  color: "black",
                }}
                label="Back"
                onClick={() => {
                  navigate("/notifications");
                }}
              />
              <FButton
                iconLeft={{ name: "add", size: 14, color: "white" }}
                label="Add Campaign"
                primary
                onClick={handleAddCampaign}
              />
            </div>
          }
        />

        <div className="w-full mt-4">
          {loading ? (
            <LoadingSpinner className="w-full h-full mt-[20%]" />
          ) : subscriptions.length === 0 ? (
            <BlankState
              title="No notification settings"
              subtitle="You don't have any notification settings at the moment"
              icon="user"
              onActionClick={() => {}}
              actionLabel="Clear All"
            />
          ) : (
            <div className="w-full">
              {subscriptions.map((subscription, index) => {
                return (
                  <NotificationSetting
                    key={index}
                    subscription={subscription}
                    className="mt-[16px]"
                    onSubscriptionDeleted={handleSubscriptionDeleted}
                    onSubscriptionUpdated={handleSubscriptionUpdated}
                  />
                );
              })}
              {total > 10 && (
                <div className="mt-4">
                  <FPagination
                    page={page}
                    pageSize={25}
                    total={total}
                    onPageChange={setPage}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {isModalOpen && (
        <Modal
          title="Select Campaign"
          subtitle={null}
          className="w-[900px]"
          onClose={handleCloseModal}
          isOpen
          hasForm
          zIndex={9999}
        >
          <div className="flex flex-col gap-4 max-h-[60vh] overflow-y-auto">
            {/*  <SelectCampaignTable
              selectedItems={selectedCampaigns}
              onChangeSelectedItems={handleCampaignSelect}
            /> */}
            <div className="flex justify-between pb-3 sticky bottom-0 z-50 bg-light_red_opacity backdrop-blur-sm pt-4">
              <FButton
                onClick={handleCloseModal}
                label="Cancel"
                width="100px"
                height="40px"
              />
              <FButton
                onClick={() => {
                  bulkCreateNotificationSettings();
                  handleCloseModal();
                }}
                primary
                label="Add to Notifications"
                height="40px"
                loading={isCreatingNotifications}
                disabled={selectedCampaigns.length === 0}
              />
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default NotificationSettings;
