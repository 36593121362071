import React, { useContext, useState } from "react";
import { CampaignContext } from "@contexts/index";
import DeliverablePost from "@components/Campaign/CMS/DeliverablePost";
import FButton from "@components/FButton";
import ConfirmModal from "@components/Modals/ConfirmModal";

import toast from "react-hot-toast";

import apiClient from "@apiClient";
import BriefPageTemplate, {
  BriefPageTemplateProps,
} from "@components/Campaign/Brief/BriefPageTemplate";
import AddDeliverableModal from "../components/AddDeliverableModal";
import { deleteParticipantDeliverable } from "@api/Campaign/CampaignDeliverables";
import FIcon from "@components/FIcon";

const { delete: deleteDeliverable } = apiClient.campaign_deliverable;

interface Props extends BriefPageTemplateProps {}

export default function BriefDeliverable({
  brief,
  onUpdate,
  pageTitle,
  pageDescription,
  pageTitleCMSName,
  customSectionName,
  containerRef,
}: Props) {
  const { campaign, setCampaign } = useContext(CampaignContext);
  const [isLocalModalOpen, setIsLocalModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deliverableToEdit, setDeliverableToEdit] = useState(null);

  const {
    deliverables: campaignDeliverables,
    participants,
    participant_groups,
  } = campaign;

  const isParticipantBrief = !!brief.participant_id;

  const { participant_group_id } = brief;

  const participant =
    isParticipantBrief &&
    participants.find(({ id }) => id === brief.participant_id);

  const deliverables = participant
    ? participant.deliverables
    : campaignDeliverables.filter((deliverable) =>
        participant_group_id
          ? deliverable.participant_group_id === participant_group_id
          : !deliverable.participant_group_id
      );

  console.log("Brief", deliverables);

  const modalClose = () => {
    setIsLocalModalOpen(false);
    setDeliverableToEdit(null);
  };

  const removeDeliverable = async (deliverableId) => {
    if (brief.participant_id) {
      await deleteParticipantDeliverable(deliverableId);
    } else {
      await deleteDeliverable(deliverableId);
    }

    const newDeliverables = deliverables.filter((d) => d.id !== deliverableId);

    let campaignUpdates;

    if (isParticipantBrief) {
      const newParticipants = participants.map((participant) => {
        if (participant.id === brief.participant_id) {
          return { ...participant, deliverables: newDeliverables };
        }

        return participant;
      });

      campaignUpdates = {
        participants: newParticipants,
      };
    } else {
      campaignUpdates = {
        deliverables: newDeliverables,
      };
    }

    setCampaign({
      ...campaign,
      ...campaignUpdates,
    });

    setIsDeleteModalOpen(false);
    setDeliverableToEdit(null);

    toast.success("Deleted");
  };

  return (
    <BriefPageTemplate
      brief={brief}
      onUpdate={onUpdate}
      pageTitle={pageTitle}
      pageDescription={pageDescription}
      customSectionName={customSectionName}
      pageTitleCMSName={pageTitleCMSName}
      containerRef={containerRef}
      actionRight={
        <FButton
          label="Add Deliverable"
          iconLeft={{
            name: "plus",
            size: 12,
            color: "black",
            className: "-mt-1",
          }}
          className="w-fit whitespace-nowrap"
          onClick={() => setIsLocalModalOpen(true)}
        />
      }
    >
      {deliverables.length > 0 && (
        <div
          className={`flex flex-col gap-4 w-full border-l border-light_border pl-10 mt-8 ${deliverables.length > 0 ? "" : ""}`}
        >
          <div className="flex flex-col relative">
            {deliverables
              ?.sort(
                (a, b) =>
                  new Date(b.updated_at).getTime() -
                  new Date(a.updated_at).getTime()
              )
              .map((deliverable, index) => (
                <DeliverablePost
                  deliverable={deliverable}
                  participantGroups={
                    participant_group_id ? [] : participant_groups
                  }
                  key={index}
                  openModal={() => {
                    setIsLocalModalOpen(true);
                    setDeliverableToEdit(deliverable);
                  }}
                  isReadOnly={false}
                  influencers={participants}
                  editDeliverable={(deliverable) => {
                    setIsLocalModalOpen(true);
                    setDeliverableToEdit(deliverable);
                  }}
                  deleteDeliverable={(deliverable) => {
                    setIsDeleteModalOpen(true);
                    setDeliverableToEdit(deliverable);
                  }}
                />
              ))}
          </div>
          {deliverables.length > 0 && (
            <div
              className="rounded-lg p-4 pb-3 bg-[#f5f0ee] shadow-sm text-center flex flex-row items-center justify-center gap-2 mb-4"
              role="button"
              onClick={() => setIsLocalModalOpen(true)}
            >
              <FIcon icon="plus" size={12} color="black" className="-mt-1" />
              <p className="text-1xl">Add Deliverable</p>
            </div>
          )}
        </div>
      )}
      {isLocalModalOpen ? (
        <AddDeliverableModal
          campaign={campaign}
          deliverable={deliverableToEdit}
          onClose={modalClose}
          onUpdateCampaign={setCampaign}
          participantId={brief.participant_id}
          participantGroupId={brief.participant_group_id}
        />
      ) : null}
      <ConfirmModal
        title="Delete Deliverable"
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onAction={() => removeDeliverable(deliverableToEdit.id)}
        actionLabel="Delete"
        subtitle="Are you sure you want to delete this deliverable?"
      />
    </BriefPageTemplate>
  );
}
