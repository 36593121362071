import {
  createParticipantContent,
  getCampaignParticipantContent,
} from "@api/Campaign/CampaignParticipantContent";
import { searchPosts } from "@api/Search";
import { CampaignContent } from "@apiTypes";
import BlankState from "@components/BlankState";
import FButton from "@components/FButton";
import FDropdown from "@components/FDropdown";
import LoadingSpinner from "@components/LoadingSpinner";
import GeneralPostModal from "@components/Modals/GeneralPostModal";
import ResultsGrid from "@components/ResultsGrid";

import { CampaignContext } from "@contexts/index";
import { CampaignDeliverable, Content, Participant } from "@types";
import { isEmpty } from "lodash";
import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";

const getContentWithAttributionStatus = (
  allContent: ContentWithDeliverable[],
  participantContents: CampaignContent[]
) =>
  allContent.filter((content) => {
    const { deliverable } = content;

    const isAttributed = participantContents.find(
      (record) =>
        record.fohr_content_id === content.id &&
        record.deliverable_id === deliverable.id
    );

    return !isAttributed;
  });

const getSearchTerm = (
  hashtagsAsString: string,
  mentionsAsString: string
): string => {
  let searchTerm = "";

  if (hashtagsAsString) {
    const hashtags = hashtagsAsString
      .split(" ")
      .map((hashtag) => `#${hashtag}`)
      .join(" ");

    searchTerm += hashtags;
  }

  if (mentionsAsString) {
    const mentions = mentionsAsString
      .split(" ")
      .map((mention) => `@${mention}`)
      .join(" ");

    searchTerm += `${searchTerm ? " " : ""}${mentions}`;
  }

  return searchTerm;
};

interface ContentWithDeliverable extends Content {
  deliverable: CampaignDeliverable;
}

const ContentAttribution = () => {
  const { campaign } = useContext(CampaignContext);

  const [loading, setLoading] = useState(false);

  const [isAttributing, setIsAttributing] = useState(false);

  const { brief, participants } = campaign;

  const [selectedContentIds, setSelectedCotentIds] = useState<string[]>([]);

  const [posts, setPosts] = useState<ContentWithDeliverable[]>([]);
  const [participantContents, setParticipantContents] = useState<
    CampaignContent[]
  >([]);

  const [activePost, setActivePost] = useState<ContentWithDeliverable>(null);

  const [selectedParticipant, setSelectedParticipant] =
    useState<Participant>(null);

  useEffect(() => {
    if (participants?.length > 0 && !selectedParticipant) {
      setSelectedParticipant(participants[0]);
    }
  }, [participants]);

  const handleToggleItemSelection = (id: string) => {
    let newSelectedItems = selectedContentIds || [];

    if (selectedContentIds.includes(id)) {
      newSelectedItems = newSelectedItems.filter((item) => item !== id);
    } else {
      newSelectedItems = [...selectedContentIds, id];
    }

    setSelectedCotentIds(newSelectedItems);
  };

  useEffect(() => {
    const loadContent = async () => {
      if (!selectedParticipant) {
        return;
      }

      setLoading(true);

      const allContent = [] as ContentWithDeliverable[];

      const { creator, deliverables } = selectedParticipant;

      const { go_live_start, go_live_end } = brief;

      const participantContentsResponse = await getCampaignParticipantContent(
        campaign.id,
        {
          participant_id_eq: selectedParticipant.id,
        }
      );

      const participantContents =
        participantContentsResponse.data.participant_contents;

      for (const deliverable of deliverables) {
        const { hashtags, mentions, platform } = deliverable;

        const searchTerm = getSearchTerm(hashtags, mentions);

        const response = await searchPosts({
          searchTerm,
          creator_ids: [creator.id],
          platforms: [platform],
          p: 1,
          per_page: 1000,
          /*   date_from: go_live_start,
          date_to: go_live_end, */
        });

        const { results } = response.data;

        results.forEach((content) => {
          allContent.push({
            ...content,
            deliverable,
          });
        });
      }

      setParticipantContents(participantContents);

      setLoading(false);

      setPosts(allContent);
    };

    loadContent();
  }, [selectedParticipant, campaign.id, brief]);

  const handleAttributeSelectedContent = async () => {
    setIsAttributing(true);

    try {
      const newParticipantContents = [];

      for (const id of selectedContentIds) {
        const contentWithDeliverable = posts.find(
          (post) => `${post.id}` === id
        );

        const { deliverable } = contentWithDeliverable;

        await createParticipantContent({
          fohr_content_id: id,
          campaign_id: campaign.id,
          participant_id: selectedParticipant.id,
          deliverable_id: deliverable.id,
          platform: deliverable.platform,
        });

        newParticipantContents.push({
          fohr_content_id: id,
          deliverable_id: deliverable.id,
        });
      }

      setParticipantContents(newParticipantContents);

      toast.success("Content attributed");
    } catch (e) {
      toast.error("Error attributing content");
    } finally {
      setIsAttributing(false);
    }
  };

  const contentToShow = getContentWithAttributionStatus(
    posts,
    participantContents
  );

  return (
    <div className="pt-[40px]">
      {loading ? (
        <LoadingSpinner className="w-full h-full mt-[20%]" />
      ) : (
        <>
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <h2 className="text-2xl">Content Attribution</h2>
            </div>
            <div className="flex items-center gap-4">
              <FDropdown
                placeholder="Select participant"
                zIndex={99999}
                hasNoDefault
                options={participants.map(({ id, creator }) => ({
                  value: id,
                  label: `${creator.firstName} ${creator.lastName}`,
                }))}
                selectedValue={selectedParticipant?.id}
                onChange={(id) =>
                  setSelectedParticipant(
                    participants.find((participant) => participant.id === id)
                  )
                }
              ></FDropdown>
              {selectedContentIds.length > 0 ? (
                <FButton
                  label="Attribute Content"
                  loading={isAttributing}
                  primary
                  onClick={handleAttributeSelectedContent}
                />
              ) : null}
            </div>
          </div>
          {!isEmpty(posts) ? (
            <ResultsGrid
              cardData={contentToShow}
              cardType="content"
              onCardClick={(content) => setActivePost(content)}
              onToggleItemSelection={handleToggleItemSelection}
              selectedItems={selectedContentIds}
            />
          ) : (
            <BlankState
              title={
                !participants?.length
                  ? "No participants in campaign"
                  : selectedParticipant
                    ? `No content found`
                    : "No participant selected"
              }
              subtitle={
                !participants?.length
                  ? "Add participants to the campaign to attribute content"
                  : selectedParticipant
                    ? "Try updating your search or create one"
                    : "Select a participant to search for content"
              }
              icon="content"
            />
          )}
          {activePost && (
            <GeneralPostModal
              post={activePost}
              isOpen
              onClose={() => setActivePost(null)}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ContentAttribution;
