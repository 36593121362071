import { isArray, isEmpty, isObject, isPlainObject } from "lodash";
import { AdvancedSearchParams } from "./types";

export const DEFAULT_FILTERS = {
  api_connected: false,
  media_product_type: null,
  searchTerm: null,
  selectedPlatforms: [],
  selectedCategories: [],
  page: 1,
  resource: "creators",
  sponsored: true,
  organic: true,
};

export const DEFAULT_AUDIENCE_FILTERS = {
  audience_age: [],
  audience_gender: [],
  audience_ethnicity: [],
  audience_marital_status: [],
  audience_parental_status: "",
  audience_income: [],
  audience_custom_locations: {},
};

export const DEFAULT_ADVANCED_FILTERS = {
  custom_locations: {},
  custom_followers: {},
  custom_age_range: {},
  date_from: "",
  date_to: "",
  gender: [],
  ethnicity: [],
  previously_contracted: false,
  api_connected: false,
  address_state: [],
  address_country: [],
  managed: "",
  target_opportunities: [],
  children: "",
  relationship_status: [],
  sexual_orientation: [],
  tags: [],
  list: [],
  exclude_list: [],
  ...DEFAULT_AUDIENCE_FILTERS,
};

export const getDefaultFilters = (searchParams, initialValue) => {
  const result = Object.keys(initialValue).reduce((result, key) => {
    const paramValue = searchParams.get(key);

    if (paramValue) {
      let value = isArray(initialValue[key])
        ? paramValue.split(",")
        : paramValue;

      if (["list", "exclude_list"].includes(key)) {
        value = value.map((item) => parseInt(item, 10));
      }

      if (value && isPlainObject(initialValue[key])) {
        value = JSON.parse(value);
      }

      return {
        ...result,
        [key]: value,
      };
    } else {
      return result;
    }
  }, initialValue);

  return result;
};

export const areAdvancedFiltersEmpty = (
  advancedFilters: AdvancedSearchParams
) => {
  const a = Object.keys(advancedFilters).every((key) => {
    const value = advancedFilters[key];

    if (isObject(value)) {
      return isEmpty(value);
    }
    return !value;
  });

  return a;
};
