import React, { useContext, useMemo, useState } from "react";
import { CampaignContext, ContentReviewContext } from "@contexts/index";
import { Campaign } from "@types";
import { ContentReviewContent } from "types/content-review";
import { Link, useNavigate } from "react-router-dom";
import { formatLargeNumber, formatDollarValue } from "@utils/number_utilities";
import { groupBy, last } from "lodash";
import { format } from "date-fns";
import CampaignOverviewStats from "./components/CampaignOverviewStats";
import CampaignBlankState from "./components/CampaignBlankState";
import PostsEngagementGraph from "./components/PostsEngagementGraph";
import CampaignDemographics from "./components/CampaignDemographics";
import CampaignTimeline from "./components/CampaignTimeline";
import CreateOrUpdateCampaignModal from "./components/CreateOrUpdateCampaignModal";
import StatCardWithGradientBar from "@pages/profile/components/StatCardWithGradientBar";
import DoughnutGraph from "@components/Graphs/DoughnutGraph";
import FIcon from "@components/FIcon";
import Table from "@components/Table";
import BlankState from "@components/BlankState";

const FOLLOWER_TIERS: Record<string, [number, number]> = {
  "Nano (1K-10K)": [1000, 10000],
  "Micro (10K-50K)": [10000, 50000],
  "Mid (50K-500K)": [50000, 500000],
  "Macro (500K-1M)": [500000, 1000000],
  "Mega (1M+)": [1000000, Infinity],
};

const CampaignOverview: React.FC = () => {
  const { campaign, setCampaign } = useContext(CampaignContext);
  const { contentReview } = useContext(ContentReviewContext);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  console.log("Campaign Overview Debug:", {
    contentReviewId: campaign?.content_review_id,
    contentReviewEnabled: campaign?.content_review_enabled,
    hasContentReviewContext: !!ContentReviewContext,
    hasContentReview: !!contentReview,
    isInsideContentReviewProvider: contentReview !== undefined,
  });

  const navigate = useNavigate();

  if (!campaign) {
    return null;
  }

  const hasParticipants = campaign?.participants?.length > 0;
  const hasBrief = campaign?.brief?.id != null;
  const hasDeliverables = campaign?.deliverables?.length > 0;

  const getAllContent = (
    contentReview: any,
    campaign: any
  ): ContentReviewContent[] => {
    if (!contentReview) {
      return [];
    }

    const { participants } = contentReview;
    let result = [] as ContentReviewContent[];
    const { participants: campaignParticipants } = campaign;

    participants.forEach((participant: any) => {
      const { deliverables, fohr_campaigns_participant_id } = participant;
      const campaignParticipant = campaignParticipants.find(
        ({ id }: any) => id === fohr_campaigns_participant_id
      );

      deliverables.forEach((deliverable: any) => {
        const { content } = deliverable;
        if (content) {
          result = [
            ...result,
            { ...content, deliverable, participant, campaignParticipant },
          ];
        }
      });
    });

    return result;
  };

  const content = useMemo(
    () => getAllContent(contentReview, campaign),
    [contentReview, campaign]
  );

  const contentByStatus = useMemo(() => groupBy(content, "status"), [content]);

  const getTotalOffers = () => {
    return campaign.participants.reduce((total, participant) => {
      return total + (participant.current_offer_price || 0);
    }, 0);
  };

  // Calculate campaign metrics
  const getMetrics = () => {
    if (!hasParticipants) return null;

    const totalReach = campaign.participants.reduce((sum, participant) => {
      const instagramFollowers = participant.instagram_stats?.followers || 0;
      const tiktokFollowers = participant.tiktok_stats?.followers || 0;
      return sum + instagramFollowers + tiktokFollowers;
    }, 0);

    const totalViews = campaign.participants.reduce((sum, participant) => {
      const instagramViews = participant.instagram_stats?.views || 0;
      const tiktokViews = participant.tiktok_stats?.views || 0;
      return sum + instagramViews + tiktokViews;
    }, 0);

    let totalEngagement = 0;
    let engagementCount = 0;
    campaign.participants.forEach((participant) => {
      if (participant.instagram_stats?.engagement_percentage) {
        totalEngagement += participant.instagram_stats.engagement_percentage;
        engagementCount++;
      }
      if (participant.tiktok_stats?.engagement_percentage) {
        totalEngagement += participant.tiktok_stats.engagement_percentage;
        engagementCount++;
      }
    });

    return {
      reach: totalReach,
      views: totalViews,
      engagement_rate:
        engagementCount > 0 ? (totalEngagement / engagementCount) * 100 : 0,
      participants: campaign.participants.length,
      budget: Number(campaign.budget) || 0,
      deliverables: campaign.deliverables?.length || 0,
    };
  };

  const metrics = getMetrics();

  const getTotalReach = () => {
    if (!hasParticipants) return 0;
    return metrics.reach;
  };

  const getTotalViews = () => {
    if (!hasParticipants) return 0;
    return metrics.views;
  };

  const getEngagementRate = () => {
    if (!hasParticipants) return 0;
    return metrics.engagement_rate;
  };

  const getTierBreakdown = () => {
    if (!hasParticipants) return [];

    const tiers = {
      "Nano (1K-10K)": { count: 0, color: "bg-blue_light" },
      "Micro (10K-50K)": { count: 0, color: "bg-blue_medium" },
      "Mid (50K-500K)": { count: 0, color: "bg-blue" },
      "Macro (500K-1M)": { count: 0, color: "bg-blue_secondary" },
      "Mega (1M+)": { count: 0, color: "bg-blue_dark" },
    };

    campaign.participants.forEach((participant) => {
      const instagramFollowers = participant.instagram_stats?.followers || 0;
      const tiktokFollowers = participant.tiktok_stats?.followers || 0;
      const totalFollowers = Math.max(instagramFollowers, tiktokFollowers);

      if (totalFollowers >= FOLLOWER_TIERS["Mega (1M+)"][0]) {
        tiers["Mega (1M+)"].count++;
      } else if (totalFollowers >= FOLLOWER_TIERS["Macro (500K-1M)"][0]) {
        tiers["Macro (500K-1M)"].count++;
      } else if (totalFollowers >= FOLLOWER_TIERS["Mid (50K-500K)"][0]) {
        tiers["Mid (50K-500K)"].count++;
      } else if (totalFollowers >= FOLLOWER_TIERS["Micro (10K-50K)"][0]) {
        tiers["Micro (10K-50K)"].count++;
      } else if (totalFollowers >= FOLLOWER_TIERS["Nano (1K-10K)"][0]) {
        tiers["Nano (1K-10K)"].count++;
      }
    });

    const total = campaign.participants.length;
    return Object.entries(tiers)
      .map(([tier, { count, color }]) => ({
        tier,
        count,
        color,
        percentage: (count / total) * 100,
      }))
      .filter((item) => item.count > 0);
  };

  const getMostProminentTier = () => {
    const tierBreakdown = getTierBreakdown();
    if (tierBreakdown.length === 0) return { tier: "", count: 0 };

    return tierBreakdown.reduce((max, current) =>
      current.count > max.count ? current : max
    );
  };

  const getParticipantTableData = () => {
    if (!campaign?.participants || !contentReview?.participants) return [];

    const participantData = campaign.participants.map((participant) => {
      const contentReviewParticipant = contentReview.participants.find(
        (p) => p.fohr_campaigns_participant_id === participant.id
      );

      const deliverableStatus =
        contentReviewParticipant?.deliverables?.map((d) => ({
          name: d.name,
          status: d.content?.status || "not_submitted",
          approved_at: d.content?.approved_at,
          submitted_on: d.content?.submitted_on,
        })) || [];

      const totalDeliverables = deliverableStatus.length;
      const approvedDeliverables = deliverableStatus.filter(
        (d) => d.status === "approved"
      ).length;

      const lastSubmission = deliverableStatus
        .map((d) => d.submitted_on)
        .filter(Boolean)
        .sort()
        .reverse()[0];

      const lastApproval = deliverableStatus
        .map((d) => d.approved_at)
        .filter(Boolean)
        .sort()
        .reverse()[0];

      return {
        id: participant.id,
        full_name: participant.creator?.full_name,
        profile_image: participant.creator?.profile_image_url,
        status: participant.status,
        deliverable_status: `${approvedDeliverables}/${totalDeliverables} Approved`,
        last_submission: lastSubmission,
        last_approval: lastApproval,
        instagram_stats: participant.instagram_stats,
        tiktok_stats: participant.tiktok_stats,
        // Add these fields for sorting
        _sortLastSubmission: lastSubmission
          ? new Date(lastSubmission).getTime()
          : 0,
        _sortStatus: participant.status === "accepted" ? 0 : 1,
      };
    });

    // Sort first by status (accepted first) then by last submission date (most recent first)
    return participantData.sort((a, b) => {
      // First sort by status
      if (a._sortStatus !== b._sortStatus) {
        return a._sortStatus - b._sortStatus;
      }
      // Then sort by last submission date
      return b._sortLastSubmission - a._sortLastSubmission;
    });
  };

  const participantTableColumns = [
    {
      key: "full_name",
      label: "Name",
      isImage: true,
      imageType: "profile" as "asset" | "profile",
      imageKey: "profile_image",
      isFirstColumn: true,
    },
    {
      key: "status",
      label: "Status",
      isStateBubble: true,
    },
    {
      key: "deliverable_status",
      label: "Deliverables",
    },
    {
      key: "last_submission",
      label: "Last Submission",
      getValue: (participant) =>
        participant.last_submission
          ? format(new Date(participant.last_submission), "MMM d, yyyy")
          : "--",
    },
    {
      key: "last_approval",
      label: "Last Approval",
      getValue: (participant) =>
        participant.last_approval
          ? format(new Date(participant.last_approval), "MMM d, yyyy")
          : "--",
    },
  ];

  // Helper function to check if campaign has any meaningful budget data
  const hasBudgetData = () => {
    return campaign?.budget && Number(campaign.budget) > 0;
  };

  // Helper function to check if campaign has any meaningful participant data
  const hasParticipantData = () => {
    return campaign?.participants?.length > 0;
  };

  // Helper function to check if campaign has any meaningful stats
  const hasStatsData = () => {
    return (
      hasParticipantData() &&
      (getTotalReach() > 0 || getTotalViews() > 0 || getEngagementRate() > 0)
    );
  };

  const handleCampaignEdited = (updatedCampaign: Campaign) => {
    setIsEditModalVisible(false);
    setCampaign(updatedCampaign);
  };

  return (
    <div className="space-y-8">
      <div className="flex justify-between items-center h-[40px] mt-[40px]">
        <h1 className="text-2xl">Overview</h1>
      </div>

      {/* Status, Budget, Content Review, and Timeline Bars */}
      <div className="grid grid-cols-4 gap-4">
        <CampaignTimeline campaign={campaign} contentReview={contentReview} />

        {/* Budget Section */}
        {/* {hasBudgetData() ? (
          <div className="bg-white p-6 shadow-sm rounded-lg border border-neutral_200">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-md font-medium">Budget</h3>
              <div
                className={`text-sm font-medium ${getTotalOffers() > Number(campaign.budget) ? "text-red" : "text-green"}`}
              >
                {Math.abs(
                  (getTotalOffers() / Number(campaign.budget) - 1) * 100
                ).toFixed(1)}
                %{" "}
                {getTotalOffers() > Number(campaign.budget) ? "Over" : "Under"}
              </div>
            </div>
            <div className="space-y-4">
              <div className="h-3 w-full bg-neutral_100 rounded-full overflow-hidden">
                <div
                  className="h-full bg-yellow transition-all duration-500"
                  style={{
                    width: `${Math.min((getTotalOffers() / Number(campaign.budget)) * 100, 100)}%`,
                  }}
                />
              </div>
              <div className="flex justify-between items-center mt-2">
                <div className="flex items-center space-x-2">
                  <div className="w-2 h-2 rounded-full bg-yellow" />
                  <span className="text-xs text-neutral_600">
                    Offers (${formatLargeNumber(getTotalOffers())})
                  </span>
                </div>
                <span className="text-xs text-neutral_600">
                  Budget: ${formatLargeNumber(Number(campaign.budget))}
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div className="bg-white p-6 shadow-sm rounded-lg border border-neutral_200">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-md font-medium">Budget</h3>
            </div>
            <div className="flex flex-col items-center justify-center h-[120px] text-neutral_600">
              <FIcon icon="dollar" size={24} color="#6B7280" />
              <p className="mt-2 text-sm">No budget set</p>
            </div>
          </div>
        )} */}

        <div className="bg-white p-6 shadow-sm rounded-lg border border-neutral_200">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-md font-medium">Followers Tier</h3>
            {getTierBreakdown().length > 0 && (
              <div className="text-sm font-medium text-blue">
                {getMostProminentTier().count} {getMostProminentTier().tier}
              </div>
            )}
          </div>
          <div className="space-y-4">
            <div className="h-3 w-full bg-neutral_100 rounded-full overflow-hidden flex">
              {getTierBreakdown().map(({ tier, percentage, color }, index) => (
                <div
                  key={tier}
                  className={`h-full ${color} transition-all duration-500 ${
                    index !== 0 ? "border-l border-white" : ""
                  }`}
                  style={{ width: `${percentage}%` }}
                />
              ))}
            </div>
            <div className="flex justify-between items-center mt-2">
              {getTierBreakdown().map(({ tier, count, color }) => (
                <div key={tier} className="flex items-center space-x-2">
                  <div className={`w-2 h-2 rounded-full ${color}`} />
                  <span className="text-xs text-neutral_600">
                    {tier} ({count})
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Participants Section */}
        <div className="bg-white p-6 shadow-sm rounded-lg border border-neutral_200">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-md font-medium">Participants</h3>
            <div className="text-sm font-medium text-neutral_600">
              {hasParticipantData()
                ? `${campaign.participants?.filter((p) => p.status === "accepted").length || 0} Accepted`
                : "No participants"}
            </div>
          </div>
          {hasParticipantData() ? (
            <div className="space-y-4">
              <div className="h-3 w-full bg-neutral_100 rounded-full overflow-hidden flex">
                {(() => {
                  const statusCounts = campaign.participants?.reduce(
                    (acc, participant) => {
                      const status = participant.status;
                      acc[status] = (acc[status] || 0) + 1;
                      return acc;
                    },
                    {} as Record<string, number>
                  );
                  return [
                    "accepted",
                    "review_pending",
                    "viewed",
                    "declined",
                    "removed",
                  ].map((status, index) => {
                    const count =
                      campaign.participants?.filter((p) => p.status === status)
                        .length || 0;
                    const total = campaign.participants?.length || 1;
                    const percentage = (count / total) * 100;
                    const colors = {
                      accepted: "bg-green",
                      review_pending: "bg-blue",
                      viewed: "bg-neutral_400",
                      declined: "bg-red",
                      removed: "bg-neutral_600",
                    };
                    return count > 0 ? (
                      <div
                        key={status}
                        className={`h-full ${colors[status as keyof typeof colors]} transition-all duration-500 ${
                          index !== 0 ? "border-l border-white" : ""
                        }`}
                        style={{ width: `${percentage}%` }}
                      />
                    ) : null;
                  });
                })()}
              </div>
              <div className="flex justify-between items-center mt-2">
                {[
                  "accepted",
                  "review_pending",
                  "viewed",
                  "declined",
                  "removed",
                ].map((status) => {
                  const count =
                    campaign.participants?.filter((p) => p.status === status)
                      .length || 0;
                  if (count === 0) return null;
                  const colors = {
                    accepted: "bg-green",
                    review_pending: "bg-blue",
                    viewed: "bg-neutral_400",
                    declined: "bg-red",
                    removed: "bg-neutral_600",
                  };
                  return (
                    <div key={status} className="flex items-center space-x-2">
                      <div
                        className={`w-2 h-2 rounded-full ${colors[status as keyof typeof colors]}`}
                      />
                      <span className="text-xs text-neutral_600 capitalize">
                        {status.replace("_", " ")} ({count})
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center text-neutral_600">
              <Link
                to={`/campaigns/${campaign.id}/participants`}
                className="mt-2 text-sm text-red hover:underline"
              >
                Add Participants →
              </Link>
            </div>
          )}
        </div>

        {/* Content Review Status */}
        <div className="bg-white p-6 shadow-sm rounded-lg border border-neutral_200">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-md font-medium">Content Review</h3>
            <div className="text-sm font-medium text-neutral_600">
              {campaign.content_review_enabled
                ? `${contentByStatus.approved?.length || 0} Approved`
                : "Disabled"}
            </div>
          </div>
          {campaign.content_review_enabled ? (
            hasParticipantData() ? (
              <div className="space-y-4">
                <div className="h-3 w-full bg-neutral_100 rounded-full overflow-hidden flex">
                  {(() => {
                    const statuses = [
                      "approved",
                      "pending",
                      "admin_changes",
                      "not_submitted",
                    ];
                    const colors = {
                      approved: "bg-green",
                      pending: "bg-blue",
                      admin_changes: "bg-yellow",
                      not_submitted: "bg-neutral_400",
                    };
                    const total = content.length || 1;

                    return statuses.map((status, index) => {
                      const count = contentByStatus[status]?.length || 0;
                      const percentage = (count / total) * 100;

                      return count > 0 ? (
                        <div
                          key={status}
                          className={`h-full ${colors[status as keyof typeof colors]} transition-all duration-500 ${
                            index !== 0 ? "border-l border-white" : ""
                          }`}
                          style={{ width: `${percentage}%` }}
                        />
                      ) : null;
                    });
                  })()}
                </div>
                <div className="flex justify-between items-center mt-2">
                  {[
                    "approved",
                    "pending",
                    "admin_changes",
                    "not_submitted",
                  ].map((status) => {
                    const count = contentByStatus[status]?.length || 0;
                    if (count === 0) return null;
                    const colors = {
                      approved: "bg-green",
                      pending: "bg-blue",
                      admin_changes: "bg-yellow",
                      not_submitted: "bg-neutral_400",
                    };
                    const labels = {
                      approved: "Approved",
                      pending: "Pending",
                      admin_changes: "Changes Needed",
                      not_submitted: "Not Submitted",
                    };
                    return (
                      <div key={status} className="flex items-center space-x-2">
                        <div
                          className={`w-2 h-2 rounded-full ${colors[status as keyof typeof colors]}`}
                        />
                        <span className="text-xs text-neutral_600">
                          {labels[status as keyof typeof labels]} ({count})
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center h-[120px] text-neutral_600">
                <FIcon icon="file-check" size={24} color="#6B7280" />
                <p className="mt-2 text-sm">No content to review yet</p>
              </div>
            )
          ) : (
            <div className="flex flex-col items-center justify-center text-neutral_600">
              <button
                onClick={() => setIsEditModalVisible(true)}
                className="mt-2 text-sm text-red hover:underline flex items-center gap-1"
              >
                <FIcon icon="settings" size={14} color="#E47667" />
                Enable in Campaign Settings
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="grid grid-cols-4 gap-8">
        {/* Left Column - Stats */}
        <div className="col-span-1">
          <div>
            <h4 className="text-lg font-medium mb-4">Summary</h4>
            <div className="bg-light_red shadow-sm rounded-lg border border-neutral_200 p-6 mb-6">
              <div className="space-y-4">
                <div className="flex justify-between items-center">
                  <div className="text-sm text-neutral_600">Status</div>
                  <div className="text-sm font-medium  capitalize">
                    {campaign.status}
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <div className="text-sm text-neutral_600">Budget</div>
                  <div className="text-sm font-medium">
                    {formatDollarValue(Number(campaign.budget) || 0)}
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <div className="text-sm text-neutral_600">Dates</div>
                  <div className="text-sm font-medium">
                    {campaign.brief?.go_live_start &&
                    campaign.brief?.go_live_end
                      ? `${new Date(campaign.brief.go_live_start).toLocaleDateString()} - ${new Date(campaign.brief.go_live_end).toLocaleDateString()}`
                      : "Not set"}
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <div className="text-sm text-neutral_600">Deliverables</div>
                  {campaign.deliverables?.length > 0 ? (
                    <div className="text-sm font-medium">
                      {campaign.deliverables?.length || 0}
                    </div>
                  ) : (
                    <Link
                      to={`/campaigns/${campaign.id}/deliverables`}
                      className="text-sm text-red hover:underline"
                    >
                      Add →
                    </Link>
                  )}
                </div>
                <div className="flex justify-between items-center">
                  <div className="text-sm text-neutral_600">Participants</div>
                  {campaign.participants.length > 0 ? (
                    <div className="text-sm font-medium">
                      {campaign.participants?.length || 0}
                    </div>
                  ) : (
                    <Link
                      to={`/campaigns/${campaign.id}/participants`}
                      className="text-sm text-red hover:underline"
                    >
                      Add →
                    </Link>
                  )}
                </div>
                <div className="flex justify-between items-center">
                  <div className="text-sm text-neutral_600">Manager</div>
                  <a
                    href={`mailto:${campaign.owner_user.email}`}
                    className="flex items-center gap-2 text-sm text-black"
                  >
                    <FIcon icon="email" size={16} color="black" />
                    {campaign.owner_user.full_name}
                  </a>
                </div>
              </div>
            </div>
          </div>
          {hasStatsData() && (
            <div>
              <h4 className="text-lg font-medium mb-4">Participant Stats</h4>
              <div className="bg-light_red shadow-sm rounded-lg border border-neutral_200 p-6 mb-6">
                <div className="space-y-4">
                  <div className="flex justify-between items-center">
                    <div className="text-sm text-neutral_600">Total Reach</div>
                    <div className="text-sm font-medium">
                      {formatLargeNumber(metrics?.reach || 0)}
                    </div>
                  </div>
                  <div className="flex justify-between items-center">
                    <div className="text-sm text-neutral_600">Total Views</div>
                    <div className="text-sm font-medium">
                      {formatLargeNumber(metrics?.views || 0)}
                    </div>
                  </div>
                  <div className="flex justify-between items-center">
                    <div className="text-sm text-neutral_600">
                      Avg. Engagement Rate
                    </div>
                    <div className="text-sm font-medium">
                      {(metrics?.engagement_rate || 0).toFixed(2)}%
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Right Column - Main Content */}
        <div className="col-span-3 space-y-8">
          <div>
            <div className="flex justify-between items-center mb-4">
              <h4 className="text-lg font-medium mb-0">Participants</h4>
              <Link
                to={`/campaigns/${campaign.id}/participants`}
                className="text-sm flex items-center text-red hover:underline"
              >
                Add Participants →
              </Link>
            </div>
            {hasParticipantData() ? (
              <div className="bg-white rounded-lg border border-neutral_200 mb-8 p-4 shadow-sm">
                <Table
                  data={getParticipantTableData()}
                  columns={participantTableColumns}
                  lastRowNoBorder={true}
                />
              </div>
            ) : (
              <BlankState
                className="!mt-0"
                title="No Participants Yet"
                subtitle="Start by adding participants to your campaign. You'll be able to track their performance and deliverables here."
                actionLabel="Add Participants"
                onActionClick={() =>
                  navigate(`/campaigns/${campaign.id}/participants`)
                }
                icon="user"
              />
            )}
          </div>
        </div>
      </div>

      {isEditModalVisible && (
        <CreateOrUpdateCampaignModal
          entity={campaign}
          onClose={() => setIsEditModalVisible(false)}
          onUpdated={handleCampaignEdited}
        />
      )}
    </div>
  );
};

export default CampaignOverview;
