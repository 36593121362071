import axios from "axios";
import { ApiResponse, GroupData } from "../types/apiTypes";
import { LOCAL_API_ORIGIN } from "../constants/api";

const API_BASE_URL = `${LOCAL_API_ORIGIN}/api/v1/memberships`;

const create = async (
  params: {

    brand_id: number,
    user_id: number,
    account_id: number
  }

): Promise<ApiResponse> => {
  try {
    const response = await axios.post(`${API_BASE_URL}`, {
      membership: {
        brand_id: params.brand_id,
        user_id: params.user_id,
        account_id: params.account_id
      }
    });
    return {
      success: true,
      message: `created successfully`,
      data: { ...response.data },
    };
  } catch (error: any) {
    return {
      success: false,
      message: `Failed to create`,
    };
  }
};
const destroy = async (
  params: {
    id: number,
  }
): Promise<ApiResponse> => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/${params.id}`);
    return {
      success: true,
      message: `Removed successfully`,
      data: { ...response.data },
    };
  } catch (error: any) {
    return {
      success: false,
      message: `Failed to remove`,
    };
  }
};

export { create, destroy};
