// General post modal, used in search results, does not include approve/reject functionality for campaigns content

import React, { ReactNode, useEffect, useState } from "react";
import FButton from "../FButton";
import FIcon from "../FIcon";
import { PostProps } from "@types";
import { addCommas } from "@utils/number_utilities";
import PostComplete from "@components/Post/PostComplete";
import { ShowMore } from "@re-dev/react-truncate";
import FCheckbox from "@components/FInputs/FCheckbox";
import { toast } from "react-hot-toast";
import { useLocation } from "react-router-dom";

interface PostModalProps {
  post: PostProps;
  className?: string;
  isOpen: boolean;
  onClose?: () => void;
  onAction?: (changes?: any) => void;
  postCustomContent?: ReactNode;
  children?: ReactNode;
  topRightContent?: ReactNode;
  zIndex?: number;
}

const renderStats = (post: PostProps) => {
  const stats = [
    { label: "Published", value: post.published_at },
    { label: "Followers", value: post.followers },
    { label: "Category", value: post.categories },
    { label: "Likes", value: post.likes_count },
    { label: "Comments", value: post.comments_count },
    { label: "Views", value: post.views_count },
    { label: "Reach", value: post.reach },
  ];

  return (
    <div className="flex flex-col">
      {stats
        .filter(
          (stat) =>
            stat.value !== undefined &&
            stat.value !== null &&
            stat.value !== 0 &&
            stat.value !== ""
        )
        .map((stat, index) => (
          <div
            key={stat.label}
            className={`flex flex-row justify-between text-[15px] pt-[8px] pb-[6px] px-2 text-dark_night_sky ${
              index % 2 === 0 ? "bg-highlight_red" : ""
            }`}
          >
            <p className="font-medium mr-[40px]">{stat.label}</p>
            <p className="text-right">
              {stat.label === "Published" || stat.label === "Category"
                ? stat.value
                : addCommas(Number(stat.value))}
            </p>
          </div>
        ))}
    </div>
  );
};

const GeneralPostModal = ({
  post,
  isOpen,
  onClose,
  onAction,
  children,
  topRightContent,
  postCustomContent,
  zIndex,
  className,
}: PostModalProps) => {
  const [changes] = useState({});
  const [bonus, setBonus] = useState(post.bonus);

  const location = useLocation();

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  if (!isOpen) return null;

  const handleCheckBonus = async () => {
    try {
      const response = await fetch(`/api/v1/participant_contents/${post.id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          bonus: !bonus,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update bonus status");
      }

      const updatedPost = await response.json();
      setBonus(updatedPost.bonus);
      console.log("Bonus status updated:", updatedPost.bonus);
    } catch (error) {
      console.error("Error updating bonus status:", error);
    }
  };

  return (
    <div
      className={`fixed inset-0 bg-dark_night_sky bg-opacity-70 flex items-center justify-center z-[${zIndex || 9999}]`}
      onClick={onClose}
    >
      <div
        className={`bg-light_red rounded-2xl max-h-[80vh] h-full shadow-2xl overflow-hidden w-auto flex flex-row relative ${className}`}
        onClick={(e) => e.stopPropagation()}
      >
        <a
          href={post.permalink}
          target="_blank"
          className="h-full p-2 overflow-hidden"
        >
          <div className="w-[450px] h-full pr-0 relative rounded-2xl overflow-hidden flex items-center justify-center bg-highlight_red">
            <PostComplete
              post={post}
              hidePlatformIcon
              height={undefined}
              width={undefined}
              showPostType={undefined}
            />
            {postCustomContent}
          </div>
        </a>

        <div className="w-[450px] relative bg-light_red px-8 pt-12 pb-8">
          <div
            className="absolute top-4 right-4 cursor-pointer"
            onClick={onClose}
          >
            {topRightContent || (
              <FIcon size={20} color="#000F45" icon="exit-2" />
            )}
          </div>
          <div className="flex flex-row pb-4 justify-between w-full">
            <div className="flex gap-4 w-full">
              <a
                href={post.profile_link}
                className="relative z-10 bg-white border border-light_border flex justify-center items-center h-[52px] w-[52px] rounded-full hover:opacity-80"
                onClick={(e) => e.stopPropagation()}
              >
                {post.profile_image ? (
                  <div
                    className="bg-cover bg-center rounded-3xl"
                    style={{
                      backgroundImage: `url(${post.profile_image})`,
                      height: "52px",
                      width: "52px",
                    }}
                  />
                ) : (
                  <FIcon
                    icon="user-bold"
                    size={16}
                    color="#000F45"
                    className="inline-block vertical-align-middle"
                  />
                )}
              </a>
              <div className="text-[15px] text-dark_night_sky flex flex-col justify-center">
                <a
                  href={post.profile_link}
                  className="font-medium hover:underline"
                  onClick={(e) => e.stopPropagation()}
                >
                  {post.full_name}
                </a>
                <a
                  href={post.social_profile_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[13px] text-neutral_500 hover:underline"
                  onClick={(e) => e.stopPropagation()}
                >
                  {"@" + post.username}
                </a>
              </div>
            </div>
          </div>
          <div
            className="relative flex flex-col overflow-y-scroll"
            style={{
              height: "calc(100% - 70px)",
            }}
          >
            {post.caption && (
              <div className="border-t border-light_border py-4">
                <p className="text-[12px] uppercase font-medium text-neutral_500 mb-2">
                  Caption
                </p>
                <ShowMore lines={2}>
                  <p>{post.caption}</p>
                </ShowMore>
              </div>
            )}
            {!children && (
              <div className="border-t border-light_border py-4">
                <p className="text-[12px] uppercase font-medium text-neutral_500 mb-3">
                  Details
                </p>
                {renderStats(post)}
              </div>
            )}
            <div className="flex flex-col flex-1 gap-[0.5rem] w-full">
              {children}
            </div>
          </div>
          {Object.keys(changes).length > 0 && (
            <div className="flex justify-end w-full bg-light_red w-full absolute px-8 box-shadow-top py-4 bottom-0 right-0 z-10 space-x-2 mt-4">
              <FButton onClick={onClose} label="Cancel" />
              <FButton
                onClick={() => {
                  onAction(changes);
                  onClose();
                }}
                label={"Save"}
                primary
                type="button"
              />
            </div>
          )}
          {location.pathname.includes("attributed-content") ||
            (location.pathname.includes("bonus-content") && (
              <div className="absolute right-0 pr-8">
                <FCheckbox
                  label="Bonus Content"
                  id={"bonus"}
                  className="w-full"
                  checked={bonus}
                  onChange={() => {
                    if (post.bonus === true) {
                      onClose();
                      toast.error(
                        "Bonus content status cannot be updated for this post."
                      );
                    } else {
                      setBonus(!bonus);
                      handleCheckBonus();
                    }
                  }}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default GeneralPostModal;
