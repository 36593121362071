import React, { useEffect, useState } from "react";

import { toast } from "react-hot-toast";

import { FCheckbox, FInput } from "@components/FInputs";

import FDropdown from "@components/FDropdown";

import { Pod } from "@apiTypes";
import { Campaign, ContractTemplate, KIND } from "@types";
import { createCampaign, updateCampaign } from "@api/Campaign/Campaigns";
import useAuth from "@hooks/useAuth";

import moment from "moment";
import { getPods } from "@api/pods";
import { capitalize, isEqual, omit, uniqBy } from "lodash";
import { getCampaignManagers } from "@api/user";
import { Membership } from "@types";
import { getContractTemplateIndex } from "@api/ContractTemplates/ContractTemplates";
import FRangePicker from "@components/FDatePicker/FRangePicker";
import FButton from "@components/FButton";

const KINDS = [KIND.PAID, KIND.GIFTING];

interface Props {
  entity?: Campaign;
  onClose?: () => void;
  onUpdated?: (campaign: Campaign) => void;
  onCreated?: (campaign: Campaign) => void;
}

export default function UpdateCampaignForm({
  onUpdated,
  onCreated,
  onClose,
  entity,
}: Props) {
  const [loading, setLoading] = useState(false);
  const [pods, setPods] = useState<Pod[]>([]);
  const [memberships, setMemberships] = useState<Membership[]>([]);
  const [contractTemplates, setContractTemplates] = useState<
    ContractTemplate[]
  >([]);

  const [isSubmitted, setSubmitted] = useState(false);

  const [values, setValues] = useState<Partial<Campaign>>({
    agency_name: "",
    kind: KINDS[0],
    brief_attributes: {
      go_live_start: moment().format(),
      go_live_end: moment().format(),
    },
    brand_content_approvers: "",
    budget: "0",
    auto_content_attribution: true,
    skip_participant_mailers: false,
    salesforce_project_number: "",
    title: "",
  });

  const { user } = useAuth();

  const isFohrUser = user && user.email.endsWith("fohr.co");

  useEffect(() => {
    const loadPods = async () => {
      const pods = await getPods();

      setPods(pods);
    };

    const loadMemberships = async () => {
      const response = await getCampaignManagers(user.brand_id);

      setMemberships(uniqBy(response.data.memberships, ({ user }) => user.id));
    };

    const loadContractTemplates = async () => {
      const response = await getContractTemplateIndex();

      setContractTemplates(response.data.contract_templates);
    };

    if (isFohrUser) {
      loadPods();
      loadMemberships();
      loadContractTemplates();
    }

    if (!entity) {
      return;
    }

    setValues((values) =>
      omit(
        {
          ...values,
          ...entity,
        },
        ["brief_attributes", "skip_participant_mailers"]
      )
    );
  }, [user.brand_id, entity, isFohrUser]);

  const handleUpdate = (updates: Partial<Campaign>) =>
    setValues({ ...values, ...updates });

  const handleNext = async () => {
    setSubmitted(true);

    const { title, budget, contract_template_id, user_id } = values;
    if (
      !title ||
      !budget ||
      (values.kind !== KIND.GIFTING && !contract_template_id) ||
      !user_id
    ) {
      return;
    }

    setLoading(true);

    try {
      if (entity) {
        const diff = {};

        Object.keys(values).forEach((key) => {
          if (!isEqual(values[key], entity[key])) {
            diff[key] = values[key];
          }
        });

        const response = await updateCampaign(entity.id, diff);

        if (onUpdated) {
          onUpdated(response.data);
        }

        toast.success("Campaign Updated");
      } else {
        const response = await createCampaign({
          ...values,
          brand_id: user.brand_id,
        });

        onCreated(response.data);
      }
    } catch (e) {
      toast.error(`Error ${entity ? "updating" : "creating"} campaign`);
    } finally {
      setLoading(false);
    }
  };

  const { brand_name } = user;

  return (
    <div className="flex flex-col gap-6 m-auto w-full">
      <FInput
        name="title"
        onChange={(value) => handleUpdate({ title: value })}
        label="Campaign Title"
        value={values.title}
        required
        validate={isSubmitted}
        width="100%"
      />
      <div className="flex gap-4">
        <FDropdown
          label="Brand"
          disabled
          options={[
            {
              label: brand_name,
              value: brand_name,
            },
          ]}
          selectedValue={brand_name}
          height="40"
          width="100%"
        />
        <FInput
          label="Agency"
          value={values.agency_name}
          width="100%"
          type="text"
          onChange={(value) => handleUpdate({ agency_name: value })}
        />
      </div>
      {!entity ? (
        <FRangePicker
          minDate={moment().format()}
          value={{
            from: values.brief_attributes.go_live_start,
            to: values.brief_attributes.go_live_end,
          }}
          onChange={({ from, to }) =>
            handleUpdate({
              brief_attributes: {
                ...values.brief_attributes,
                go_live_start: from,
                go_live_end: to,
              },
            })
          }
        />
      ) : null}
      <div className="flex gap-4">
        <FDropdown
          label="Type"
          options={KINDS.map((value) => ({
            label: capitalize(value),
            value,
          }))}
          selectedValue={values.kind}
          onChange={(value) => handleUpdate({ kind: value as string })}
          height="40"
          width="100%"
        />
        <FInput
          label="Budget"
          prefix="$"
          value={values.budget}
          width="100%"
          type="number"
          validate={isSubmitted}
          onChange={(value) => handleUpdate({ budget: value })}
          required
        />
      </div>
      {isFohrUser ? (
        <div className="flex gap-4">
          <FDropdown
            hasNoDefault
            label="Manager"
            options={memberships.map(
              ({
                user: { id, first_name: firstName, last_name: lastName },
              }) => ({
                label: `${firstName} ${lastName}`,
                value: id,
              })
            )}
            selectedValue={values.user_id}
            onChange={(value) => handleUpdate({ user_id: value as number })}
            height="40"
            validate={isSubmitted}
            required
            width="100%"
          />
          <FDropdown
            label="Pod"
            options={pods.map((pod) => ({
              label: pod.name,
              value: pod.id,
            }))}
            selectedValue={values.pod}
            onChange={(value) => handleUpdate({ pod: value as string })}
            height="40"
            width="100%"
          />
        </div>
      ) : null}
      {isFohrUser ? (
        <FDropdown
          hasNoDefault
          label="Contract Template"
          options={contractTemplates.map(({ id, name }) => ({
            value: id,
            label: name,
          }))}
          selectedValue={values.contract_template_id}
          onChange={(value) =>
            handleUpdate({ contract_template_id: value as number })
          }
          validate={isSubmitted}
          required={values.kind !== KIND.GIFTING}
          height="40"
          width="100%"
        />
      ) : null}
      <FInput
        label="Project Number"
        value={values.salesforce_project_number}
        width="100%"
        onChange={(value) => handleUpdate({ salesforce_project_number: value })}
      />
      <div className="flex gap-4">
        <FCheckbox
          className="w-full"
          label="Enable automatic content attribution"
          id="checkbox1"
          onChange={(value) =>
            handleUpdate({
              auto_content_attribution: value,
            })
          }
          checked={values.auto_content_attribution}
        />
        <FCheckbox
          className="w-full"
          label="Skip participant mailers"
          id="skip_participant_mailers"
          onChange={(value) =>
            handleUpdate({
              skip_participant_mailers: value,
            })
          }
          checked={values.skip_participant_mailers}
        />
      </div>
      {values.kind !== KIND.GIFTING ? (
        <>
          <div>
            <p className="text-xl">Content Review</p>
            <div className="bg-neutral_400 h-[1px] mt-1 w-full" />
          </div>
          <FCheckbox
            className="w-full"
            label="Content Review Enabled"
            id="checkboxContentReviewEnabled"
            onChange={(value) =>
              handleUpdate({
                content_review_enabled: value,
              })
            }
            checked={values.content_review_enabled}
          />
          <FInput
            label="Brand approvers (add a list of email addresses separated by commas)"
            description="This is the list of people outside of your organization authorized to review and approve content in Content Review."
            className="w-full"
            placeholder="e.g user1@fohr.co, user2@fohr.co"
            value={values.brand_content_approvers}
            onChange={(value) =>
              handleUpdate({ brand_content_approvers: value })
            }
            width="100%"
          />
        </>
      ) : null}

      <div className="flex justify-center space-x-4 mt-[40px]">
        <FButton onClick={onClose} label="Cancel" width="140px" height="40px" />
        <FButton
          onClick={handleNext}
          label="Save"
          loading={loading}
          width="140px"
          height="40px"
          primary
          type="submit"
        />
      </div>
    </div>
  );
}
