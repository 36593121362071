import Table from "@components/Table";
import {
  Campaign,
  CampaignContentReview,
  ParticipantContentReview,
} from "@types";
import STATUSES from "../statuses";
import { isEmpty } from "lodash";

import BlankState from "@components/BlankState";

const getParticipantsWithNotSubmittedContent = (
  participants: ParticipantContentReview[]
) =>
  participants.reduce((result, participant) => {
    const { deliverables } = participant;

    const deliverablesWithNotSubmittedContent = deliverables.filter(
      ({ content }) => content?.status === "not_submitted"
    );

    if (isEmpty(deliverablesWithNotSubmittedContent)) {
      return result;
    }

    return [
      ...result,
      {
        ...participant,
        deliverables: deliverablesWithNotSubmittedContent,
      },
    ];
  }, []);

interface ParticipantDeliverablesProps {
  campaign: Campaign;
  contentReview: CampaignContentReview;
  showNotSubmitted?: boolean;
}

const ParticipantDeliverables = ({
  campaign,
  contentReview,
  showNotSubmitted,
}: ParticipantDeliverablesProps) => {
  const { participants } = campaign;

  console.log("Participants", participants, contentReview);

  if (!contentReview) {
    return null;
  }

  const TABLE_COLUMNS = [
    {
      key: "name",
      label: "Name",
      isLink: true,
      linkURL: "/campaigns/{campaign_id_actual}/deliverables",
      isFirstColumn: true,
    },
    {
      key: "platform",
      label: "Platform",
      type: "platform",
    },
    {
      key: "submission_due_date",
      label: "Due date",
      isDate: true,
    },
    {
      key: "hashtags",
      label: "Hashtags",
    },
    {
      key: "mentions",
      label: "Mentions",
    },
    {
      key: "status",
      isStateBubble: true,
      label: "Status",
      getValue: (item) => {
        const statusValue = item.content?.status || "not_submitted";

        const status = STATUSES.find(({ value }) => value === statusValue);

        return status.label;
      },
    },
    {
      key: "updated_at",
      label: "Modified",
      isDate: true,
    },
  ];

  const { participants: contentReviewParticipants } = contentReview;

  const participantsToShow = showNotSubmitted
    ? getParticipantsWithNotSubmittedContent(contentReviewParticipants)
    : contentReviewParticipants;

  if (showNotSubmitted && isEmpty(participantsToShow)) {
    return (
      <BlankState
        title="No content at this stage"
        subtitle={`No content found matching the selected stage`}
        icon="search"
      />
    );
  }

  return (
    <div className="flex flex-col gap-8">
      {participantsToShow.map((participant) => {
        const { deliverables, fohr_campaigns_participant_id, id } = participant;

        const campaignParticipant = participants.find(
          ({ id }) => id === fohr_campaigns_participant_id
        );

        const {
          creator: { firstName, lastName, profile_image_url },
        } = campaignParticipant;

        const contentSubmitted = deliverables.reduce((result, deliverable) => {
          const { content } = deliverable;

          if (content?.status === "not_submitted") {
            return result;
          }

          return [...result, content];
        }, []);

        const reformedDeliverables = deliverables.map((deliverable) => ({
          ...deliverable,
          campaign_id_actual: campaign.id,
        }));

        return (
          <div
            className="border border-light_border rounded-lg bg-white shadow-sm"
            key={id}
          >
            <div className="border-b border-light_border flex items-center p-7">
              <div className="flex items-center">
                <div
                  className={`bg-center bg-cover w-[46px] h-[46px] mr-3 rounded-full`}
                  style={{
                    backgroundImage: `url(${profile_image_url})`,
                  }}
                />
                <div>
                  <p className="text-lg ">
                    {firstName} {lastName}
                  </p>
                  <p className="text-default_weak flex flex-row gap-2 text-sm">
                    <span className="">
                      {deliverables.length} Deliverable
                      {deliverables.length === 1 ? "" : "s"}
                    </span>
                    <span className="text-neutral_400">|</span>
                    <span className="">
                      {contentSubmitted.length} Submitted
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="p-4">
              <Table
                lastRowNoBorder
                columns={TABLE_COLUMNS}
                data={reformedDeliverables}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ParticipantDeliverables;
