import React, { useCallback } from "react";
import { Sort } from "@types";
import { getReportContent } from "@api/Reports";
import SinglePageLayoutWithData from "@layouts/SinglePageLayoutWithData";
import { formatPlatform } from "@utils/string_utilities";

const tableColumns = [
  {
    key: "full_name",
    label: "Name",
    isLink: true,
    linkURL: "{permalink}",
  },
  {
    key: "published_at",
    label: "Published At",
    isDate: true,
  },
  {
    key: "platform",
    label: "Platform",
    getValue: (item) => {
      return formatPlatform(item.platform);
    },
  },
  {
    key: "media_type",
    label: "Media Type",
  },
  {
    key: "views_count",
    label: "Views",
    isNumber: true,
  },
  {
    key: "interactions",
    label: "Engagements",
    isNumber: true,
  },
  {
    key: "engagement_rate",
    label: "Engagement %",
    isPercentage: true,
  },
  {
    key: "viewership_rate",
    label: "Viewership %",
    isPercentage: true,
  },
];

export default function ReportContentTable({ report_id, onCardClick }) {
  const getContent = useCallback(
    async (page?: number, sort?: Sort, params?) => {
      const { search, filter } = params;

      const response = await getReportContent(report_id, page, sort, {
        creator_firstName_or_creator_lastName_cont: search,
      });
      const { posts: items } = response.data;

      return {
        ...response.data,
        items,
      };
    },
    []
  );

  return (
    <div className="py-10">
      <SinglePageLayoutWithData
        entityName="content"
        pageName="Content"
        cardType="content"
        defaultGrid={true}
        tableColumns={tableColumns}
        hideCreateNewButton
        getItems={getContent}
        onCardClick={onCardClick}
      />
    </div>
  );
}
