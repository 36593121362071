import FButton from "@components/FButton";
import RichTextEditor from "@components/RichTextEditor";
import { useEffect, useState } from "react";

interface RationaleProps {
  id: string;
  isSaving: boolean;
  description: string;
  onSubmit: (value: string) => void;
  width?: string;
  label?: string;
}

const RationaleEditor = ({
  id,
  description,
  onSubmit,
  isSaving,
  width,
  label,
}: RationaleProps) => {
  const [isEditingDescription, setEditingDescription] = useState(false);

  const [pendingDescription, setPendingDescription] = useState("");

  useEffect(() => {
    setEditingDescription(false);
  }, [description]);

  const handleEditDescription = () => {
    setPendingDescription(description);
    setEditingDescription(true);
  };

  if (isEditingDescription) {
    return (
      <div className={`flex flex-col gap-2 w-[${width || "400px"}]`}>
        <div className="bg-white w-full my-4">
          <RichTextEditor
            value={pendingDescription}
            id={id}
            onChange={setPendingDescription}
          />
        </div>
        <div className="flex gap-2 justify-end w-full">
          <FButton
            label="Save"
            onClick={() => onSubmit(pendingDescription)}
            loading={isSaving}
            primary
          />
          <FButton
            label="Cancel"
            onClick={() => setEditingDescription(false)}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="flex gap-2 justify-start">
      {description ? (
        <p
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      ) : (
        <p>No {label || "description"}</p>
      )}
      <FButton
        className=""
        height={20}
        icon={{ color: "#000F45", name: "edit", size: 14 }}
        iconButton
        onClick={handleEditDescription}
      />
    </div>
  );
};

export default RationaleEditor;
