import OnboardingStepContainer from "../components/OnboardingStepContainer";
import { useNavigate, useParams } from "react-router-dom";

import FButton from "@components/FButton";

import { Creator } from "@apiTypes";
import { useEffect, useState } from "react";

import LoadingSpinner from "@components/LoadingSpinner";
import { getCreator } from "@api/Creators/Creators";
import toast from "react-hot-toast";
import useAuth from "@hooks/useAuth";
import { ETHNICITIES, GENDERS } from "@constants/demographics";

const getProperties = (user: Creator) => {
  const {
    address,
    firstName,
    lastName,
    gender = [],
    ethnicity,
    profile_image_url,
  } = user;

  const genderLabel = gender
    .map((item) => GENDERS.find(({ value }) => value === item).label)
    .join(", ");

  const ethnicityLabel = ethnicity
    .map((item) => ETHNICITIES.find(({ value }) => value === item).label)
    .join(", ");

  return [
    {
      name: "Photo",
      image: profile_image_url,
    },
    {
      name: "Name",
      value: `${firstName || ""} ${lastName || ""}`,
    },
    {
      name: "Address",
      value: address,
    },
    {
      name: "Gender",
      value: genderLabel,
    },
    {
      name: "Ethnicity",
      value: ethnicityLabel,
    },
  ];
};

const ConfirmDetails = () => {
  const { brand } = useParams<{ brand: string }>();

  const [loading, setLoading] = useState(true);

  const [creator, setCreator] = useState<Creator>(null);

  const { user } = useAuth();

  useEffect(() => {
    if (!user) {
      return;
    }

    const fetchCreatorData = async () => {
      try {
        const response = await getCreator(user.creator_id);

        const creator = response.data;

        const { about_you } = creator;

        const {
          address_city,
          address_country,
          address_line_1,
          address_state,
          ethnicity,
        } = about_you;

        const address = `${address_line_1}, ${address_city}, ${address_state} ${address_country}`;

        setCreator({ ...creator, address, ethnicity });
      } catch (error) {
        toast("Failed to fetch creator data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCreatorData();
  }, [user]);

  const navigate = useNavigate();

  const handleContinue = () =>
    navigate(
      brand ? `/${brand}/ambassador-onboarding/submit-application` : "/"
    );

  if (loading) {
    return <LoadingSpinner className="w-full h-[90vh]" />;
  }

  return (
    <OnboardingStepContainer
      title={brand ? null : "Confirm your details"}
      description="Confirm your details"
      currentStep={brand ? 2 : 3}
      stepsCount={3}
      isBrand={!!brand}
    >
      <div className="w-[100%] sm:w-[440px] overflow-auto">
        <div className="flex items-center justify-between pb-4 px-4 border-b border-neutral_400 w-full">
          <span>Personal Info</span>
          <FButton
            iconLeft={{ color: "#000721", name: "pencil", size: 16 }}
            label="Edit"
            onClick={() =>
              brand
                ? navigate(`/${brand}/ambassador-onboarding/add-personal-info`)
                : navigate(-1)
            }
          />
        </div>
        {creator ? (
          <div className="flex flex-col px-4 py-4 border-b border-neutral_400 gap-1 w-full">
            {getProperties(creator).map(({ image, name, value }, index) => (
              <div className="align-start flex gap-2 py-2" key={index}>
                <span className="w-[140px]">{name}</span>
                {image ? (
                  <img width={80} height={80} src={image} />
                ) : (
                  <span className="flex-1">{value}</span>
                )}
              </div>
            ))}
          </div>
        ) : null}
        <FButton
          height="48px"
          loading={loading}
          width="100%"
          primary
          label={brand ? "Continue to questions" : "Continue to Fohr"}
          onClick={handleContinue}
        />
      </div>
    </OnboardingStepContainer>
  );
};

export default ConfirmDetails;
