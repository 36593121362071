import React, { useRef, useState } from "react";
import { FTooltip } from "../../../FTooltip";
import FIcon from "@components/FIcon";
import { formatLargeNumber } from "../../../../utils/number_utilities";
import { getResizedImageURL } from "@utils/image";
import FButton from "@components/FButton";
import VideoPlayer from "@components/Post/VideoPlayer";
import { Link } from "react-router-dom";

const ContentCard = ({
  post,
  className,
  imageOnly = false,
  isProfile,
  cardHeight,
  cardWidth,
  checkBoxClickList,
  onCheckBoxClick,
  onCardClick,
  platform,
  permalink,
  full_name,
  profile_image,
  media_type,
  content_type,
  published_at,
  onDelete,
  onViewStatsScreenshot,
  isHighlighted = false,
  username,
  customStats,
  ...props
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPaused, setIsPaused] = useState(true);
  const [hover, setHover] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const formatUsername = (username: string) => {
    if (!username) return "";
    return username
      .split(/[-_.]/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ")
      .trim();
  };

  const displayName =
    full_name?.trim().toLowerCase() || formatUsername(username);

  const togglePlayPause = (e) => {
    e.stopPropagation();

    if (videoRef.current) {
      if (isPaused) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
      setIsPaused(!isPaused);
    }
  };

  const photo_url = props.image?.original || props.photo_url || props.file_url;
  const stockImage = photo_url && !photo_url.includes("missing_default");
  const isVideo = props.image_content_type?.includes("video");

  const contentTypeBannerText = (content_type, platform) => {
    if (
      platform === "instagram" &&
      (content_type === "image" || content_type === "video")
    ) {
      return "In-feed Post";
    } else if (
      platform === "instagram" ||
      platform === "tiktok" ||
      platform === "youtube"
    ) {
      return content_type;
    } else {
      return "Post";
    }
  };

  const ContentTypeBanner = () => {
    return (
      <span className="justify-center px-[10px] items-center bg-[#2B2C33] bg-opacity-60 rounded-md pt-[4px] pb-[2px] bg-blend-multiply">
        <p className="text-white text-[12px] font-medium capitalize">
          {media_type
            ? media_type
            : contentTypeBannerText(content_type, platform)}
        </p>
      </span>
    );
  };

  const isSelected =
    isHighlighted ||
    (checkBoxClickList && checkBoxClickList.includes(props.id));

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() =>
        onCardClick &&
        onCardClick({
          post,
          className,
          imageOnly,
          isProfile,
          cardHeight,
          cardWidth,
          checkBoxClickList,
          platform,
          permalink,
          full_name,
          profile_image,
          media_type,
          content_type,
          published_at,
          username,
          ...props,
        })
      }
      className={`flex flex-col w-full cursor-pointer shadow-lg bg-highlight_red relative overflow-hidden h-full min-h-[400px] rounded-xl relative ${className} ${
        isSelected
          ? "border-2 border-dark_night_sky"
          : "border-1 border-light_red"
      } ${isDropdownOpen ? "z-50" : "z-10"}`}
      key={post?.content_id ? post.content_id : props.id}
      style={{
        minHeight: `${cardHeight}px`,
        minWidth: `${cardWidth}px`,
      }}
    >
      <div className="relative flex flex-row top-0 left-0 z-20 w-full">
        <div className="flex absolute top-3 left-[12px] justify-between items-center gap-2 w-[calc(100%-24px)]">
          {!onCheckBoxClick && onDelete ? null : (
            <div>
              {onCheckBoxClick && (
                <input
                  type="checkbox"
                  className="w-5 h-5 text-white border-light_red focus:ring-black checked:bg-dark_night_sky focus:ring-none cursor-pointer hover:border-dark_night_sky"
                  checked={isSelected}
                  onChange={(e) => {
                    e.stopPropagation();

                    onCheckBoxClick && onCheckBoxClick(props.id);
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                />
              )}
            </div>
          )}
          {hover && (
            <div className="flex items-center justify-center">
              {content_type && <ContentTypeBanner />}
            </div>
          )}
        </div>
      </div>

      {isSelected && (
        <div
          className="absolute w-full h-full left-0 top-0"
          style={{
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: 11,
          }}
        />
      )}

      {onDelete && (
        <div className="absolute right-[10px] top-[2px] z-[100]">
          <FButton
            icon={{ color: "#FFFFFF", name: "delete", size: 20 }}
            iconButton
            onClick={(e) => {
              e.stopPropagation();
              onDelete(props.id);
            }}
          />
        </div>
      )}

      {!onCardClick && permalink && (
        <Link
          to={permalink}
          className={`w-full h-full absolute top-0 left-0 right-0 bottom-0 z-[10]`}
          target="_blank"
          key={props.id}
        />
      )}

      <div className="w-full flex-grow relative overflow-hidden flex items-center justify-center">
        {photo_url && stockImage ? (
          isVideo ? (
            <div className="w-full h-full relative">
              <video
                ref={videoRef}
                src={photo_url}
                autoPlay={!isPaused}
                muted
                className="w-full h-full object-cover"
              />
              <div
                className="absolute inset-0 z-50 flex items-center justify-center border-2 border-white opacity-50 hover:opacity-100 rounded-full cursor-pointer p-4 w-[60px] h-[60px] m-auto"
                onClick={togglePlayPause}
              >
                {isPaused ? (
                  <FIcon
                    icon="play-button"
                    color="white"
                    className="left-1 relative"
                    size={24}
                  />
                ) : (
                  <FIcon icon="pause" color="white" size={24} />
                )}
              </div>
            </div>
          ) : (
            <div
              className={`w-full h-full bg-cover bg-center transition-all duration-500 ease-out ${
                hover ? "scale-105" : "scale-100"
              }`}
              style={{
                backgroundImage: `url(${getResizedImageURL(photo_url, 500, 500)})`,
              }}
            />
          )
        ) : props.permalink_url ? (
          <VideoPlayer
            className="h-full"
            hidePlatformIcon={false}
            post={{ ...props, platform }}
            showPostType
          />
        ) : (
          <div className="w-[60px] h-[60px] m-auto bg-gray-100 flex items-center bg-[rgba(255,255,255,0.2)] justify-center p-10 border-2 border-[rgba(0,0,0,0.07)] rounded-full">
            <FIcon icon="photo" size={30} color="rgba(0,0,0,0.16)" />
          </div>
        )}
      </div>

      <div className="w-full relative mt-auto z-[10]">
        {!imageOnly && !isProfile && profile_image && (
          <Link
            to={`/card/${props.creator?.slug}`}
            className="absolute -top-9 left-4 z-20"
            onClick={(e) => e.stopPropagation()}
          >
            <div
              className="bg-cover bg-center rounded-full border-[2px] border-white h-[48px] w-[48px] bg-white"
              style={{ backgroundImage: `url(${profile_image})` }}
            />
            {platform && (
              <div className="flex absolute bottom-[0px] right-[-10px] items-center p-[6px] rounded-full bg-dark_night_sky">
                <FIcon size={12} icon={`${platform}`} color="white" />
              </div>
            )}
          </Link>
        )}
        <div className="px-4 pt-[16px] pb-[12px] bottom-0 bg-white flex flex-col relative z-[2]">
          <div className="flex flex-row mb-[2px] justify-between items-center w-full">
            {displayName && (
              <p className="text-[18px] text-blue_night_sky capitalize truncate w-[95%]">
                {displayName}
              </p>
            )}
            <div className="flex items-center gap-2">
              {onViewStatsScreenshot && props.stats_screenshot && (
                <FTooltip label="View stats screenshot">
                  <FButton
                    icon={{ color: "#000F45", name: "graph", size: 16 }}
                    iconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      onViewStatsScreenshot(props);
                    }}
                  />
                </FTooltip>
              )}
              {published_at && (
                <FTooltip label={published_at}>
                  <FIcon icon="clock" size={14} color="#000F45" />
                </FTooltip>
              )}
            </div>
          </div>

          {customStats || (
            <div className="flex flex-row justify-between relative items-center w-full">
              <div className="flex flex-row gap-4 items-center">
                <FTooltip label="Impressions">
                  <div className="flex flex-row gap-[5px] items-center cursor-pointer z-[20] relative">
                    <FIcon
                      size={14}
                      icon="eye"
                      color="black"
                      className="-mt-[1px]"
                    />
                    <p className="text-sm truncate text-dark_night_sky whitespace-nowrap">
                      {props.stats?.views_count || props.views_count
                        ? formatLargeNumber(
                            props.stats?.views_count || props.views_count
                          )
                        : "-"}
                    </p>
                  </div>
                </FTooltip>

                <FTooltip label="Interactions">
                  <div className="flex flex-row gap-[5px] items-center cursor-pointer z-[20] relative">
                    <FIcon
                      size={14}
                      icon="heart"
                      color="black"
                      className="-mt-[1px]"
                    />
                    <p className="text-sm truncate text-dark_night_sky whitespace-nowrap">
                      {props.stats?.interactions || props.interactions
                        ? formatLargeNumber(
                            props.stats?.interactions || props.interactions
                          )
                        : "-"}
                    </p>
                  </div>
                </FTooltip>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContentCard;
