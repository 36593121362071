import React from "react";
import { Campaign } from "@types";
import {
  parseISO,
  isAfter,
  format,
  isBefore,
  isWithinInterval,
  addDays,
  differenceInDays,
} from "date-fns";

interface CampaignTimelineProps {
  campaign: Campaign;
  contentReview: any;
}

const CampaignTimeline: React.FC<CampaignTimelineProps> = ({
  campaign,
  contentReview,
}) => {
  const getTimelineData = () => {
    const today = new Date();
    const createdAt = parseISO(campaign.created_at);
    const flightStart = campaign.brief?.go_live_start
      ? parseISO(campaign.brief.go_live_start)
      : null;
    const flightEnd = campaign.brief?.go_live_end
      ? parseISO(campaign.brief.go_live_end)
      : null;
    const extendedEnd = flightEnd ? addDays(flightEnd, 14) : null;

    // Calculate total deliverables and approved deliverables
    const totalDeliverables =
      contentReview?.participants?.reduce(
        (sum: number, participant: any) =>
          sum + (participant.deliverables?.length || 0),
        0
      ) || 0;

    const approvedDeliverables =
      contentReview?.participants?.reduce(
        (sum: number, participant: any) =>
          sum +
          (participant.deliverables?.filter((d: any) => d.content?.approved_at)
            ?.length || 0),
        0
      ) || 0;

    // Determine campaign state
    const isComplete =
      totalDeliverables > 0 && approvedDeliverables === totalDeliverables;
    const isInFlight =
      flightStart &&
      flightEnd &&
      isWithinInterval(today, { start: flightStart, end: extendedEnd });
    const isPreFlight = flightStart && isBefore(today, flightStart);
    const isPostFlight = extendedEnd && isAfter(today, extendedEnd);

    // Calculate progress percentage
    let progress = 0;
    if (flightStart && extendedEnd) {
      const totalDuration = extendedEnd.getTime() - createdAt.getTime();
      const elapsed = today.getTime() - createdAt.getTime();
      progress = Math.min(Math.max((elapsed / totalDuration) * 100, 0), 100);
    }

    // Calculate days until submission
    const daysUntilSubmission = flightEnd
      ? Math.max(differenceInDays(flightEnd, today), 0)
      : 0;

    return {
      createdAt,
      flightStart,
      flightEnd,
      extendedEnd,
      totalDeliverables,
      approvedDeliverables,
      isComplete,
      isInFlight,
      isPreFlight,
      isPostFlight,
      progress,
      daysUntilSubmission,
    };
  };

  const timelineData = getTimelineData();

  const getStatusText = () => {
    if (timelineData.isComplete) return "Complete";
    if (timelineData.isPostFlight) return "Flight Period Ended";
    if (timelineData.isInFlight) {
      return `${timelineData.daysUntilSubmission} Days Until Submission`;
    }
    if (timelineData.isPreFlight) return "Not Started";
    return "Not Scheduled";
  };

  const getProgressBarColor = () => {
    if (timelineData.isComplete) return "bg-green";
    return "bg-blue";
  };

  return (
    <div className="bg-white p-6 shadow-sm rounded-lg border border-neutral_200">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-md font-medium">Status</h3>
        <div
          className={`text-sm capitalize font-medium ${timelineData.isComplete ? "text-green" : "text-blue"}`}
        >
          {getStatusText()}
        </div>
      </div>

      <div className="space-y-4">
        {/* Progress bar */}
        <div className="h-3 w-full bg-neutral_100 rounded-full overflow-hidden">
          <div
            className={`h-full ${getProgressBarColor()} transition-all duration-500`}
            style={{ width: `${timelineData.progress}%` }}
          />
        </div>

        {/* Timeline labels */}
        <div className="flex justify-between items-center mt-2">
          <div className="flex items-center space-x-2">
            <span className="text-xs capitalize text-neutral_600">
              Campaign {campaign.status}
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <span className="text-xs text-neutral_600">
              {timelineData.flightStart &&
                format(timelineData.flightStart, "MMM d")}{" "}
              -{" "}
              {timelineData.flightEnd &&
                format(timelineData.flightEnd, "MMM d")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignTimeline;
