import parser from "parse-address";

export function capitalize(string: string = "") {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function truncate(string: string, charCount: number) {
  if (string.length <= charCount) {
    return string;
  }
  return string.substring(0, charCount).concat("…");
}

export function formatPlatform(platform: string) {
  switch (platform) {
    case "tiktok":
      return "TikTok";
    case "Tiktok":
      return "TikTok";
    case "youtube":
    case "rss":
      return "Blog";
    case "all":
      return "All";
    default:
      return capitalize(platform);
  }
}

export function humanizeString(string: string) {
  return string[0].toUpperCase() + string.replace(/_/g, " ").slice(1);
}

export function getShippingProvider(num) {
  const uspsRegex =
    /(^(94|93|92|94|95)[0-9]{20}$)|(^(94|93|92|94|95)[0-9]{22}$)|(^(70|14|23|03)[0-9]{14}$)|(^(M0|82)[0-9]{8}$)|(^([A-Z]{2})[0-9]{9}([A-Z]{2})$)/g;
  const fedexRegex = /(^[0-9]{20}$)|(^[0-9]{15}$)|(^[0-9]{12}$)|(^[0-9]{22}$)/g;
  const upsRegex =
    /(^(1Z)[0-9A-Z]{16}$)|(^(T)+[0-9A-Z]{10}$)|(^[0-9]{9}$)|(^[0-9]{26}$)/g;
  const lasershipRegex =
    /(^(LW)[0-9]{8}$)|(^(LX)[0-9]{8}$)|(^(1LS)[0-9]{17}$)/g;
  let provider = "";

  const trackingRegex = [
    { regex: uspsRegex, value: "USPS" },
    { regex: fedexRegex, value: "Fedex" },
    { regex: upsRegex, value: "UPS" },
    { regex: lasershipRegex, value: "LaserShip" },
  ];

  for (let i = 0; i < trackingRegex.length; i++) {
    if (num.match(trackingRegex[i].regex)) {
      provider = trackingRegex[i].value;
      break;
    }
  }

  return provider;
}

export function removeHtmlTags(str: string) {
  return str.replace(/(<([^>]+)>)|(\n)|(\u00A0)/gi, "");
}

export function getYoutubeEmbedLink(url: string) {
  if (url) {
    const params = url.split("?");
    if (params.length > 1) {
      const value = new URLSearchParams(params[1]).get("v");
      return `https://www.youtube.com/embed/${value}?showinfo=0`;
    }

    return "";
  }
}

export function statusNames(status) {
  switch (status) {
    case "not_submitted":
      return "Not submitted";
    case "approved":
      return "Approved";
    case "admin_review":
      return "Admin Review";
    case "client_review":
      return "Client Review";
    case "admin_changes":
      return "Rejected";
    case "influencer_changes":
      return "Pending Changes";
    case "soft_approved":
      return "Aproved";
    case "none":
      return "";
    case undefined:
      return "";
    default:
      return capitalize(status);
  }
}

export function formatAddress(address: string) {
  const addressObj = parser.parseLocation(address);
  // if addresses are missing data, the parser does not work
  // return original format if this is the case
  // not having a city/state is an indicator that data is not sufficient for parser
  if (addressObj["city"] && addressObj["state"]) {
    const topKeys = [
      "number",
      "prefix",
      "street",
      "type",
      "sec_unit_type",
      "sec_unit_num",
    ];
    const bottomKeys = ["city", "state", "zip"];

    let topAddressLine = "",
      bottomAddressLine = "";

    topKeys.forEach((k, i) => {
      if (addressObj[k]) {
        if (i !== 0) {
          topAddressLine += " ";
        }
        topAddressLine += addressObj[k];
      }
    });

    bottomKeys.forEach((k, i) => {
      if (addressObj[k]) {
        if (i !== 0) {
          bottomAddressLine += " ";
        }

        bottomAddressLine += addressObj[k];

        if (k === "city" && addressObj["state"]) {
          bottomAddressLine += ",";
        }
      }
    });

    return [topAddressLine, bottomAddressLine];
  } else {
    return [address, ""];
  }
}

export function formatDate(date: string) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const formattedDate = new Date(
    new Date(date).toLocaleString("en-US", { timeZone: "UTC" })
  );
  return `${
    months[formattedDate.getMonth()]
  } ${formattedDate.getDate()}, ${formattedDate.getFullYear()}`;
}
