export const GENDERS = [
  {
    value: "female",
    label: "Female",
  },
  {
    value: "male",
    label: "Male",
  },
  {
    value: "nonbinary",
    label: "Nonbinary",
  },
  {
    value: "transgender",
    label: "Transgender",
  },
  {
    value: "notsay",
    label: "Prefer not to say",
  },
  {
    value: "selfdescribe",
    label: "Prefer to self-describe",
  },
];

export const ETHNICITIES = [
  {
    value: "nativeamerican",
    label: "American Indian or Alaska Native",
  },
  {
    value: "asian",
    label: "Asian",
  },
  {
    value: "black",
    label: "Black or African American",
  },
  {
    value: "hispanic",
    label: "Hispanic or Latino",
  },
  {
    value: "middle_east",
    label: "Middle Eastern",
  },
  {
    value: "pacificislander",
    label: "Native Hawaiian or Other Pacific Islander",
  },
  {
    value: "caucasian",
    label: "White",
  },
  {
    value: "two_or_more",
    label: "Two or more races",
  },
  {
    value: "other",
    label: "I prefer not to say",
  },
];

export const SEXUAL_ORIENTATIONS = [
  {
    value: "heterosexual",
    label: "Heterosexual",
  },
  {
    value: "homosexual",
    label: "Homosexual",
  },
  {
    value: "bisexual",
    label: "Bisexual",
  },
  {
    value: "asexual",
    label: "Asexual",
  },
  {
    value: "other",
    label: "Other",
  },
];

export const MARITAL_STATUSES = [
  {
    value: "single",
  },
  {
    value: "married",
  },
  {
    value: "divorced",
  },
  {
    value: "in-relationship",
    label: "In Relationship",
  },
  {
    value: "other",
  },
];
