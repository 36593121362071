import FButton from "@components/FButton";
import { capitalize } from "lodash";
import moment from "moment";

export interface Comment {
  author_name: string;
  author_email?: string;
  body: string;
  created_at: string;
  subject?: string;
}

export interface CommentProps {
  comment: Comment;
  onDelete?: () => void;
  isDeleting?: boolean;
  onEdit?: () => void;
}

const Comment = ({ comment, onDelete, isDeleting, onEdit }: CommentProps) => {
  const { author_name, author_email, body, created_at, subject } = comment;

  console.log(comment);

  return (
    <div className="mb-6 relative border border-light_border bg-white rounded-lg">
      <div className="flex mb-2  items-center justify-between p-4 pb-0">
        <p className="font-bold text-sm">{author_name || author_email}</p>
        {subject ? (
          <div className="rounded-md bg-black text-white mb-1 px-[8px] py-[2px] w-max text-xs">
            {capitalize(subject)}
          </div>
        ) : null}
      </div>
      <p
        className="mb-1 opacity-80 px-4 py-2 break-words"
        dangerouslySetInnerHTML={{ __html: body }}
      />
      <div className="flex items-center justify-between border-t border-light_border py-2 px-4">
        <p className="text-sm text-default_weak">
          {moment(created_at).format("MMM DD, H:mm A")}
        </p>
        <div className="flex gap-2">
          {onEdit ? (
            <FButton
              className=""
              label="Edit"
              primary={false}
              height={20}
              onClick={onEdit}
            />
          ) : null}
          {onDelete ? (
            <FButton
              className="bg-red"
              label="Delete"
              primary
              height={20}
              loading={isDeleting}
              onClick={onDelete}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Comment;
