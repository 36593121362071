import React, { useState } from "react";
import FIcon from "./FIcon";
import Pagination from "rc-pagination";
import { bool } from "prop-types";

const FPagination = ({
  onPageChange,
  page = 1,
  pageSize = 12,
  total,
}: {
  page?: number | string;
  pageSize?: number;
  onPageChange: (arg: number) => void;
  total: number;
}) => {
  const [current, setCurrent] = useState(page);

  // onChange returns page as a number, but urlParam is a string
  // this keeps the type correct for the Pagination component
  const parsePageNumber = (num: string | number) => {
    if (typeof num === "string") {
      return parseInt(num);
    }

    return num;
  };

  const handlePageChange = (page) => {
    setCurrent(page);
    onPageChange(page);
  };

  return (
    <div className="custom-pagination">
      <Pagination
        current={parsePageNumber(current)}
        jumpPrevIcon={<FIcon icon="ellipsis" color="#282F45" size={13} />}
        jumpNextIcon={<FIcon icon="ellipsis" color="#282F45" size={13} />}
        onChange={handlePageChange}
        pageSize={pageSize}
        pageSizeOptions={[]}
        prevIcon={<FIcon icon="chevron" color="#000F45" size={12} />}
        nextIcon={<FIcon icon="chevron" color="#000F45" size={12} />}
        total={total}
      />
    </div>
  );
};

export default FPagination;
