import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import useAuth from "@hooks/useAuth";
import { Permission } from "@constants/roles";
import FIcon from "@components/FIcon";

interface Props {
  className?: string;
  name?: string;
  underSectionData?: React.ReactNode;
  underSectionTitle?: string;
  selectedValue?: string | number;
  items: {
    label: string;
    value?: any;
    link?: string;
    linkName?: string;
    active?: boolean;
    disabled?: boolean;
    dropdownOptions?: {
      label: string;
      value: string;
      link: string;
      requiredPermission?: Permission;
    }[];
  }[];
  onChange?: (value: string | number) => void;
  style?: any;
}

const FSubNav = ({
  className = "",
  items = [],
  onChange,
  selectedValue,
  ...props
}: Props) => {
  const [showUnder, setShowUnder] = useState(true);
  const [openDropdown, setOpenDropdown] = useState<number | null>(null);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const dropdownRefs = useRef<Array<HTMLDivElement | null>>([]);
  const navigate = useNavigate();
  const { can } = useAuth();

  const updateDropdownPosition = (index: number) => {
    const buttonElement = dropdownRefs.current[index];
    if (buttonElement) {
      const rect = buttonElement.getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + rect.width / 2 + window.scrollX,
      });
    }
  };

  const handleDropdownClick = (index: number) => {
    if (openDropdown === index) {
      setOpenDropdown(null);
    } else {
      setOpenDropdown(index);
      updateDropdownPosition(index);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (openDropdown !== null) {
        const dropdownContainer = dropdownRefs.current[openDropdown];
        if (
          dropdownContainer &&
          !dropdownContainer.contains(event.target as Node)
        ) {
          setOpenDropdown(null);
        }
      }
    };

    const handleScroll = () => {
      if (openDropdown !== null) {
        updateDropdownPosition(openDropdown);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
  }, [openDropdown]);

  const toggleUnderSection = () => {
    setShowUnder(!showUnder);
  };

  const getItemColors = (item) => {
    if (item.disabled) {
      return "text-neutral_400";
    } else if (item.active) {
      return "cursor-pointer";
    } else {
      return "cursor-pointer hover:opacity-80";
    }
  };

  const handleLinkClick = (link: string, value: any) => {
    setOpenDropdown(null);
    if (onChange) {
      onChange(value);
      navigate(link);
    }
  };

  const getFilteredDropdownOptions = (options) => {
    if (!options) return [];
    return options.filter((option) => {
      if (option.requiredPermission) {
        return can(option.requiredPermission);
      }
      return true;
    });
  };

  return (
    <div
      style={{
        width: "100%",
        ...props.style,
      }}
      className={`${className} relative`}
    >
      <div className={`w-full border-b border-light_border`}>
        <div className="flex items-center font-normal text-base overflow-x-auto gap-[40px]">
          {items.map((item, index) => {
            const key = `${item.value}-${index}`;
            const filteredDropdownOptions = getFilteredDropdownOptions(
              item.dropdownOptions
            );
            const hasDropdown = filteredDropdownOptions.length > 0;
            const isActive =
              item.value === selectedValue ||
              (hasDropdown &&
                filteredDropdownOptions.some(
                  (opt) => opt.value === selectedValue
                ));

            return (
              <div key={key} className="relative flex items-center">
                {hasDropdown ? (
                  <div
                    className="flex items-center -mr-[10px]"
                    ref={(el) => (dropdownRefs.current[index] = el)}
                  >
                    <div
                      className={`flex items-center pt-[16px] pb-[14px] gap-[8px] ${
                        isActive
                          ? "opacity-100 border-b-2 border-black"
                          : "opacity-25"
                      } text-center whitespace-nowrap ${getItemColors({ ...item, active: isActive })} hover:opacity-100`}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDropdownClick(index);
                      }}
                      role="button"
                      tabIndex={0}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          e.preventDefault();
                          handleDropdownClick(index);
                        }
                      }}
                    >
                      <div className="cursor-pointer">
                        <span>{item.linkName || item.label}</span>
                      </div>
                      <FIcon
                        size={16}
                        color="black"
                        icon="chevron-dropdown"
                        className={`transform transition-transform duration-200 inline-block -mt-[2px] ${
                          openDropdown === index ? "rotate-180" : ""
                        }`}
                      />
                    </div>
                    {openDropdown === index &&
                      filteredDropdownOptions.length > 0 && (
                        <div
                          className="fixed bg-white shadow-lg rounded-md py-1 z-[999] min-w-[200px]"
                          style={{
                            boxShadow:
                              "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
                            top: `${dropdownPosition.top}px`,
                            left: `${dropdownPosition.left}px`,
                            transform: "translateX(calc(-50% + 56px))",
                          }}
                        >
                          {filteredDropdownOptions.map(
                            (option, optionIndex) => (
                              <Link
                                key={`${option.value}-${optionIndex}`}
                                to={option.link}
                                className={`block px-4 py-2 text-sm bg-white hover:bg-neutral_100 text-gray-700 hover:bg-gray-100 transition-colors duration-150 ${
                                  option.value === selectedValue
                                    ? "bg-gray-100"
                                    : ""
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleLinkClick(option.link, option.value);
                                }}
                              >
                                {option.label}
                              </Link>
                            )
                          )}
                        </div>
                      )}
                  </div>
                ) : item.link ? (
                  <Link
                    to={item.link}
                    className={`pb-[14px] pt-[16px] ${
                      isActive ? "opacity-100" : "opacity-25"
                    } text-center whitespace-nowrap ${getItemColors({ ...item, active: isActive })} border-b-2 ${
                      isActive ? "border-black" : "border-transparent"
                    } hover:opacity-100`}
                    onClick={(e) => {
                      e.preventDefault();
                      handleLinkClick(item.link || "#", item.value);
                    }}
                  >
                    {item.label}
                  </Link>
                ) : (
                  <p
                    className={`pt-[16px] pb-[14px] text-center whitespace-nowrap ${getItemColors(
                      item
                    )} border-b-2 ${
                      isActive ? "border-black" : "border-transparent"
                    }`}
                    onClick={() => {
                      if (item.disabled) return;
                      if (onChange) onChange(item.value);
                    }}
                  >
                    {item.label}
                  </p>
                )}
              </div>
            );
          })}
        </div>
      </div>
      {props.underSectionData && (
        <div className="flex flex-col items-start justify-start py-[16px] border-b border-neutral_400 gap-[10px]">
          <div className="flex flex-row items-center gap-[8px] justify-between text-dark_night_sky text-[16px]">
            <p className="">{props.underSectionTitle}</p>
            <p className="">•</p>
            <p
              className="cursor-pointer underline"
              onClick={toggleUnderSection}
            >
              {showUnder ? "Hide" : "Show"}
            </p>
          </div>
          {showUnder && props.underSectionData}
        </div>
      )}
    </div>
  );
};

export default FSubNav;
