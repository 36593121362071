import { getAccount, toggleMembershipPermission } from "@api/user";
import FDropdown from "@components/FDropdown";
import FSwitch from "@components/FInputs/FSwitch";
import Heading from "@components/Heading";
import LoadingSpinner from "@components/LoadingSpinner";

import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

const AccountPage = () => {
  const { id } = useParams();

  const [account, setAccount] = useState(null);
  const [loading, setLoading] = useState(true);

  const [selectedMembershipId, setSelectedMembershipId] =
    useState<number>(null);

  useEffect(() => {
    const loadAccount = async () => {
      const account = await getAccount(parseInt(id, 10));

      setAccount(account);

      setSelectedMembershipId(account.memberships[0].id);

      setLoading(false);
    };

    loadAccount();
  }, []);

  if (loading) {
    return <LoadingSpinner className="w-full h-[90vh]" />;
  }

  const { email_address, memberships } = account;

  const selectedMembership = memberships.find(
    ({ id }) => id === selectedMembershipId
  );

  const { permissions } = selectedMembership;

  const handleTogglePermission = async (permission) => {
    await toggleMembershipPermission(selectedMembership.id, permission);

    toast.success("Saved");

    const newMembership = {
      ...selectedMembership,
      permissions: {
        ...permissions,
        [permission]: !permissions[permission],
      },
    };

    setAccount({
      ...account,
      memberships: account.memberships.map((m) =>
        m.id === selectedMembership.id ? newMembership : m
      ),
    });
  };

  return (
    <div className="pb-16">
      <Heading className="mb-8" title={email_address} />
      <FDropdown
        label="Memberships"
        options={memberships.map(({ brand_name, id }) => ({
          label: brand_name,
          value: id,
        }))}
        selectedValue={selectedMembership.id}
        onChange={(membershipId) =>
          setSelectedMembershipId(membershipId as number)
        }
      />
      <h2 className="my-8 text-xl">Permissions</h2>
      <div className="flex flex-col gap-4 mt-8">
        {Object.keys(permissions).map((key) => (
          <FSwitch
            className="justify-between w-[500px]"
            label={key}
            isChecked={permissions[key]}
            onChange={() => handleTogglePermission(key)}
          />
        ))}
      </div>
    </div>
  );
};

export default AccountPage;
