import React, { useState } from "react";

import { DragDropContext, Draggable } from "react-beautiful-dnd";

import { CampaignBrief, CampaignBriefAsset } from "@types";

import FIcon from "@components/FIcon";
import {
  deleteCampaignBriefAsset,
  updateCampaignBriefAsset,
} from "@api/CampaignBriefs/CampaignBriefAssets";
import { size } from "lodash";
import ContentPreview from "@components/media/content-highlight/ContentPreview";

import UpdateSectionAssetModal from "./components/UpdateSectionAssetModal";
import FButton from "@components/FButton";
import toast from "react-hot-toast";
import ConfirmModal from "@components/Modals/ConfirmModal";

import Droppable from "@components/drag-and-drop/Droppable";

import { getItemStyle, getListStyle, reorder } from "@components/drag-and-drop";
import {
  deleteParticipantBriefAsset,
  updateParticipantBriefAsset,
} from "@api/ParticipantBriefs/ParticipantBriefAssets";

interface Props {
  brief: CampaignBrief;
  onUpdate: (updates: Partial<CampaignBrief>) => void;
}

export default function BriefMoodboard({ brief, onUpdate }: Props) {
  const { participant_id, sections } = brief;

  const [updatingAsset, setUpdatingAsset] = useState(null);
  const [deletingAssetId, setDeletingAssetId] = useState(null);
  const [assetToDelete, setAssetToDelete] = useState(null);

  const creativeDirection = sections.find(
    ({ kind }) => kind === "creative_direction"
  );

  const { assets = [] } = creativeDirection || {};

  const handleAddNewAsset = () =>
    setUpdatingAsset({
      position: size(assets),
      [`${participant_id ? "participant" : "campaign"}_brief_section_id`]:
        creativeDirection.id,
    });

  const handleAssetCreated = (asset: CampaignBriefAsset) => {
    handleUpdateSection({
      assets: [...assets, asset],
    });

    toast.success("Asset created");

    setUpdatingAsset(null);
  };

  const handleUpdateSection = (updates) => {
    const newSections = sections.map((section) =>
      section.id === creativeDirection.id ? { ...section, ...updates } : section
    );

    onUpdate({ sections: newSections });
  };

  const handleAssetUpdated = (updatedAsset: CampaignBriefAsset) => {
    const newAssets = assets.map((asset) => {
      if (asset.id === updatedAsset.id) {
        return updatedAsset;
      }

      return asset;
    });

    handleUpdateSection({ assets: newAssets });

    toast.success("Asset updated");

    setUpdatingAsset(null);
  };

  const handleDragEnd = async (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newAssets = reorder(
      assets,
      result.source.index,
      result.destination.index
    ).map((item, index) => ({
      ...item,
      position: index,
    }));

    handleUpdateSection({ assets: newAssets });

    for (const asset of newAssets) {
      await (
        participant_id ? updateParticipantBriefAsset : updateCampaignBriefAsset
      )(asset.id, { position: asset.position });
    }

    toast.success("Updated asset order");
  };

  const handleDeleteAsset = async (assetId: number) => {
    setDeletingAssetId(true);

    try {
      await (
        participant_id ? deleteParticipantBriefAsset : deleteCampaignBriefAsset
      )(assetId);

      toast.success("Asset deleted");
    } catch {
      toast.error("Error deleting asset");
    } finally {
      setDeletingAssetId(false);
    }

    const newAssets = assets.filter((asset) => assetId !== asset.id);

    handleUpdateSection({ assets: newAssets });
  };

  return (
    <>
      <div className="">
        <h2 className="text-2xl mb-8">Moodboard</h2>

        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="grid gap-4 auto-rows-fr min-h-[200px]"
                style={{
                  ...getListStyle(snapshot.isDraggingOver),
                  gridTemplateColumns: `repeat(5, minmax(0, 1fr))`,
                }}
              >
                {assets.map((asset, index) => {
                  const { file, id } = asset;

                  const content = { photo_url: file?.original };

                  return (
                    <Draggable
                      key={`key-${id}`}
                      draggableId={`ID-${id}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <div
                            className="cursor-pointer  border border-default_weak hover:border-black rounded-md overflow-hidden relative h-[200px]"
                            onClick={() => setUpdatingAsset(asset)}
                            {...provided.dragHandleProps}
                          >
                            <div className="absolute bg-black/50 right-[0px] top-[0px] z-[100]">
                              <FButton
                                icon={{
                                  color: "#FFFFFF",
                                  name: "delete",
                                  size: 20,
                                }}
                                iconButton
                                loading={deletingAssetId === asset.id}
                                onClick={(e) => {
                                  e.stopPropagation();

                                  setAssetToDelete(asset.id);
                                }}
                              />
                            </div>
                            <ContentPreview content={content} />
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                <button
                  className="flex flex-col items-center justify-center bg-white border border-dashed border-default_weak rounded-md px-6 py-10 hover:border-black"
                  onClick={handleAddNewAsset}
                >
                  <FIcon icon="upload" size={20} color="#000000" />
                  <p className="mt-4 opacity-30">Add new asset</p>
                </button>
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {updatingAsset ? (
          <UpdateSectionAssetModal
            onClose={() => setUpdatingAsset(null)}
            asset={updatingAsset}
            onUpdated={handleAssetUpdated}
            onCreated={(asset) => {
              handleAssetCreated(asset);
              setUpdatingAsset(null);
            }}
            participantId={participant_id}
          />
        ) : null}
        {assetToDelete ? (
          <ConfirmModal
            title="Delete asset"
            isOpen
            onClose={() => setAssetToDelete(null)}
            onAction={() => {
              handleDeleteAsset(assetToDelete);

              setAssetToDelete(null);
            }}
            actionLabel="Delete"
            subtitle="Are you sure you want to delete this asset?"
          />
        ) : null}
      </div>
    </>
  );
}
