import React, { useContext } from "react";
import { NewReportContext } from "@contexts/index";
import { StatsSnapshot } from "./components/StatsSnapshot";
import ReportPaid from "./components/ReportPaid";
import SummaryGraph from "./components/SummaryGraph";
import { Link } from "react-router-dom";
import FIcon from "@components/FIcon";
import { SummaryGraphSkeleton } from "./components/SummaryGraphSkeleton";
import { StatsSnapshotSkeleton } from "./components/StatsSnapshotSkeleton";
import { ReportPaidSkeleton } from "./components/ReportPaidSkeleton";
import BlankState from "@components/BlankState";
import InfluencerTiers from "./components/InfluencerTiers";
import CountriesBreakdown from "./components/charts/demographics/CountriesBreakdown";
import StatesBreakdown from "./components/charts/demographics/StatesBreakdown";
import DemographicSnapshots from "./components/DemographicSnapshots";
import ContentTypeBreakdown from "./components/ContentTypeBreakdown";
import ViralPosts from "./components/ViralPosts";
import { DemographicsSkeletons } from "./components/DemographicsSkeletons";

export default function Overview({ campaignReport = false }) {
  const {
    report,
    metrics,
    isLoading,
    engagementGraphData,
    audience,
    audienceDataFetched,
    availablePlatforms,
    selectedPlatform,
    setSelectedPlatform,
    performanceGraphs,
  } = useContext(NewReportContext) || {};

  const renderDemographics = () => {
    if (!audienceDataFetched || !audience) {
      return <DemographicsSkeletons />;
    }

    // Show skeleton if we have no data yet
    if (!Object.keys(audience).length) {
      return <DemographicsSkeletons />;
    }

    return (
      <div className="mt-[48px]">
        <div>
          <DemographicSnapshots
            audience={audience}
            audienceDataFetched={audienceDataFetched}
            availablePlatforms={availablePlatforms}
            selectedPlatform={selectedPlatform}
            setSelectedPlatform={setSelectedPlatform}
            parentLoading={isLoading}
            content={metrics.posts > 0}
          />
        </div>
        {audience.countries?.length > 0 &&
          audience.states?.length > 0 &&
          metrics.posts > 0 && (
            <>
              <h2 className="text-xl font-medium mb-4 mt-[48px]">
                Geographic Distribution
              </h2>
              <div className="mt-8">
                <div className="grid gap-6 grid-cols-2">
                  <CountriesBreakdown items={audience.countries} />
                  <StatesBreakdown items={audience.states} />
                </div>
              </div>
            </>
          )}
      </div>
    );
  };

  return (
    <div className="space-y-8 pt-10">
      <div className="grid grid-cols-4 gap-4">
        <div className="col-span-1">
          {isLoading ? (
            <>
              <StatsSnapshotSkeleton />
              <ReportPaidSkeleton />
            </>
          ) : (
            <>
              <StatsSnapshot metrics={metrics} isLoading={isLoading} />
              <ReportPaid report_id={report?.id} parentLoading={isLoading} />
              {performanceGraphs?.content_chart_data &&
                performanceGraphs?.posts && (
                  <ContentTypeBreakdown
                    posts={performanceGraphs.posts}
                    chartData={performanceGraphs.content_chart_data}
                    parentLoading={isLoading}
                  />
                )}
              {performanceGraphs?.viral_posts && (
                <ViralPosts
                  viralPosts={performanceGraphs.viral_posts}
                  parentLoading={isLoading}
                />
              )}
            </>
          )}
        </div>

        <div className="col-span-3 space-y-6">
          <div className="flex justify-between items-center mb-8">
            <h2 className="text-xl font-medium">Content Performance</h2>
            <Link
              className="text-sm flex flex-row gap-2 items-center text-red hover:underline"
              to={
                campaignReport
                  ? `/campaigns/${report?.id}/content`
                  : `/reports/${report?.id}/content`
              }
            >
              View Content
              <FIcon icon="next-arrow" color="#E47667" size={12} />
            </Link>
          </div>

          <div className="flex flex-col bg-white border border-light_border rounded-lg bg-light_red shadow-md">
            <div className="p-4 pt-8">
              {isLoading ? (
                <SummaryGraphSkeleton />
              ) : Object.values(engagementGraphData).every(
                  (value) => value === null
                ) ? (
                <BlankState
                  title="No Data Available"
                  subtitle="No engagement data is currently available to display. Please try again later."
                  icon="info"
                  actionLabel="Refresh"
                  onActionClick={() => window.location.reload()}
                />
              ) : (
                <SummaryGraph postsEngagement={engagementGraphData} />
              )}
            </div>
          </div>

          <div className="mb-[48px]">
            <div className="flex justify-between items-center mt-[48px] mb-8">
              <h2 className="text-xl font-medium">Creator Performance</h2>
              <Link
                className="text-sm flex flex-row gap-2 items-center text-red hover:underline"
                to={
                  campaignReport
                    ? `/campaigns/${report?.id}/participants`
                    : `/reports/${report?.id}/creators`
                }
              >
                View Creators
                <FIcon icon="next-arrow" color="#E47667" size={12} />
              </Link>
            </div>
            <InfluencerTiers report_id={report?.id.toString()} />
          </div>

          {/* Demographics section */}
          {renderDemographics()}
        </div>
      </div>
    </div>
  );
}
