import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import FSidebarMenuItem from "./FSidebarMenuItem";
import FSidebarSection from "./FSidebarSection";
import { FSidebarItem, Children } from "../../types/types";
import CollapseButton from "./CollapseButton";
import FohrLogo from "../FohrLogo";
import FohrLogoShort from "../FohrLogoShort";
import { Link } from "react-router-dom";
import UserMenu from "./UserMenu";
import { UserContext } from "@contexts/index";
import HelpScout from "../HelpScout";

interface Props {
  children?: Children;
  className?: string;
  collapsible?: boolean;
  sections?: {
    fixedBottom: any;
    collapsible?: boolean;
    icon?: string;
    title?: string;
    items: FSidebarItem[];
    mainLink?: {
      icon: string;
      name: string;
    };
  }[];
  topOffset?: number;
  style?: any;
  bubbleCollapsed?: (state: boolean) => void;
}

const FSidebar = ({
  className = "",
  children = [],
  sections = [],
  topOffset = 0,
  collapsible = true,
  style = {},
  bubbleCollapsed,
}: Props) => {
  const savedCollapsedState = localStorage.getItem("fohr-sidebar-collapsed");
  const [expandedSection, setExpandedSection] = useState<number | null>(null);
  const location = useLocation();
  const activeLink = location.pathname;
  const { user } = useContext(UserContext);

  const toggleSection = (index: number) => {
    setExpandedSection((prev) => (prev === index ? null : index));
  };

  let initialCollapsedState = true;

  if (window.innerWidth > 640) {
    initialCollapsedState = savedCollapsedState === "true";
  }

  const [collapsed, setCollapsed] = useState<boolean>(
    collapsible ? initialCollapsedState : false
  );

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        ...child.props,
        collapsed: collapsed,
      });
    }
    return child;
  });

  useEffect(() => {
    if (window.innerWidth > 640) {
      localStorage.setItem(
        "fohr-sidebar-collapsed",
        collapsed ? "true" : "false"
      );
      bubbleCollapsed(collapsed);
    }
  }, [collapsed]);

  return (
    <div
      style={style}
      className={`${className} border-r border-light_border flex flex-col min-h-full`}
    >
      <div
        className={`flex flex-col min-h-full ${
          collapsed ? "absolute w-0 sm:w-[66px] bg-none" : "fixed w-[244px]"
        } sm:sticky top-0 sm:self-start`}
        style={{
          height: `${topOffset ? `calc(100vh - ${topOffset}px)` : "100vh"}`,
        }}
      >
        {!collapsed && (
          <div
            className="fixed top-0 left-[244px] w-full h-full block sm:hidden"
            style={{ background: "rgba(0,0,0,0.5)" }}
            onClick={() => setCollapsed(true)}
          ></div>
        )}

        <div className="p-[24px]">
          <Link to="/home">
            {collapsed ? (
              <FohrLogoShort height={18} />
            ) : (
              <FohrLogo height={18} />
            )}
          </Link>
        </div>
        <div className="flex flex-col h-full mt-[20px]">
          <FSidebarSection collapsed={collapsed} topBorder={false}>
            {sections.map((section, i) => {
              const sectionClasses = section.fixedBottom ? "mt-auto" : "";

              return (
                <div key={i} className={`${sectionClasses}`}>
                  {section.collapsible ? (
                    <FSidebarMenuItem
                      href={null}
                      mainLink={section.mainLink}
                      key={i}
                      icon={section.icon}
                      name={section.title}
                      items={section.items}
                      collapsed={collapsed}
                      isExpanded={expandedSection === i}
                      onToggle={() => toggleSection(i)}
                      activeLink={activeLink}
                    />
                  ) : (
                    <>
                      {section.items.map((item) => (
                        <FSidebarMenuItem
                          key={item.name.split(" ").join("-").toLowerCase()}
                          icon={item.icon}
                          name={item.name}
                          href={item.href}
                          target={item.target}
                          active={item.active}
                          upgrade_required_label={item.upgrade_required_label}
                          collapsed={collapsed}
                          isExpanded={expandedSection === i}
                          onToggle={() => toggleSection(i)}
                          activeLink={activeLink}
                        />
                      ))}
                    </>
                  )}
                </div>
              );
            })}
            <div>
              <CollapseButton
                collapsed={collapsed}
                collapsible={true}
                onClick={() => {
                  setCollapsed(!collapsed);
                  bubbleCollapsed(!collapsed);
                }}
              />
            </div>
          </FSidebarSection>

          <HelpScout
            collapsed={collapsed}
            beaconId="32dde665-4ab6-4d6c-b9a1-52a8cdfb8299"
          />

          <UserMenu
            user={user}
            collapsed={collapsed}
            menu={{
              title: `${user.first_name} ${user.last_name}`,
              subtitle: `${user.email}`,
              items: [
                {
                  name: "Home",
                  icon: { name: "home", color: "black" },
                  link: "/home",
                },
              ],
            }}
          />

          {childrenWithProps}
        </div>
      </div>
    </div>
  );
};

export default FSidebar;
