import FBaseInput from "@components/FInputs/FBaseInput";
import FormField, { FormFieldProps } from "./FormField";

interface FRangeInputProps extends FormFieldProps {
  minValue?: number;
  maxValue?: number;
  prefix?: string;
  value: { min: number; max: number };
  onChange: (value: { min?: number; max?: number }) => void;
}

const FRangeInput = ({
  minValue,
  maxValue,
  value,
  onChange,
  prefix,
  ...rest
}: FRangeInputProps) => {
  const { min, max } = value || {};

  const handleMinChange = (value: string) => {
    const valueAsNumber = parseInt(value, 10) || 0;

    handleChange({
      min: valueAsNumber,
      max,
    });
  };

  const handleMaxChange = (value: string) => {
    const valueAsNumber = parseInt(value, 10);

    handleChange({
      max: valueAsNumber,
      min,
    });
  };

  const handleChange = (inputs: { min: number; max: number }) => {
    const updates = {};

    Object.keys(inputs).forEach((key) => {
      if (inputs[key] !== null) {
        updates[key] = inputs[key];
      }
    });

    onChange(updates);
  };

  const handleValidateInputs = () => {
    handleChange({ min, max: Math.max(min, max) || null });
  };

  return (
    <FormField {...rest}>
      <div className="flex gap-2 items-center">
        <FBaseInput
          min={minValue}
          max={maxValue}
          placeholder="Min"
          value={min ? `${min}` : ""}
          onChange={handleMinChange}
          onBlur={handleValidateInputs}
          type="number"
          prefix={prefix}
        />
        <div className="w-[10px] h-[1px] bg-neutral_500" />
        <FBaseInput
          min={minValue}
          max={maxValue}
          placeholder="Max"
          value={max ? `${max}` : ""}
          onChange={handleMaxChange}
          onBlur={handleValidateInputs}
          type="number"
          prefix={prefix}
        />
      </div>
    </FormField>
  );
};

export default FRangeInput;
