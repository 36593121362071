import React, { useState, useContext, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { CampaignContext, ContentReviewContext } from "@contexts/index";

import { addFeedback, updateContentStatus } from "@api/Campaign/ContentReview";
import {
  CampaignContentReview,
  ContentReviewContent,
  ContentReviewRound,
} from "types/content-review";
import { capitalize, groupBy, last } from "lodash";

import toast from "react-hot-toast";

import FProcessNav from "@components/FProcessNav";
import FButtonSelect from "@components/FButtonSelect";
import FilterDropdowns from "@components/FilterDropdowns";
import ParticipantDeliverables from "./components/ParticipantDeliverables";
import STATUSES, { CONTENT_REVIEW_STATUS } from "./statuses";
import ContentReviewList from "./components/ContentReviewList";
import { Campaign } from "@types";

import ContentReviewModal from "./components/ContentReviewModal";
import DeliverablesOverview from "./components/DeliverablesOverview";

const VIEW_OPTIONS = ["participant", "deliverable"];

const getContentByStatus = (allContent: ContentReviewContent[]) =>
  groupBy(allContent, "status");

const getUpdatedContentReview = (
  contentReview: CampaignContentReview,
  contentId: number,
  updates: { status: string }
) => {
  const { participants } = contentReview;

  return {
    ...contentReview,
    participants: participants.map((participant) => {
      const { deliverables } = participant;

      return {
        ...participant,
        deliverables: deliverables.map((deliverable) => {
          const { content } = deliverable;

          const updatedContent =
            content?.id === contentId
              ? {
                  ...content,
                  ...updates,
                }
              : content;

          return {
            ...deliverable,
            content: updatedContent,
          };
        }),
      };
    }),
  };
};

const getAllContent = (
  contentReview: CampaignContentReview,
  campaign: Campaign
): ContentReviewContent[] => {
  if (!contentReview) {
    return [];
  }

  const { participants } = contentReview;

  let result = [] as ContentReviewContent[];

  const { participants: campaignParticipants } = campaign;

  participants.forEach((participant) => {
    const { deliverables, fohr_campaigns_participant_id } = participant;

    const campaignParticipant = campaignParticipants.find(
      ({ id }) => id === fohr_campaigns_participant_id
    );

    deliverables.forEach((deliverable) => {
      const { content } = deliverable;

      if (content) {
        result = [
          ...result,
          { ...content, deliverable, participant, campaignParticipant },
        ];
      }
    });
  });

  return result;
};

const ContentReview = () => {
  const { campaign } = useContext(CampaignContext);
  const { contentReview, setContentReview } = useContext(ContentReviewContext);

  const [searchParams, setSearchParams] = useSearchParams();

  console.log(contentReview, "contentReview");

  // Get status from URL or default to "not_submitted"
  const selectedStatus = searchParams.get("status") || "not_submitted";
  const selectedView = searchParams.get("view") || VIEW_OPTIONS[0];

  const [selectedFilters, setSelectedFilters] = useState({
    stage: "",
    deliverable: "",
    participant: "",
    mediaType: "",
  });

  const [activePost, setActivePost] = useState(null);
  const [rejecting, setRejecting] = useState(false);

  // Update URL when status changes
  const handleStatusChange = (value: CONTENT_REVIEW_STATUS) => {
    setSearchParams((prev) => {
      prev.set("status", value);
      return prev;
    });
  };

  // Update URL when view changes
  const handleViewChange = (value: string) => {
    setSearchParams((prev) => {
      prev.set("view", value);
      return prev;
    });
  };

  const content = useMemo(
    () => getAllContent(contentReview, campaign),
    [contentReview, campaign]
  );

  const contentByStatus = useMemo(() => getContentByStatus(content), [content]);

  const visibleContent = contentByStatus[selectedStatus] || [];

  const handleUpdateContentStatus = async (
    contentId: number,
    status: CONTENT_REVIEW_STATUS
  ) => {
    const selectedItems = [contentId];

    Promise.all(
      selectedItems.map(async (contentId) => {
        try {
          await updateContentStatus(contentId, status);

          return contentId;
        } catch (e) {
          toast.error(`Error updating content with ID ${contentId}`);

          return null;
        }
      })
    ).then((updatedIds) => {
      setContentReview(
        getUpdatedContentReview(contentReview, updatedIds[0], { status })
      );

      setActivePost(null);

      toast.success(
        `Content moved to ${STATUSES.find(({ value }) => value === status).label}`
      );
    });
  };

  const handleReject = async ({ mediaFeedback, captionFeedback }) => {
    const { id, rounds } = activePost;

    setRejecting(true);

    const status = "admin_changes";

    const params = {
      content_id: id,
      round_id: (last(rounds) as ContentReviewRound).id,
    };

    try {
      await addFeedback({
        ...params,
        subject: "media",
        body: mediaFeedback,
      });

      await addFeedback({
        ...params,
        subject: "description",
        body: captionFeedback,
      });

      await updateContentStatus(id, status);

      setContentReview(getUpdatedContentReview(contentReview, id, { status }));

      setActivePost(null);

      toast.success("Feedback sent to influencer");
    } catch (e) {
      toast.error("Error adding feedback");

      setRejecting(false);
    }
  };

  return (
    <>
      <div className="flex justify-between items-center py-[40px] border-light_border">
        <div className="flex flex-row justify-between items-center gap-4 w-full">
          <h2 className="text-2xl">
            Content Review ({content ? content.length : ""})
          </h2>
          {selectedStatus !== "not_submitted" && (
            <FilterDropdowns
              content={visibleContent}
              campaign={campaign}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              disabled={visibleContent.length === 0}
            />
          )}
        </div>
        {selectedStatus === "not_submitted" ? (
          <div className="flex items-center gap-4 w-full justify-end">
            <span className="text-sm">View By:</span>
            <FButtonSelect
              options={VIEW_OPTIONS.map((view) => ({
                label: capitalize(view),
                value: view,
              }))}
              selectedValue={selectedView}
              onChange={handleViewChange}
            />
          </div>
        ) : null}
      </div>
      <FProcessNav
        className="mb-[40px]"
        items={STATUSES.map((item) => ({
          ...item,
          label: `${item.label} (${contentByStatus[item.value]?.length || 0})`,
        }))}
        selectedValue={selectedStatus}
        onChange={handleStatusChange}
      />

      {selectedStatus === "not_submitted" ? (
        selectedView === "participant" ? (
          <ParticipantDeliverables
            campaign={campaign}
            contentReview={contentReview}
            showNotSubmitted
          />
        ) : (
          <DeliverablesOverview
            campaign={campaign}
            contentReview={contentReview}
          />
        )
      ) : (
        <ContentReviewList
          campaign={campaign}
          content={visibleContent}
          status={selectedStatus}
          onSelectedContent={setActivePost}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
        />
      )}
      {activePost && (
        <ContentReviewModal
          post={activePost}
          onClose={() => setActivePost(null)}
          onUpdateStatus={(value) =>
            handleUpdateContentStatus(activePost.id, value)
          }
          onReject={handleReject}
          rejecting={rejecting}
        />
      )}
    </>
  );
};

export default ContentReview;
