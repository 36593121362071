import * as User from "./user";
import * as UserNotifications from "./Notifications/UserNotifications";
import * as UserNotificationSettings from "./Notifications/UserNotificationSettings";

import * as Groups from "./Groups/Groups";
import * as GroupCreators from "./Groups/GroupCreators";

import * as Brands from "./brands";

import * as Bulletins from "./Bulletins/Bulletins";

import * as Creators from "./Creators/Creators";

import * as Outreach from "./Outreach/Outreach";
import * as OutreachTemplates from "./Outreach/OutreachTemplates";

import * as Profile from "./Profile";
import * as Reports from "./Reports";

import * as Testimonial from "./Testimonial";

import * as Ambassadors from "./Ambassadors/Ambassadors";
import * as AmbassadorQuestions from "./Ambassadors/AmbassadorQuestions";
import * as AmbassadorResponses from "./Ambassadors/AmbassadorResponses";
import * as AmbassadorContent from "./Ambassadors/AmbassadorContent";

import * as ContractTemplates from "./ContractTemplates/ContractTemplates";
import * as ContractTemplateSection from "./ContractTemplates/ContractTemplateSections";

import * as Campaigns from "./Campaign/Campaigns";
import * as CampaignDeliverables from "./Campaign/CampaignDeliverables";
import * as CampaignParticipantGroups from "./Campaign/CampaignParticipantGroups";
import * as CampaignParticipants from "./Campaign/CampaignParticipants";
import * as CampaignReports from "./Campaign/CampaignReports";
import * as CampaignProducts from "./Campaign/CampaignProducts";
import * as CampaignLinkAssets from "./CampaignLinkAssets";
import * as CampaignAccountingSummaries from "./Campaign/CampaignAccountingSummaries";
import * as CampaignAccounting from "./Campaign/CampaignAccounting";
import * as ParticipantContent from "./Campaign/CampaignParticipantContent";

import * as CampaignBriefs from "./CampaignBriefs/CampaignBriefs";
import * as CampaignBriefAssets from "./CampaignBriefs/CampaignBriefAssets";
import * as CampaignBriefSections from "./CampaignBriefs/CampaignBriefSections";

import * as CampaignParticipantBriefs from "./CampaignParticipantBriefs/CampaignParticipantBriefs";
import * as CampaignParticipantBriefSections from "./CampaignParticipantBriefs/CampaignParticipantBriefSections";

import * as AdminBrands from "./Admin/AdminBrands";

import * as Assets from "./Assets";
import * as Memberships from "./Memberships"

import { Attachment, Sort } from "@types";

const apiClient = {
  participant_briefs: {
    index: async function (params: any) {
      return await CampaignParticipantBriefs.getParticipantBriefs(params);
    },
    get: async function (participant_brief_id: string | number) {
      return await CampaignParticipantBriefs.getParticipantBrief(
        participant_brief_id
      );
    },
    // create: async function (params: any) {
    // 	return await CampaignParticipantBriefs.createParticipantBrief(params);
    // },
    update: async function (
      participant_brief_id: string | number,
      params: any
    ) {
      return await CampaignParticipantBriefs.updateParticipantBrief(
        participant_brief_id,
        params
      );
    },
  },

  memberships: {
    create: async function (params: { brand_id: number, user_id: number, account_id?: number }) {
      return await Memberships.create(params);
    },
    delete: async function (params: { id: number}) {
      return await Memberships.destroy(params);
    }
  },

  bulletins: {
    index: async function (params?: any) {
      return await Bulletins.getBulletinList(params);
    },
    create: async function (params: any) {
      return await Bulletins.createBulletin(params);
    },
    get: Bulletins.getBulletin,
    update: async function (bulletin_id: string | number, params: any) {
      return await Bulletins.updateBulletin(bulletin_id, params);
    },
    delete: async function (bulletin_id: string | number) {
      return await Bulletins.deleteBulletin(bulletin_id);
    },
  },

  participant_brief_sections: {
    index: async function (params: any) {
      return await CampaignParticipantBriefSections.getParticipantBriefIndex(
        params
      );
    },
    get: async function (section_id: string | number) {
      return await CampaignParticipantBriefSections.getParticipantBriefSection(
        section_id
      );
    },
    create: async function (params: any) {
      return await CampaignParticipantBriefSections.createParticipantBriefSection(
        params
      );
    },
    update: async function (
      participant_brief_section_id: string | number,
      params: any
    ) {
      return await CampaignParticipantBriefSections.updateParticipantBriefSection(
        participant_brief_section_id,
        params
      );
    },
    delete: async function (participant_brief_section_id: string | number) {
      return await CampaignParticipantBriefSections.deleteParticipantBriefSection(
        participant_brief_section_id
      );
    },
  },

  brands: {
    index: async function (params?: {
      id_eq?: number;
      name_eq?: string;
      name_i_cont?: string;
      slug_eq?: string;
      slug_i_cont?: string;
      instagram_handle_eq?: string;
    }) {
      return await Brands.listBrands(params);
    },
    create: async function (params: Partial<any>) {
      return await Brands.createBrand(params);
    },
    update: async function (brand_id: string | number, params: Partial<any>) {
      return await Brands.updateBrand(brand_id, params);
    },
    delete: async function (brand_id: string | number) {
      return await Brands.deleteBrand(brand_id);
    },
    get: async function (brand_id: string | number) {
      return await Brands.showBrand(brand_id);
    },
  },

  campaign_accounting_summaries: {
    index: async function (params: any) {
      return await CampaignAccountingSummaries.listCampaignAccountingSummaries(
        params
      );
    },
    download: async function () {
      return await CampaignAccountingSummaries.downloadCampaignAccountingSummary();
    },
  },
  campaign_accounting: {
    index: async function (params?: any) {
      return await CampaignAccounting.listCampaignAccountings(params);
    },
    get: async function (accounting_id: string | number) {
      return await CampaignAccounting.getCampaignAccounting(accounting_id);
    },
    update: async function (accounting_id: string | number, params: any) {
      return await CampaignAccounting.updateCampaignAccounting(
        accounting_id,
        params
      );
    },
  },
  assets: {
    index: async function (params?: any) {
      return await Assets.listAssets(params);
    },
    create: async function (params: any) {
      return await Assets.createAsset(params);
    },
    update: async function (asset_id: string | number, params: any) {
      return await Assets.updateAsset(asset_id, params);
    },
    delete: async function (asset_id: string | number) {
      return await Assets.deleteAsset(asset_id);
    },
    get: async function (asset_id: string | number) {
      return await Assets.showAsset(asset_id);
    },
  },
  ambassadors: {
    index: async function (params?: any) {
      return await Ambassadors.listAmbassadors(params);
    },
    create: async function (params: any) {
      return await Ambassadors.createAmbassador(params);
    },
    update: async function (ambassador_id: string | number, params: any) {
      return await Ambassadors.updateAmbassador(ambassador_id, params);
    },
    delete: async function (ambassador_id: string | number) {
      return await Ambassadors.deleteAmbassador(ambassador_id);
    },
    get: async function (ambassador_id: string | number) {
      return await Ambassadors.showAmbassador(ambassador_id);
    },
  },
  ambassador_questions: {
    index: async function (params?: any) {
      return await AmbassadorQuestions.listAmbassadorQuestions(params);
    },
    create: async function (params: any) {
      return await AmbassadorQuestions.createAmbassadorQuestion(params);
    },
    update: async function (question_id: string | number, params: any) {
      return await AmbassadorQuestions.updateAmbassadorQuestion(
        question_id,
        params
      );
    },
    delete: async function (question_id: string | number) {
      return await AmbassadorQuestions.deleteAmbassadorQuestion(question_id);
    },
    get: async function (question_id: string | number) {
      return await AmbassadorQuestions.showAmbassadorQuestion(question_id);
    },
  },
  ambassador_responses: {
    index: async function (params?: any) {
      return await AmbassadorResponses.getAmbassadorResponses(params);
    },
  },
  ambassador_content: {
    index: async function (params?: any) {
      return await AmbassadorContent.getAmbassadorContent(params);
    },
  },
  outreach: {
    index: async function (page: number, sort: Sort, params?: any) {
      return await Outreach.getOutreachList(page, sort, params);
    },
    get: async function (outreach_id: string | number) {
      return await Outreach.getOutreach(outreach_id);
    },
    create: async function (params: any) {
      return await Outreach.createOutreach(params);
    },
    update: async function (
      outreach_id: string | number,
      params: any,
      assets?: Attachment[],
      assetsToRemove?: Attachment[]
    ) {
      return await Outreach.updateOutreach(
        outreach_id,
        params,
        assets,
        assetsToRemove
      );
    },
    delete: async function (outreach_id: string | number) {
      return await Outreach.deleteOutreach(outreach_id);
    },
  },
  outreach_templates: {
    index: async function (page: number, params?: any) {
      return await OutreachTemplates.getOutreachTemplateList(page, params);
    },
    get: async function (outreach_template_id: string | number) {
      return await OutreachTemplates.getOutreachTemplate(outreach_template_id);
    },
    create: async function (params: any) {
      return await OutreachTemplates.createOutreachTemplate(params);
    },
    update: async function (
      outreach_template_id: string | number,
      params: any
    ) {
      return await OutreachTemplates.updateOutreachTemplate(
        outreach_template_id,
        params
      );
    },
    delete: async function (outreach_template_id: string | number) {
      return await OutreachTemplates.deleteOutreachTemplate(
        outreach_template_id
      );
    },
  },
  groups: {
    index: async function (params?: any) {
      return await Groups.getGroupList(params);
    },
    create: async function (params: any) {
      return await Groups.createGroup(params);
    },
    get: async function (group_id: string | number) {
      return await Groups.getGroup(group_id);
    },
    update: async function (group_id: string | number, params: any) {
      return await Groups.updateGroup(group_id, params);
    },
    delete: async function (group_id: string | number) {
      return await Groups.deleteGroup(group_id);
    },
    remove_creator: async function (
      group_id: string | number,
      creator_ids: (string | number)[]
    ) {
      return await Groups.removeCreator(group_id, creator_ids);
    },
    add_creator: async function (
      group_id: string | number,
      creator_ids: (string | number)[]
    ) {
      return await Groups.addCreator(group_id, creator_ids);
    },
    download_csv: async function (group_id: string | number) {
      return await Groups.downloadGroupCSV(group_id);
    },
    duplicate: async function (group_id: string | number) {
      return await Groups.duplicateGroup(group_id);
    },
    combine: async function (name: string, creatorIds: number[]) {
      return await Groups.combineGroup(name, creatorIds);
    },
  },
  group_creators: {
    index: async function (params?: any) {
      return await GroupCreators.getGroupCreatorList(params);
    },
    get: async function (group_creator_id: string | number) {
      return await GroupCreators.getGroupCreator(group_creator_id);
    },
    create: async function (params: any) {
      return await GroupCreators.createGroupCreator(params);
    },
    update: async function (group_creator_id: string | number, params: any) {
      return await GroupCreators.updateGroupCreator(group_creator_id, params);
    },
    delete: async function (group_creator_id: string | number) {
      return await GroupCreators.deleteGroupCreator(group_creator_id);
    },
  },
  creators: {
    index: async function (params?: any) {
      return await Creators.getCreatorList(params);
    },
    get: async function (creator_id: string | number) {
      return await Creators.getCreator(creator_id);
    },
    create: async function (params: any) {
      return await Creators.createCreator(params);
    },
    update: async function (creator_id: string | number, params: any) {
      return await Creators.updateCreator(creator_id, params);
    },
    delete: async function (creator_id: string | number) {
      return await Creators.deleteCreator(creator_id);
    },
  },
  campaigns: {
    index: async function (page: number, params?: any) {
      return await Campaigns.getCampaignList(page, params);
    },
    get: async function (campaign_id: string | number) {
      return await Campaigns.getCampaign(campaign_id);
    },
    create: async function (params: any) {
      return await Campaigns.createCampaign(params);
    },
    update: async function (campaign_id: string | number, params: any) {
      return await Campaigns.updateCampaign(campaign_id, params);
    },
    delete: async function (campaign_id: string | number) {
      return await Campaigns.deleteCampaign(campaign_id);
    },
    duplicate: async function (campaign_id: string | number) {
      return await Campaigns.duplicateCampaign(campaign_id);
    },
  },
  campaign_participant_content: {
    create: async function (
      campaign_id: number | string,
      participant_id: number | string,
      body: any
    ) {
      return await ParticipantContent.createParticipantContent(
        campaign_id,
        participant_id,
        body
      );
    },
    update: async function (content_id: number | string, body: any) {
      return await ParticipantContent.updateParticipantContent(
        content_id,
        body
      );
    },
    get: async function (campaign_id: number | string, params?: any) {
      return await ParticipantContent.getCampaignParticipantContent(
        campaign_id,
        params
      );
    },
  },
  campaign_products: {
    get: async function (campaign_id: string | number) {
      return await CampaignProducts.getCampaignProductList(campaign_id);
    },
    create: async function (campaign_id: string | number, params: any) {
      return await CampaignProducts.createCampaignProduct(campaign_id, params);
    },
    update: async function (campaign_id: string | number, params: any) {
      return await CampaignProducts.updateCampaignProduct(campaign_id, params);
    },
    delete: async function (campaign_id: string | number) {
      return await CampaignProducts.deleteCampaignProduct(campaign_id);
    },
  },
  campaign_participant_group: {
    get: async function (campaign_id: string | number) {
      return await CampaignParticipantGroups.getParticipantGroups(campaign_id);
    },
    create: async function (campaign_id: string | number, params: any) {
      return await CampaignParticipantGroups.createParticipantGroup(
        campaign_id,
        params
      );
    },
    update: async function (
      participant_group_id: string | number,
      params: any
    ) {
      return await CampaignParticipantGroups.updateParticipantGroup(
        participant_group_id,
        params
      );
    },
  },
  campaign_brief: {
    get: async function (campaign_id: string | number) {
      return await CampaignBriefs.getCampaignBrief(campaign_id);
    },
    create: async function (campaign_id: string | number, params: any) {
      return await CampaignBriefs.createCampaignBrief(campaign_id, params);
    },
    update: async function (brief_id: number | string, params: any) {
      return await CampaignBriefs.updateCampaignBrief(brief_id, params);
    },
    upload: async function (id, formData) {
      return await CampaignBriefs.uploadCampaignBriefFiles(id, formData);
    },
  },
  campaign_brief_asset: {
    create: async function (params: any) {
      return await CampaignBriefAssets.createCampaignBriefAsset({
        campaign_brief_asset: params,
      });
    },
    delete: async function (id: number) {
      return await CampaignBriefAssets.deleteCampaignBriefAsset(id);
    },
    update: async function (id: number, params: any) {
      return await CampaignBriefAssets.updateCampaignBriefAsset(id, {
        campaign_brief_asset: params,
      });
    },
    upload_file: async function (id, formData) {
      return await CampaignBriefAssets.uploadCampaignBriefAssetFiles(
        id,
        formData
      );
    },
  },
  campaign_brief_sections: {
    create: async function (params: any) {
      return await CampaignBriefSections.createCampaignBriefSection(params);
    },
    delete: async function (campaignBriefSectionId: number | string) {
      return await CampaignBriefSections.deleteCampaignBriefSection(
        campaignBriefSectionId
      );
    },
    get: async function (campaign_brief_id: number | string, params?: any) {
      return await CampaignBriefSections.getCampaignBriefSections(
        campaign_brief_id,
        params
      );
    },
    update: async function (section_id: number | string, params: any) {
      return await CampaignBriefSections.updateCampaignBriefSection(
        section_id,
        params
      );
    },
  },
  campaign_deliverable: {
    create: async function (campaign_id: string | number, params: any) {
      return await CampaignDeliverables.createCampaignDeliverable(
        campaign_id,
        params
      );
    },
    delete: async function (deliverable_id: number) {
      return await CampaignDeliverables.deleteCampaignDeliverable(
        deliverable_id
      );
    },
    get: async function (campaign_id: string | number, params?: any) {
      return await CampaignDeliverables.getCampaignDeliverables(
        campaign_id,
        params
      );
    },
    update: async function (deliverable_id: number, params: any) {
      return await CampaignDeliverables.updateCampaignDeliverable(
        deliverable_id,
        params
      );
    },
  },
  campaign_link: {
    create: async function (campaignId, params) {
      return await CampaignLinkAssets.createCampaignLink(campaignId, params);
    },
    delete: async function (campaignId, linkId) {
      return await CampaignLinkAssets.deleteCampaignLink(campaignId, linkId);
    },
    update: async function (campaignId, linkId, params) {
      return await CampaignLinkAssets.updateCampaignLink(
        campaignId,
        linkId,
        params
      );
    },
  },
  campaign_participant: {
    archive: async function (participant_id: string | number) {
      return await CampaignParticipants.archiveCampaignParticipant(
        participant_id
      );
    },
    create: async function (campaign_id: string | number, params: any) {
      return await CampaignParticipants.createCampaignParticipant(
        campaign_id,
        params
      );
    },
    get: async function (participant_id: string | number) {
      return await CampaignParticipants.getCampaignParticipant(participant_id);
    },
    index: async function (campaign_id: string | number) {
      return await CampaignParticipants.getCampaignParticipantList(campaign_id);
    },
    update: async function (participant_id: string | number, params: any) {
      return await CampaignParticipants.updateCampaignParticipant(
        participant_id,
        params
      );
    },
  },
  campaign_reports: {
    get_summary: async function (campaign_id: number) {
      return await CampaignReports.getCampaignReportSummary(campaign_id);
    },
    get_performance_graphs: async function (campaign_id: number) {
      return await CampaignReports.getCampaignReportPerformanceGraphs(
        campaign_id
      );
    },
    get_posts_engagement_graph: async function (
      campaign_id: number,
      platform: string,
      dateRange: string,
      include_paid_media: boolean
    ) {
      return await CampaignReports.getCampaignPostsEngagementGraph(
        campaign_id,
        platform,
        dateRange,
        include_paid_media
      );
    },
  },
  contract_template_sections: {
    create: async function (
      contract_template_id: number | string,
      params: any
    ) {
      return await ContractTemplateSection.createContractTemplateSection(
        contract_template_id,
        params
      );
    },
    delete: async function (contract_template_id: number | string) {
      return await ContractTemplateSection.deleteContractTemplateSection(
        contract_template_id
      );
    },
    get: async function (contract_template_id: number | string) {
      return await ContractTemplateSection.getContractTemplateSection(
        contract_template_id
      );
    },
    update: async function (
      contract_template_id: number | string,
      contract_template_section_id: number | string,
      params: any
    ) {
      return await ContractTemplateSection.updateContractTemplateSection(
        contract_template_id,
        contract_template_section_id,
        params
      );
    },
  },
  contract_templates: {
    create: async function (params: any) {
      return await ContractTemplates.createContractTemplate(params);
    },
    delete: async function (contract_template_id: number | string) {
      return await ContractTemplates.deleteContractTemplate(
        contract_template_id
      );
    },
    get: async function (contract_template_id: number | string) {
      return ContractTemplates.getContractTemplate(contract_template_id);
    },
    index: async function () {
      return await ContractTemplates.getContractTemplateIndex();
    },
    update: async function (
      contract_template_id: number | string,
      params: any
    ) {
      return await ContractTemplates.updateContractTemplate(
        contract_template_id,
        params
      );
    },
  },
  profiles: {
    audience_data: async function (slug: string, platform: string) {
      return await Profile.getAudienceData(slug, platform);
    },
    overview_data: async function (slug: string) {
      return await Profile.getOverviewData(slug);
    },
    all_content: async function (slug: string) {
      return await Profile.getAllContent(slug);
    },
    top_content: async function (slug: string) {
      return await Profile.getTopContent(slug);
    },
    recent_sponsored_content: async function (slug: string) {
      return await Profile.getRecentSponsoredContent(slug);
    },
    stats: async function (slug: string, platform: string, timePeriod: string) {
      return await Profile.getStats(slug, platform, timePeriod);
    },
  },

  reports: {
    index: async function (params?: any) {
      return await Reports.getReportsList(params);
    },
    // create: async function (params: any) {
    //   return await Reports.createReport(params);
    // },
    // get: Reports.getReport,
    // update: async function (report_id: string | number, params: any) {
    //   return await Reports.updateReport(report_id, params);
    // },
    // delete: async function (report_id: string | number) {
    //   return await Report.deleteReport(report_id);
    // },
  },

  testimonial: {
    get: async function (id: number | string) {
      return await Testimonial.getTestimonial(id as string);
    },
    index: async function (cardId: number) {
      return await Testimonial.getTestimonialsList(cardId);
    },
  },
  user: {
    create: async function (values: any) {
      return await User.create(values);
    },
    signUp: async function (values: any) {
      return await User.signUp(values);
    },
    signIn: async function (
      email: string,
      password: string,
      captchaToken: string
    ) {
      return await User.signIn(email, password, captchaToken);
    },
  },
  user_notifications: {
    index: async function (params) {
      return await UserNotifications.getUserNotificationList(params);
    },
    get: async function (notification_id) {
      return await UserNotifications.getUserNotification(notification_id);
    },
    create: async function (params) {
      return await UserNotifications.createUserNotification(params);
    },
    update: async function (notification_id, params) {
      return await UserNotifications.updateUserNotification(
        notification_id,
        params
      );
    },
  },
  user_notification_settings: {
    bulk_create: async function (params) {
      return await UserNotificationSettings.bulkCreateUserNotificationSetting(
        params
      );
    },
    create: async function (params) {
      return await UserNotificationSettings.createUserNotificationSetting(
        params
      );
    },
    index: async function (params) {
      return await UserNotificationSettings.getUserNotificationSettings(params);
    },
    update: async function (notification_setting_id, params) {
      return await UserNotificationSettings.updateUserNotificationSettings(
        notification_setting_id,
        params
      );
    },
    delete: async function (notification_setting_id) {
      return await UserNotificationSettings.deleteUserNotificationSettings(
        notification_setting_id
      );
    },
  },
  admin_brands: {
    list: async function (params) {
      return await AdminBrands.getAdminBrandList(params);
    },
  },
};

export default apiClient;
