import React, { useState, useEffect, useContext } from "react";
import InstagramConnectButton from "@components/InstagramConnectionButton";
import TiktokConnectButton from "@components/TiktokConnectButton";
import YoutubeConnectButton from "@components/YoutubeConnectButton";
import { useLocation, useNavigate } from "react-router-dom";

import { UserContext } from "@contexts/index";
import Modal from "@components/Modals/Modal";
import FButton from "@components/FButton";
import FInput from "@components/FInputs/FInput";
import {
  connectToPlatform,
  getCreator,
  removePlatformConnection,
} from "@api/Creators/Creators";
import toast from "react-hot-toast";
import { capitalize } from "lodash";
import { SocialMedialPlatform } from "@types";

const INSTAGRAM = "instagram";
const TIKTOK = "tiktok";
const YOUTUBE = "youtube";

const showSuccessToast = (platform: string, isConnected = false) =>
  toast.success(
    `${capitalize(platform)} ${isConnected ? "" : "dis"}connected successfully`
  );

const showErrorToast = (platform: string, isConnecting = false) =>
  toast.success(
    `Error ${isConnecting ? "connecting to " : "disconnecting from "} ${capitalize(platform)} `
  );

// Reusable component for handling modals
const SocialMediaModal = ({
  isOpen,
  title,
  username,
  setUsername,
  handleSubmit,
  onClose,
}) => {
  if (!isOpen) return null;

  const isTikTok = title.includes("TikTok");
  // Use the platform name based on the title; if not TikTok, try to parse it from the title.
  const platformName = isTikTok ? "TikTok" : title.split(" ")[2];

  return (
    <Modal
      hasForm
      isOpen={isOpen}
      title={title}
      onClose={onClose}
      actionLabel="Add"
      className="w-[650px] overflow-auto"
    >
      <div className="modal-content">
        {isTikTok ? (
          <>
            <p className="mb-[8px]">Add your TikTok username.</p>
            <p className="mb-[8px]">
              By providing your username, we can track your posts and access
              select stats.
            </p>
          </>
        ) : (
          <>
            <p className="mb-[8px]">
              It looks like we couldn’t connect to your {platformName}. But
              don’t worry!
            </p>
            <p className="mb-[8px]">
              By providing your username, we can track your posts and access
              select stats.
            </p>
          </>
        )}
        <FInput
          type="text"
          label={`${platformName} Username`}
          value={username}
          onChange={(value) => setUsername(value)}
          width="100%"
          className="mb-[8px]"
        />
        <FButton
          primary={true}
          onClick={handleSubmit}
          label="Submit"
          type="button"
        />
      </div>
    </Modal>
  );
};

interface SocialMediaConnectionsProps {
  hasCompactView?: boolean;
  hideContinueButton?: boolean;
  platforms?: SocialMedialPlatform[];
  connections: {
    instagram?: any;
    tiktok?: any;
    youtube?: any;
  };
  onUpdateConnections: (updates: any) => void;
}

export default function SocialMediaConnections({
  hasCompactView,
  hideContinueButton,
  platforms,
  connections,
  onUpdateConnections,
}: SocialMediaConnectionsProps) {
  const location = useLocation();
  const { user } = useContext(UserContext);

  const [loading, setLoading] = useState(true);
  const [instagramStatus, setInstagramStatus] = useState(null);
  const [tiktokStatus, setTiktokStatus] = useState(null);
  const [youtubeStatus, setYoutubeStatus] = useState(null);
  const [isInstagramModalOpen, setIsInstagramModalOpen] = useState(false);
  const [isTiktokModalOpen, setIsTiktokModalOpen] = useState(false);
  const [isYoutubeModalOpen, setIsYoutubeModalOpen] = useState(false);
  const [instagramUsername, setInstagramUsername] = useState("");
  const [tiktokUsername, setTiktokUsername] = useState("");

  const navigate = useNavigate();

  // Fetch creator data
  useEffect(() => {
    const fetchCreatorData = async () => {
      try {
        const response = await getCreator(user.creator_id);

        const creator = response.data.socials;

         onUpdateConnections({
          instagram: creator.instagram || null,
          tiktok: creator.tiktok || null,
          youtube: creator.youtube || null,
        })
      } catch (error) {
        console.error("Failed to fetch creator data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchCreatorData();
  }, [user.creator_id]);

  // Check URL params and set status
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setInstagramStatus(params.get("instagram_status"));
    setTiktokStatus(params.get("tiktok_status"));
  }, [location]);

  // Auto open modals based on status
  useEffect(() => {
    setIsInstagramModalOpen(instagramStatus && instagramStatus !== "success");
    setIsTiktokModalOpen(tiktokStatus && tiktokStatus !== "success");
  }, [instagramStatus, tiktokStatus]);

  const handleConnectToInstagram = async () => {
    try {
      await connectToPlatform(INSTAGRAM, instagramUsername);

      showSuccessToast(INSTAGRAM, true);

      onUpdateConnections({ instagram: { username: instagramUsername } });

      setInstagramStatus("success");
      setIsInstagramModalOpen(false);
    } catch (e) {
      showErrorToast(INSTAGRAM, true);
    }
  };

  const handleDisconnectFromInstagram = async () => {
    try {
      await removePlatformConnection(INSTAGRAM);

      onUpdateConnections({ instagram: null });

      setInstagramStatus(null);
      setIsInstagramModalOpen(false);

      showSuccessToast(INSTAGRAM);
    } catch (e) {
      showErrorToast(INSTAGRAM);
    }
  };

  const handleConnectToTikTok = async () => {
    try {
      await connectToPlatform(TIKTOK, tiktokUsername);

      showSuccessToast(TIKTOK, true);

      onUpdateConnections({ tiktok: { username: tiktokUsername } });

      setTiktokStatus("success");
      setIsTiktokModalOpen(false);
    } catch (e) {
      showErrorToast(TIKTOK, true);
    }
  };

  const handleDisconnectFromTikTok = async () => {
    try {
      await removePlatformConnection(TIKTOK);

      showSuccessToast(TIKTOK);

      onUpdateConnections({ tiktok: null });

      setTiktokStatus(null);
      setIsTiktokModalOpen(false);
    } catch (e) {
      showErrorToast(TIKTOK);
    }
  };

  const handleDisconnectFromYoutube = async () => {
    try {
      await removePlatformConnection(YOUTUBE);

      showSuccessToast(YOUTUBE);

      onUpdateConnections({ youtube: null });

      setYoutubeStatus(null);
      setIsYoutubeModalOpen(false);
    } catch (e) {
      showErrorToast(YOUTUBE);
    }
  };

  return (
    <div className="w-full">
      {instagramStatus === "success" && <p>Instagram Connection Successful!</p>}
      {tiktokStatus === "success" && <p>TikTok Connection Successful!</p>}

      <div className="w-full">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <div
            className={`flex ${hasCompactView ? "" : "flex-col"} gap-2 w-full mb-8`}
          >
            {!platforms ||
            platforms.includes(SocialMedialPlatform.INSTAGRAM) ? (
              <InstagramConnectButton
                isConnected={connections.instagram}
                iconOnly={hasCompactView}
                onClick={handleDisconnectFromInstagram}
              />
            ) : null}

            {!platforms || platforms.includes(SocialMedialPlatform.TIKTOK) ? (
              <TiktokConnectButton
                isConnected={connections.tiktok}
                iconOnly={hasCompactView}
                onClick={handleDisconnectFromTikTok}
              />
            ) : null}
            {!platforms || platforms.includes(SocialMedialPlatform.YOUTUBE) ? (
              <YoutubeConnectButton
                isConnected={false}
                iconOnly={hasCompactView}
                onClick={handleDisconnectFromYoutube}
              />
            ) : null}
          </div>
        )}
        {!hideContinueButton ? (
          <FButton
            height="48px"
            width="100%"
            primary
            label="Continue"
            onClick={() => navigate("/signup/influencer/add-personal-info")}
          />
        ) : null}
      </div>

      {/* Instagram Modal */}
      <SocialMediaModal
        isOpen={isInstagramModalOpen}
        title="Enter Your Instagram Username"
        username={instagramUsername}
        setUsername={setInstagramUsername}
        handleSubmit={handleConnectToInstagram}
        onClose={() => setIsInstagramModalOpen(false)}
      />

      {/* TikTok Modal */}
      <SocialMediaModal
        isOpen={isTiktokModalOpen}
        title="Enter Your TikTok Username"
        username={tiktokUsername}
        setUsername={setTiktokUsername}
        handleSubmit={handleConnectToTikTok}
        onClose={() => setIsTiktokModalOpen(false)}
      />
    </div>
  );
}
