import React, { useContext, useState } from "react";

import FIcon from "@components/FIcon";
import FDatePicker from "@components/FDatePicker/FDatePicker";
import { CampaignBrief, User } from "@types";
import FileUpload from "@components/FileUpload";

import moment from "moment";

import FRangePicker from "@components/FDatePicker/FRangePicker";
import { CampaignContext } from "@contexts/index";
import {
  updateParticipantBrief,
  uploadParticipantBriefFiles,
} from "@api/CampaignParticipantBriefs/CampaignParticipantBriefs";
import {
  updateCampaignBrief,
  uploadCampaignBriefFiles,
} from "@api/CampaignBriefs/CampaignBriefs";
import RichTextEditor from "@components/RichTextEditor";
import FormLabel from "@components/Form/FormLabel";
import FButton from "@components/FButton";
import CreateOrUpdateCampaignModal from "./components/CreateOrUpdateCampaignModal";

const MISSING_IMAGE_URL =
  "https://s3.amazonaws.com/assets.fohrcard.com/uploads/x4OGW62/missing_default.png";

interface BriefSettingsProps {
  brief: CampaignBrief;
  onUpdate: (brief: Partial<CampaignBrief>) => void;
  onBriefUpdated: (brief: CampaignBrief) => void;
}

export default function BriefSettings({
  brief,
  onUpdate,
  onBriefUpdated,
}: BriefSettingsProps) {
  const { campaign } = useContext(CampaignContext);

  const { owner_user: manager } = campaign;

  const { sections } = brief;

  const timelineSection = sections.find(({ kind }) => kind === "timeline");

  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const handleUpdateSection = (sectionId, updates) => {
    const newSections = sections.map((section) =>
      section.id === sectionId ? { ...section, ...updates } : section
    );

    onUpdate({ sections: newSections });
  };

  const handleUpdateBrief = (updates) =>
    onBriefUpdated({ ...brief, ...updates });

  const uploadFunction = brief?.participant_id
    ? uploadParticipantBriefFiles
    : uploadCampaignBriefFiles;

  const handleRemoveImage = async (propertyName: string) => {
    const updateFunction = brief?.participant_id
      ? updateParticipantBrief
      : updateCampaignBrief;

    await updateFunction(brief.id, {
      campaign_brief: {
        [propertyName]: null,
      },
    });

    handleUpdateBrief({
      [propertyName]: { large: MISSING_IMAGE_URL, original: MISSING_IMAGE_URL },
    });
  };

  const uploadName = brief?.participant_id
    ? "participant_brief"
    : "campaign_brief";

  const handleCampaignEdited = () => {
    setIsEditModalVisible(false);
    window.location.reload();
  };

  return (
    <>
      <div className="mb-8 pt-0">
        <div className="mb-8">
          <h3 className="text-xl">Timelines</h3>
        </div>
        <div className="flex flex-col gap-4">
          <FRangePicker
            minDate={moment().format()}
            value={{
              from: brief.go_live_start,
              to: brief.go_live_end,
            }}
            onChange={({ from, to }) =>
              onUpdate({
                go_live_start: from,
                go_live_end: to,
              })
            }
          />

          <div className="w-[calc(50%-8px)]">
            <FDatePicker
              label="Application Deadline"
              disabled={{
                after: null,
                before: moment(brief.submit_application).toDate(),
              }}
              value={brief.submit_application}
              onSelectedValue={(value) =>
                onUpdate({
                  submit_application: value,
                })
              }
            />
          </div>
        </div>
        <div className="mt-8">
          <p className="mb-4 text-sm">Full Timeline Breakdown</p>
          <div className="w-full bg-white">
            <RichTextEditor
              id="timeline"
              value={timelineSection?.body || ""}
              onChange={(value) =>
                handleUpdateSection(timelineSection.id, { body: value })
              }
            />
          </div>
        </div>
      </div>
      <div className="pt-6">
        <div className="mb-8">
          <p className="text-xl mb-2">People</p>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex-row flex gap-4">
            <div className="w-full">
              <div className="flex flex-row items-center justify-start  mb-[7px] text-[15px]">
                <p>Campaign Manager</p>
              </div>
              <div className="flex flex-row gap-2 items-center">
                <div className="flex w-[calc(50%-6px)] flex-row gap-2 bg-white p-4 h-[40px] rounded-md border border-default_weak items-center">
                  <FIcon
                    icon="user-bold"
                    size={14}
                    color="black"
                    className="mr-1 relative -top-[1px]"
                  />
                  <p className="font-light text-sm">{manager?.full_name}</p>
                  <p className="font-light text-default_weak text-sm">|</p>
                  <p className="font-light text-sm">
                    <a href={`mailto:${manager?.email}`} className="underline">
                      {manager?.email}
                    </a>
                  </p>
                </div>
                <FButton
                  primary
                  label="Edit"
                  onClick={() => setIsEditModalVisible(true)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-14">
        <div className="mb-8">
          <p className="text-xl mb-2">Assets</p>
        </div>
        <div className="flex flex-row gap-8">
          <div className="flex flex-col">
            <div className="mb-4">
              <h3 className="text">Logo</h3>
            </div>
            <div className="relative w-fit border border-weak_border rounded-md">
              <FileUpload
                type="image"
                url={brief.logo_image?.original || brief.logo_image?.large}
                id="logo_image"
                name="logo_image"
                apiFunction={uploadFunction}
                onUpdate={(logo_image, response) => {
                  const updates = {
                    logo_image: (response as CampaignBrief).logo_image,
                  };

                  handleUpdateBrief(updates);
                }}
                onDelete={() => handleRemoveImage("logo_image")}
                resourceName={uploadName}
                resourceId={brief.id}
                includeHover={true}
              />
            </div>
          </div>
          <div className="flex flex-col">
            <div className="mb-4">
              <h3 className="text">Cover Image</h3>
            </div>
            <div className="relative w-fit border border-weak_border rounded-md">
              <FileUpload
                type="image"
                url={brief.cover_image.original || brief.cover_image.large}
                id="cover_image"
                name="cover_image"
                apiFunction={uploadFunction}
                onUpdate={(cover_image, response) => {
                  const updates = {
                    cover_image: (response as CampaignBrief).cover_image,
                  };

                  handleUpdateBrief(updates);
                }}
                onDelete={() => handleRemoveImage("cover_image")}
                resourceName={uploadName}
                resourceId={brief.id}
                includeHover={true}
              />
            </div>
          </div>
        </div>
        {/* <div className="pt-8 pb-10 border-t border-light_border">
          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-2">Mood Image</h3>
          </div>
          <FileUpload
            type="image"
            url={brief.mood_image?.original}
            id="mood_image"
            name="mood_image"
            apiFunction={uploadFunction}
            onUpdate={(mood_image, response) => {
              const updates = {
                mood_image: (response as CampaignBrief).mood_image,
              };

              handleUpdateBrief(updates);
            }}
            onDelete={() => handleRemoveImage("mood_image")}
            resourceName={uploadName}
            resourceId={brief.id}
            includeHover={true}
          />
        </div> */}
      </div>

      {isEditModalVisible && (
        <CreateOrUpdateCampaignModal
          entity={campaign}
          onClose={() => setIsEditModalVisible(false)}
          onUpdated={handleCampaignEdited}
        />
      )}
    </>
  );
}
