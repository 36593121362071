import React from "react";

export const ReportPaidSkeleton = () => {
  const MetricCardSkeleton = () => (
    <div className="border border-light_border rounded-lg bg-light_red shadow-md animate-pulse">
      <div className="p-4 flex justify-between items-center border-b border-light_border">
        <div className="h-6 w-24 bg-neutral_200 rounded"></div>
        <div className="h-6 w-16 bg-neutral_200 rounded"></div>
      </div>
      <div className="space-y-4 p-4">
        <div className="flex justify-between">
          <div className="flex flex-col items-center">
            <div className="h-4 w-20 bg-neutral_200 rounded mb-2"></div>
            <div className="h-4 w-16 bg-neutral_200 rounded"></div>
          </div>
          <div className="flex flex-col items-center">
            <div className="h-4 w-20 bg-neutral_200 rounded mb-2"></div>
            <div className="h-4 w-16 bg-neutral_200 rounded"></div>
          </div>
        </div>
        <div className="h-2 bg-neutral_200 rounded"></div>
      </div>
    </div>
  );

  return (
    <div className="pr-8 mt-8">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-medium">Organic vs Sponsored</h2>
      </div>
      <div className="space-y-4">
        {[1, 2, 3].map((index) => (
          <MetricCardSkeleton key={index} />
        ))}
      </div>
    </div>
  );
};
