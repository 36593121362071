import React, { useContext } from "react";

import { CampaignContext } from "@contexts/index";
import BlankState from "@components/BlankState";

import CarouselView from "./components/CarouselView";

import ParticipantsByGroup from "../components/ParticipantsByGroup";
import ParticipantReviewStats from "./stats";
import { Participant } from "@types";
import CreatorReviewModal from "./creator-review-modal";
import { useNavigate, useParams } from "react-router-dom";

const ParticipantsOverview = ({
  currentState,
  selectedGroup,
  selectedItems,
  onChangeSelectedItems,
  viewType,
  transitionDirection,
}) => {
  const { participant_id } = useParams();

  const { campaign, setCampaign } = useContext(CampaignContext);

  const navigate = useNavigate();

  const { participants = [], participant_groups = [] } = campaign || {};

  const handleUpdateParticipant = (updates: Partial<Participant>) => {
    const newParticipants = participants.map((participant) => {
      if (participant.id === parseInt(participant_id, 10)) {
        return {
          ...participant,
          ...updates,
        };
      }

      return participant;
    });

    setCampaign({ ...campaign, participants: newParticipants });
  };

  const handleGoToParticipantProfile = (participantId?: number) =>
    navigate(
      `/campaigns/${campaign.id}/participant-review/${participantId || ""}`
    );

  const filteredCardData = participants.filter(
    (participant) =>
      (selectedGroup === null ||
        (participant.participant_group_id === null &&
          selectedGroup === "Participant Group") ||
        participant.participant_group_id?.toString() === selectedGroup) &&
      (currentState === null || participant.status === currentState)
  );

  if (participant_id) {
    return (
      <CreatorReviewModal
        onClose={() => handleGoToParticipantProfile()}
        stateFilter={currentState}
        onUpdate={handleUpdateParticipant}
      />
    );
  }

  if (participants.length === 0) {
    return (
      <div className="pt-[80px]">
        <BlankState
          title={`No Participants found`}
          subtitle="Participants have to be in Pending, Approved or Rejected state to appear in Participant Review"
          icon="user"
        />
      </div>
    );
  }

  const group =
    selectedGroup &&
    participant_groups.find((group) => group.id == selectedGroup);

  return (
    <div className="flex flex-col w-full pt-[80px]">
      <div className="pt-[40px]">
        {viewType === "carousel" ? (
          <CarouselView
            campaign={campaign}
            selectedGroupId={group?.id}
            transitionDirection={transitionDirection}
            currentState={currentState}
            cardData={filteredCardData}
            onChangeSelectedItems={onChangeSelectedItems}
            selectedItems={selectedItems}
            onSelectedCreator={({ id }) => {
              handleGoToParticipantProfile(id);
            }}
            onUpdateCampaign={(updates) =>
              setCampaign({ ...campaign, ...updates })
            }
          />
        ) : (
          <div className="grid grid-cols-[245px_auto] w-full">
            <div className="flex flex-col w-[245px] bg-transparent" />
            <div className="flex flex-col w-full p-[48px]">
              {viewType === "stats" ? (
                <ParticipantReviewStats selectedGroup={selectedGroup} />
              ) : (
                <ParticipantsByGroup
                  campaign={campaign}
                  status={currentState}
                  onUpdateCampaign={(updates) =>
                    setCampaign({ ...campaign, ...updates })
                  }
                  selectedItems={selectedItems}
                  selectedGroup={selectedGroup}
                  onChangeSelectedItems={onChangeSelectedItems}
                  isParticipantReview
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ParticipantsOverview;
