import React, { useContext, useRef, useState } from "react";

import { useLocation, useParams } from "react-router-dom";
import { isEqual, omit, reduce } from "lodash";

import { CampaignContext, ProfileContext } from "@contexts/index";

import SaveOverrideModal from "./SaveOverrideModal";
import FButton from "@components/FButton";
import toast from "react-hot-toast";
import apiClient from "@apiClient";
import BriefPageTemplate from "@components/Campaign/Brief/BriefPageTemplate";
import BlankState from "@components/BlankState";
import { CampaignBrief } from "@types";
import BriefSettings from "../BriefSettings";

const {
  campaign_brief_sections,
  campaign_brief,
  participant_brief_sections,
  participant_briefs,
} = apiClient;

const updateBrief = async (
  participant_id: string,
  activeBrief: CampaignBrief,
  participantBriefs: CampaignBrief[],
  briefs: CampaignBrief[]
) => {
  const originalBrief = (
    activeBrief.participant_id ? participantBriefs : briefs
  ).find((brief) => brief.id === activeBrief.id);

  const { sections: originalSections, ...originalRest } = originalBrief;
  const { sections: updatedSections, ...rest } = activeBrief;

  const diff = reduce(
    originalBrief,
    function (result, value, key) {
      return isEqual(value, activeBrief[key]) ? result : result.concat(key);
    },
    []
  );

  console.log("Brief diff", diff, originalRest, rest);

  if (!isEqual(originalRest, rest)) {
    const updateFunction = participant_id
      ? participant_briefs.update
      : campaign_brief.update;

    await updateFunction(activeBrief.id, rest);
  }

  const changedSections = updatedSections.filter((section) => {
    const originalSection = originalSections.find(
      ({ id }) => id === section.id
    );

    return !isEqual(section, originalSection);
  });

  const successfullyUpdatedSections = [];

  for (const section of changedSections) {
    const updateFunction = participant_id
      ? participant_brief_sections.update
      : campaign_brief_sections.update;

    const response = await updateFunction(section.id, section);

    successfullyUpdatedSections.push(response.data);
  }

  const newUpdatedSections = originalSections.reduce((result, section) => {
    const updatedSection = successfullyUpdatedSections.find(
      ({ id }) => section.id === id
    );

    const isDeleted = !updatedSections.find(({ id }) => id === section.id);

    if (isDeleted) {
      return result;
    }

    if (updatedSection) {
      return [...result, updatedSection];
    }

    return [...result, section];
  }, []);

  const newSections = successfullyUpdatedSections.filter(
    (section) => !originalSections.find(({ id }) => id === section.id)
  );

  return {
    ...activeBrief,
    ...rest,
    sections: [...newSections, ...newUpdatedSections],
  };
};

const FullBrief = ({ sectionRefs, subNavItems, pageName }) => {
  const { collapsed } = useContext(ProfileContext);
  const {
    activeBrief,
    briefs,
    participantBriefs,
    setActiveBrief,
    setBriefs,
    setParticipantBriefs,
  } = useContext(CampaignContext);

  const containerRef = useRef(null);

  const [isOverrideModalOpen, setIsOverrideModalOpen] = useState(false);

  const [saving, setSaving] = useState(false);
  const [applying, setApplying] = useState(false);

  const location = useLocation();

  const { participant_id } = useParams();

  const handleUpdateBrief = (updates) => {
    setActiveBrief({ ...activeBrief, ...updates });
  };

  const handleUpdateBriefs = (updatedBrief) => {
    const isParticipant = !!participant_id;

    const getUpdatedBriefs = (briefs) =>
      briefs.map((brief) =>
        brief.id === activeBrief.id ? updatedBrief : brief
      );

    if (isParticipant) {
      setParticipantBriefs(getUpdatedBriefs(participantBriefs));
    } else {
      setBriefs(getUpdatedBriefs(briefs));
    }
  };

  const handleSaveAndApply = async (
    selectedBriefs: number[],
    allBriefs: CampaignBrief[]
  ) => {
    if (!activeBrief || !activeBrief.sections) {
      toast.error("No active brief or sections found");
      return;
    }

    try {
      setApplying(true);

      const updatedCampaignBriefs = [];
      const updatedParticipantBriefs = [];

      for (const briefId of selectedBriefs) {
        const briefToUpdate = allBriefs.find((b) => b.id === briefId);

        const isParticipantBrief = !!briefToUpdate.participant_id;

        const sectionApi = isParticipantBrief
          ? participant_brief_sections
          : campaign_brief_sections;

        // Update brief itself
        const briefUpdates = omit(activeBrief, [
          "id",
          "participant_group_id",
          "sections",
        ]);

        const updateFunction = isParticipantBrief
          ? participant_briefs.update
          : campaign_brief.update;

        await updateFunction(briefToUpdate.id, briefUpdates);

        // Delete existing sections
        for (const section of briefToUpdate.sections || []) {
          try {
            await sectionApi.delete(section.id);
          } catch (error) {
            toast.error(`Failed to delete section ${section.id}`);
          }
        }

        // Create new sections based on activeBrief
        const newSections = [];
        for (const section of activeBrief.sections) {
          const updates = omit(section, [
            "id",
            "updated_at",
            "created_at",
            "participant_brief_id",
            "campaign_brief_id",
          ]);

          try {
            const newSection = await sectionApi.create({
              [isParticipantBrief
                ? "participant_brief_id"
                : "campaign_brief_id"]: briefToUpdate.id,
              ...updates,
            });
            newSections.push(newSection.data);
          } catch (error) {
            toast.error(`Failed to create new section`);
          }
        }

        // Update the brief with new sections
        const updatedBrief = {
          ...briefToUpdate,
          ...briefUpdates,
          sections: newSections,
        };

        // Update state based on brief type
        if (isParticipantBrief) {
          updatedParticipantBriefs.push(updatedBrief);
        } else {
          updatedCampaignBriefs.push(updatedBrief);
        }
      }

      const newParticipantBriefs = participantBriefs.map((brief) => {
        const updatedBrief = updatedParticipantBriefs.find(
          ({ id }) => brief.id === id
        );

        return updatedBrief || brief;
      });

      const newCampaignBriefs = briefs.map((brief) => {
        const updatedBrief = updatedCampaignBriefs.find(
          ({ id }) => brief.id === id
        );

        return updatedBrief || brief;
      });

      setParticipantBriefs(newParticipantBriefs);

      setBriefs(newCampaignBriefs);

      setIsOverrideModalOpen(false);

      toast.success("Briefs updated successfully");
    } catch (error) {
      console.error("Error updating briefs:", error);
      toast.error("An error occurred while updating briefs");
    } finally {
      setApplying(false);
    }
  };

  const handleSubmit = async () => {
    setSaving(true);

    try {
      const newBrief = await updateBrief(
        participant_id,
        activeBrief,
        participantBriefs,
        briefs
      );

      console.log("New brief", newBrief);

      handleUpdateBriefs(newBrief);

      toast.success("Saved");
    } catch (error) {
      toast.error("Error saving sections: " + error.message);
    } finally {
      setSaving(false);
    }
  };

  const getClassNames = (collapsed) => {
    if (collapsed === null) {
      return "sticky bottom-0 z-10 py-8 pl-0 bg-light_red_opacity backdrop-blur";
    }
    return collapsed
      ? "fixed w-[calc(100vw-525px)] right-[0px] bottom-0 z-10 py-8 pl-10 pr-[40px] bg-light_red_opacity z-50 backdrop-blur"
      : "fixed w-[calc(100vw-694px)] right-[0px] bottom-0 z-10 py-8 pl-10 pr-[40px] bg-light_red_opacity z-50 backdrop-blur";
  };

  if (activeBrief.loading) {
    return (
      <BlankState
        title="Brief loading"
        subtitle={`The brief for the ${participant_id ? "participant" : "participant group you just created"} is being loaded in the background. Please wait a few minutes and come back.`}
        icon={"warning-2"}
      />
    );
  }

  const isSettings = location.pathname.includes("settings");

  return (
    <>
      <div className="">
        {isSettings ? (
          <BriefSettings
            brief={activeBrief}
            key={activeBrief.id}
            onUpdate={handleUpdateBrief}
            onBriefUpdated={handleUpdateBriefs}
          />
        ) : (
          subNavItems.map((item, index) => {
            const {
              component: Component,
              pageTitleCMSName,
              customSectionName,
              hasOwnWrapper,
              value,
              label,
              description,
            } = item;

            const ComponentToRender = hasOwnWrapper
              ? Component
              : BriefPageTemplate;

            return (
              <div
                key={value}
                id={value}
                ref={sectionRefs[value]}
                className={`${index === subNavItems.length - 1 ? "" : "pb-14"}`}
              >
                <ComponentToRender
                  brief={activeBrief}
                  key={activeBrief.id}
                  onUpdate={handleUpdateBrief}
                  pageTitle={label}
                  pageDescription={description}
                  customSectionName={customSectionName}
                  pageTitleCMSName={pageTitleCMSName}
                  containerRef={containerRef}
                >
                  {Component && !hasOwnWrapper ? (
                    <Component
                      containerRef={containerRef}
                      key={activeBrief.id}
                      pageName={pageName}
                      brief={activeBrief}
                      onUpdate={(updates) => handleUpdateBrief(updates)}
                    />
                  ) : null}
                </ComponentToRender>
              </div>
            );
          })
        )}
      </div>
      <div className={getClassNames(collapsed)}>
        <div className="flex justify-center items-center text-center max-w-7xl mx-auto">
          <div className="flex flex-row gap-4 items-center">
            <button
              className="text-[15px] px-4 rounded-md h-[40px] bg-white border flex items-center justify-center"
              type="button"
              onClick={(e) => {
                e.stopPropagation();

                setIsOverrideModalOpen(true);
              }}
            >
              <p className="top-[1px] relative text-[15px]">
                Save &amp; Apply to Other Briefs
              </p>
            </button>
            <FButton
              label={`Save ${pageName}`}
              loading={saving}
              onClick={handleSubmit}
              primary={true}
              className="bg-primary text-white"
            />
          </div>
        </div>
      </div>
      {isOverrideModalOpen ? (
        <SaveOverrideModal
          pageName={pageName}
          onSaveAndApply={handleSaveAndApply}
          onClose={() => setIsOverrideModalOpen(false)}
          waiting={applying}
        />
      ) : null}
    </>
  );
};

export default FullBrief;
