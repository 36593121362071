import { ApiResponse } from "@apiTypes";
import apiWrapper from "@apiWrapper";
import { getQueryParamsForSearchRequest } from "@constants/api";
import { AmbassadorResponse } from "types/ambassador";

const getAmbassadorResponses = async (params?: {
  creator_id_eq?: number;
}): Promise<ApiResponse<any>> => {
  const queryParams = getQueryParamsForSearchRequest(1, null, params);

  const response = await apiWrapper(
    `/ambassador_responses?${queryParams.toString()}`,
    "GET"
  );
  return response;
};

export const getAmbassadorCreatorResponses = async (
  brand_id_eq?: number
): Promise<AmbassadorResponse[]> => {
  const queryParams = new URLSearchParams();

  if (brand_id_eq) {
    queryParams.append("q[brand_id_eq]", `${brand_id_eq}`);
  }

  const response = await apiWrapper(
    `/creator_view/ambassador_responses?${queryParams.toString()}`,
    "GET"
  );
  return response.data.ambassador_responses;
};

export const createAmbassadorResponse = async (params?: {
  ambassador_question_id?: number;
  answer: string | string[];
  data: string[];
  brand_slug: string;
}): Promise<ApiResponse<any>> => {
  const response = await apiWrapper(`/ambassador_responses`, "POST", params);
  return response;
};

export { getAmbassadorResponses };
