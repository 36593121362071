import * as React from "react";

export default function AuthLayout({ children }) {
  // TODO: Revisit this
  const isFullScreen = window.location.href.includes("add-personal-info");

  const LeadingVideo = () => (
    <video autoPlay muted className="h-full object-cover w-[417px]">
      <source src="/videos/cropped2.mp4" type="video/mp4" />
    </video>
  );

  return (
    <div className="sm:flex sm:h-screen w-full text-dark_night_sky font-sofia_pro">
      <div className="h-[500px] sm:hidden">
        <LeadingVideo />
      </div>
      <div className="flex flex-col flex-1 items-center h-full overflow-y-auto">
        <div
          className={
            isFullScreen
              ? "py-[100px]"
              : "flex flex-col justify-center items-center h-full"
          }
        >
          <div className="flex h-[100px] sm:mb-[70px] -mt-[70px] w-full left-0 justify-center items-center">
            <a href="https://www.fohr.co/">
              <img
                src="/images/fohr-logo-navy.png"
                className="w-[80px] mx-auto"
              />
            </a>
          </div>
          <div className="w-full sm:w-[440px] p-2 sm:p-0">{children}</div>
          <div className="text-center mt-8">
            <div className="mb-2">
              <p className="text-xs opacity-50">
                Need additional help? Please reach out to{" "}
                <a className="underline" href="mailto:support@fohr.co">
                  support@fohr.co
                </a>
                .
              </p>
            </div>
            <div className="text-xs mb-2 opacity-50 max-w-[400px] mx-auto">
              By continuing, you acknowledge that you understand and agree to
              the{" "}
              <a
                href="https://www.fohr.co/terms"
                target="_blank"
                className="underline"
              >
                Terms of Service
              </a>{" "}
              and{" "}
              <a
                href="https://www.fohr.co/privacy"
                target="_blank"
                className=" underline"
              >
                Privacy Policy
              </a>
              .
            </div>
          </div>
        </div>
      </div>
      <div className="hidden sm:block sm:h-full">
        <LeadingVideo />
      </div>
    </div>
  );
}
