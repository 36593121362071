import FIcon from "@components/FIcon";
import { CampaignBrief, Campaign } from "@types";
import moment from "moment";

const DATE_FORMAT = "MMM DD";

const getTimelineItems = (brief: CampaignBrief, campaign: Campaign) => {
  const { go_live_start, go_live_end, submit_application } = brief;
  const { owner_user: manager } = campaign;

  return [
    {
      icon: "user",
      label: "Campaign Manager",
      value: [manager?.full_name, manager.email].join(", "),
    },
    {
      icon: "calendar",
      label: "Acceptance Deadline",
      value: submit_application
        ? moment(submit_application).format(DATE_FORMAT)
        : "Deadline Not Set",
    },
    {
      icon: "speaker",
      label: "Campaign Flight Dates",
      value: [
        moment(go_live_start).format(DATE_FORMAT),
        moment(go_live_end).format(DATE_FORMAT),
      ].join(" - "),
    },
  ];
};

interface BriefPreviewTimelineProps {
  brief: CampaignBrief;
  campaign: Campaign;
}

const BriefPreviewTimeline = ({
  brief,
  campaign,
}: BriefPreviewTimelineProps) => {
  const { sections } = brief;

  const timelineSection = sections.find(({ kind }) => kind === "timeline");

  return (
    <div className="pt-8">
      <h2 className="mb-4 text-xl">Timeline</h2>
      {timelineSection ? (
        <div className="ql-snow">
          <div
            className="ql-editor no-padding"
            dangerouslySetInnerHTML={{ __html: timelineSection.body }}
          />
        </div>
      ) : null}
      {/* <div className="flex items-center justify-between gap-4 pr-8">
        {getTimelineItems(brief, campaign).map((item, index) => {
          const { icon, label, value } = item;

          return (
            <div className="flex items-center gap-4" key={index}>
              <FIcon color="#000721" icon={icon} size={24} />
              <div>
                <p className="font-bold">{label}</p>
                <p className="text-default_weak">{value}</p>
              </div>
            </div>
          );
        })}
      </div> */}
    </div>
  );
};

export default BriefPreviewTimeline;
