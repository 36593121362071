import heic2any from "heic2any";
import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import FIcon from "@components/FIcon";
import FSpinner from "./FSpinner";

interface Props {
  type: string;
  url: string;
  name: string;
  id?: string;
  apiFunction: (id: number, formData: FormData) => Promise<any>;
  resourceName: string;
  resourceId: number;
  onUpdate?: (uploadedImage: string, response?: unknown) => void;
  onDelete?: () => void;
  showPreviewImage?: boolean;
  includeHover?: boolean;
}

const FileUpload = ({
  type,
  id,
  url,
  name,
  apiFunction,
  resourceName,
  resourceId,
  showPreviewImage = true,
  includeHover = false,
  onUpdate,
  onDelete,
}: Props) => {
  const [attachment, setAttachment] = useState<string>(url);

  const [isUploading, setIsUploading] = useState(false);

  const previewAsset = () => {
    if (type === "image") {
      return (
        <div className="flex justify-center items-center h-[220px]">
          <img
            src={attachment || url}
            alt="Image"
            className="rounded-lg h-full"
          />
        </div>
      );
    }
  };

  const deleteAttachmentButton = () => {
    return (
      <button
        onClick={() => onDelete()}
        className="absolute top-2 right-0 z-10"
      >
        <FIcon icon="delete" size={20} color="#FF5C5C" className="mr-2" />
      </button>
    );
  };

  useEffect(() => {
    setAttachment(url);
  }, [url]);

  const handleImage = async (event) => {
    let file = event.target.files[0];

    if (["image/heic", "image/heif"].includes(file?.type)) {
      const blob = (await heic2any({
        blob: file, // Use the original file object
        toType: "image/jpeg",
        quality: 0.7, // adjust quality as needed
      })) as Blob;

      file = new File([blob], file.name.replace(".heic", ".jpg"));
    }

    const updatedAttachment = URL.createObjectURL(file);

    setAttachment(updatedAttachment);

    const formData = new FormData();
    formData.append(`${resourceName}[${event.target.name}]`, file, file.name);

    // Update campaign brief on upload.

    setIsUploading(true);

    const response = await apiFunction(resourceId, formData);

    setIsUploading(false);

    if (onUpdate) {
      onUpdate(updatedAttachment, response);
    }

    toast.success("Saved");
  };

  return (
    <div className="relative w-full h-full">
      {showPreviewImage ? (
        <div className="w-full h-full rounded-lg relative cursor-pointer">
          {onDelete && deleteAttachmentButton()}
          <Toaster toastOptions={{ duration: 1000 }} />
          <label htmlFor={name} className="w-full h-full cursor-pointer">
            {previewAsset()}
            {includeHover && (
              <div className="absolute inset-0 flex flex-col items-center justify-center hover:bg-highlight_red_opacity opacity-0 hover:opacity-100 rounded-lg">
                <span className="mt-2 px-4 py-1 bg-white text-dark_night_sky rounded">
                  Edit Image
                </span>
              </div>
            )}
            <input
              id={id || name}
              name={name}
              type="file"
              onChange={handleImage}
              className="hidden"
            />
          </label>
        </div>
      ) : (
        <div className="flex flex-col relative items-center justify-center h-full w-full hover:bg-highlight_red_opacity opacity-0 hover:opacity-100 cursor-pointer rounded-lg">
          {onDelete && deleteAttachmentButton()}
          <label
            htmlFor={name}
            className="mt-2 px-4 py-1 bg-blue-500 text-white bg-black  cursor-pointer rounded-lg"
          >
            Edit Image
            <input
              id={id || name}
              name={name}
              type="file"
              onChange={handleImage}
              className="absolute top-0 left-0 w-full h-full"
            />
          </label>
        </div>
      )}
      {isUploading ? (
        <div className="absolute left-[calc(50%-12px)] top-[calc(50%-12px)]">
          <FSpinner
            width={24}
            height={24}
            borderWidth={2}
            borderColor="transparent"
            backgroundColor="#FFFFFF"
          />
        </div>
      ) : null}
    </div>
  );
};

export default FileUpload;
