import React, {
  useState,
  useContext,
  useRef,
  useEffect,
  useCallback,
} from "react";
import { Outlet, useLocation } from "react-router-dom";
import {
  DashboardProvider,
  CampaignMetrics,
} from "@contexts/DashboardProvider";
import { UserContext } from "@contexts/index";
import MultiPageLayout from "@layouts/MultiPageLayout";
import { CampaignContent, ICampaign } from "types/types";
import { DateRange } from "@utils/dashboardUtils";
import { changeMembership } from "@api/user";
import {
  fetchCampaignData,
  sortMembershipsByCurrentBrand,
} from "@utils/dashboardUtils";

import { DashboardHeader } from "./components/DashboardHeader";
import { getAnalyticsData } from "@utils/dashboardUtils";

const dashboardNavItems = [
  {
    label: "Overview",
    value: "overview",
    link: "/home",
    matchPattern: "/home",
  },
  {
    label: "Content",
    value: "content",
    link: "/content",
    matchPattern: "/content",
  },
];

export default function DashboardWrap() {
  const location = useLocation();
  const { user, setUser } = useContext(UserContext);
  const [dateRange, setDateRange] = useState<DateRange>("90");
  const [isLoading, setIsLoading] = useState(true);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [metrics, setMetrics] = useState<CampaignMetrics>({
    campaigns: 0,
    engagementRate: 0,
    impressions: 0,
    posts: 0,
    influencers: 0,
  });

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isBrandDropdownOpen, setIsBrandDropdownOpen] = useState(false);
  const [campaigns, setCampaigns] = useState<ICampaign[]>([]);
  const [campaignContent, setCampaignContent] = useState<CampaignContent[]>([]);
  const [activePost, setActivePost] = useState(null);
  const [contentModalOpen, setContentModalOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const brandDropdownRef = useRef<HTMLDivElement>(null);

  const fetchAndProcessData = useCallback(async () => {
    setIsLoading(true);
    setIsPageLoading(true);
    try {
      const { campaigns: newCampaigns, content: newContent } =
        await fetchCampaignData(user.brand_id, dateRange);
      setCampaigns(newCampaigns);
      setCampaignContent(newContent);
    } catch (error) {
      console.error("Error in fetchAndProcessData:", error);
    } finally {
      setIsLoading(false);
      setIsPageLoading(false);
    }
  }, [dateRange, user.brand_id]);

  useEffect(() => {
    if (user?.brand_id) {
      fetchAndProcessData();
    }
  }, [dateRange, fetchAndProcessData, user.brand_id]);

  // Handle click outside for date range dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  // Handle click outside for brand dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        brandDropdownRef.current &&
        !brandDropdownRef.current.contains(event.target as Node)
      ) {
        setIsBrandDropdownOpen(false);
      }
    };

    if (isBrandDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isBrandDropdownOpen]);

  const handleDateRangeChange = (newRange: DateRange) => {
    setDateRange(newRange);
    setIsDropdownOpen(false);
  };

  const handleBrandChange = async (membership) => {
    try {
      const result = await changeMembership({ id: membership.id });
      if (result.data.brand_id === membership.brand_id) {
        setUser((prevUser) => ({
          ...prevUser,
          brand_id: membership.brand_id,
        }));
        setIsBrandDropdownOpen(false);

        window.location.reload();
      } else {
        console.error("Failed to change membership");
      }
    } catch (error) {
      console.error("Error changing membership:", error);
    }
  };

  const handleContentModalClick = (content) => {
    setActivePost(content);
    setContentModalOpen(true);
  };

  const handleContentModalClose = () => {
    setContentModalOpen(false);
    setActivePost(null);
  };

  const currentMembership = user.memberships.find(
    (m) => m.brand_id === user.brand_id
  );

  const sortedMemberships = sortMembershipsByCurrentBrand(
    user.memberships,
    user.brand_id
  );

  const analyticsData = getAnalyticsData(metrics, dateRange);

  const customHeader = (
    <DashboardHeader
      user={user}
      dateRange={dateRange}
      isDropdownOpen={isDropdownOpen}
      setIsDropdownOpen={setIsDropdownOpen}
      dropdownRef={dropdownRef}
      handleDateRangeChange={handleDateRangeChange}
      isBrandDropdownOpen={isBrandDropdownOpen}
      setIsBrandDropdownOpen={setIsBrandDropdownOpen}
      brandDropdownRef={brandDropdownRef}
      currentMembership={currentMembership}
      handleBrandChange={handleBrandChange}
      currentBrandId={user.brand_id}
      sortedMemberships={sortedMemberships}
      isLoading={isLoading}
      campaigns={campaigns}
      campaignContent={campaignContent}
      setMetrics={setMetrics}
      analyticsData={analyticsData}
    />
  );

  const contextValue = {
    dateRange,
    setDateRange,
    metrics,
    setMetrics,
    isLoading,
    isPageLoading,
    currentBrandId: user.brand_id,
    setCurrentBrandId: () => {},
    campaigns,
    campaignContent,
    activePost,
    contentModalOpen,
    handleContentModalClick,
    handleContentModalClose,
    isDropdownOpen,
    setIsDropdownOpen,
    isBrandDropdownOpen,
    setIsBrandDropdownOpen,
    dropdownRef,
    brandDropdownRef,
    handleDateRangeChange,
    handleBrandChange,
    currentMembership,
    sortedMemberships,
  };

  const activeSubNavItems = dashboardNavItems.map((item) => ({
    ...item,
    active:
      location.pathname === item.link ||
      (item.matchPattern === "/" && location.pathname === "/") ||
      (item.matchPattern !== "/" &&
        location.pathname.startsWith(item.matchPattern)),
  }));

  return (
    <DashboardProvider value={contextValue}>
      <MultiPageLayout
        pageName="Dashboard"
        subNavClassName="!pt-4"
        subNavItems={activeSubNavItems}
        customHeader={customHeader}
      >
        <Outlet />
      </MultiPageLayout>
    </DashboardProvider>
  );
}
