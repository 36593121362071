import React from "react";

interface ProcessNavItem {
  label: string;
  value: string;
  active?: boolean;
}

interface Props {
  className?: string;
  items: ProcessNavItem[];
  selectedValue?: string;
  onChange: (value: string) => void;
}

const FProcessNav = ({
  className = "",
  items = [],
  selectedValue,
  onChange,
}: Props) => {
  // Find the index of the selected item to determine background darkness
  const selectedIndex = items.findIndex((item) => item.value === selectedValue);

  return (
    <div className={`w-full flex justify-center relative ${className}`}>
      <div className="flex items-stretch relative w-full">
        {items.map((item, index) => {
          const isActive = item.value === selectedValue;
          const shouldShowBackground = selectedIndex >= index;
          const opacity = shouldShowBackground ? 0.2 + index * 0.1 : 0;
          const isFirst = index === 0;
          const isLast = index === items.length - 1;

          return (
            <div
              key={item.value}
              className="relative flex-1 flex items-center -ml-[14px] first:ml-0"
              style={{ zIndex: items.length - index }}
            >
              {/* Left triangle (hide for first item) */}
              {!isFirst && (
                <div
                  className="absolute left-0 h-full w-[24px]"
                  style={{
                    clipPath: "polygon(0 0, 100% 50%, 0 100%)",
                    backgroundColor: "#FFFAF9",

                    zIndex: 2,
                  }}
                />
              )}

              {/* Main rectangle */}
              <div className="relative flex-1 flex" style={{ zIndex: 1 }}>
                <div
                  className="absolute inset-0"
                  style={{
                    backgroundColor: `rgba(249, 233, 231, ${opacity})`,

                    borderTop: "1px solid #F9E9E7",
                    borderBottom: "1px solid #F9E9E7",
                    ...(isFirst && {
                      borderLeft: "1px solid #F9E9E7",
                    }),
                    ...(isLast && {
                      borderRight: "1px solid #F9E9E7",
                    }),
                  }}
                />

                {/* Right triangle (hide for last item) */}
                {!isLast && (
                  <div
                    className="absolute right-0 h-full w-[24px] translate-x-full"
                    style={{
                      clipPath: "polygon(0 0, 100% 50%, 0 100%)",
                      backgroundColor: `rgba(249, 233, 231, ${opacity})`,

                      zIndex: 1,
                      borderTop: "1px solid #F9E9E7",
                      borderBottom: "1px solid #F9E9E7",
                      boxSizing: "border-box",
                      width: "25px", // Slightly wider to account for border
                    }}
                  />
                )}

                {/* Content */}
                <button
                  onClick={() => onChange(item.value)}
                  className={`
                    relative flex-1 ${isFirst ? "pl-0" : "pl-10"} pr-2 py-3 text-sm whitespace-nowrap
                    ${isActive ? "opacity-100 font-medium" : "opacity-60 hover:opacity-80"}
                    text-center z-10
                  `}
                >
                  {item.label}
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FProcessNav;
