import React, { useContext, useCallback } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { UserContext, NewReportContext } from "@contexts/index";
import MultiPageLayout from "@layouts/MultiPageLayout";
import { ReportHeader } from "./components/ReportHeader";
import { formatNumber } from "@utils/number_utilities";
import { useReportData } from "./hooks/useReportData";
import { NewReportContextType } from "@contexts/types";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";

export default function ReportWrap() {
  const location = useLocation();
  const { report_id } = useParams();
  const { trackEvent } = useContext(MixpanelContext);

  const {
    isLoading,
    setIsLoading,
    isPageLoading,
    setIsPageLoading,
    metrics,
    setMetrics,
    report,
    setReport,
    performanceGraphs,
    engagementGraphData,
    audience,
    selectedPlatform,
    setSelectedPlatform,
    audienceDataFetched,
    availablePlatforms,
    activePost,
    setActivePost,
    contentModalOpen,
    setContentModalOpen,
  } = useReportData(report_id);

  const handleContentModalClick = useCallback((content) => {
    setActivePost(content);
    setContentModalOpen(true);
  }, []);

  const handleContentModalClose = useCallback(() => {
    setContentModalOpen(false);
    setActivePost(null);
  }, []);

  const reportNavItems = [
    {
      label: "Overview",
      value: "overview",
      link: "overview",
    },
    {
      label: `Content (${formatNumber(metrics.posts)})`,
      value: "content",
      link: "content",
    },
    ...(report && report.bonus_content_count > 0
      ? [
          {
            label: `Bonus Content (${formatNumber(report.bonus_content_count)})`,
            value: "bonus",
            link: "bonus",
          },
        ]
      : []),
    {
      label: `Creators (${formatNumber(metrics.creators)})`,
      value: "creators",
      link: "creators",
    },
  ];
  

  const customHeader = (
    <ReportHeader
      report={report}
      metrics={metrics}
      performanceGraphs={performanceGraphs}
      isLoading={isLoading}
    />
  );

  const contextValue: NewReportContextType = {
    report,
    setReport,
    metrics,
    setMetrics,
    isLoading,
    setIsLoading,
    isPageLoading,
    setIsPageLoading,
    activePost,
    setActivePost,
    contentModalOpen,
    setContentModalOpen,
    handleContentModalClick,
    handleContentModalClose,
    performanceGraphs,
    engagementGraphData,
    audience,
    selectedPlatform,
    setSelectedPlatform,
    audienceDataFetched,
    availablePlatforms,
    contentSummary: undefined,
  };

  const activeSubNavItems = reportNavItems.map((item) => ({
    ...item,
    active: location.pathname === item.link,
  }));

  if (report) {
    trackEvent(EVENTS.PAGE_VIEW.REPORT, { report: report.title });
  }

  return (
    <NewReportContext.Provider value={contextValue}>
      <MultiPageLayout
        pageName="Report"
        subNavClassName="!pt-4"
        subNavItems={activeSubNavItems}
        customHeader={customHeader}
      >
        <Outlet />
      </MultiPageLayout>
    </NewReportContext.Provider>
  );
}
