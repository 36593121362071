import React, { useEffect, useState } from "react";

import apiClient from "@apiClient";

import CampaignPageHeader from "../components/CampaignPageHeader";
import LoadingSpinner from "@components/LoadingSpinner";
import {
  BriefSection,
  Campaign,
  Participant,
  ParticipantOffer,
  User,
} from "@types";
import { Link, useParams } from "react-router-dom";
import CoverImage from "../components/CoverImage";
import FSubNav from "@components/FSubNav";
import { getSubNavItems, SubNavItems } from "../components/SubNavItems";

import BriefProductPost from "@components/Campaign/Brief/ProductPost";
import CmsBriefDeliverablePost from "@components/Campaign/CMS/DeliverablePost";
import { getContractTemplate } from "@api/ContractTemplates/ContractTemplates";
import BriefContractSection from "../brief/BriefContractSection";
import Modal from "@components/Modals/Modal";
import { getCampaignBrief } from "@api/CampaignBriefs/CampaignBriefs";
import { getParticipantBriefs } from "@api/CampaignParticipantBriefs/CampaignParticipantBriefs";
import { getParticipantBriefIndex } from "@api/CampaignParticipantBriefs/CampaignParticipantBriefSections";
import FButton from "@components/FButton";
import moment from "moment";
import { getParticipantOffers } from "@api/ParticipantBriefs/ParticipantOffers";
import { uploadContract } from "@api/upload";
import useAuth from "@hooks/useAuth";
import FIcon from "@components/FIcon";
import BriefPreviewTimeline from "../components/BriefPreviewTimeline";
import { Permission } from "@constants/roles";
import ContentPreview from "@components/media/content-highlight/ContentPreview";
import BgCoverImage from "./bgCoverImage";

const { campaigns, campaign_brief_sections } = apiClient;

const getReplacements = (
  campaign: Campaign,
  participant: Participant,
  user: User
): Record<string, string> => {
  const { title } = campaign;
  const { creator, representative_entity_name } = participant || {};

  const { brand_name: brandName } = user;

  return {
    CAMPAIGN_NAME: title,
    BRAND_NAME: brandName,
    PRODUCTS: null,
    INFLUENCER_NAME: `${creator?.firstName} ${creator?.lastName}`,
    INFLUENCER_EMAIL: creator?.email,
    REPRESENTATIVE: representative_entity_name,
    CAMPAIGN_URL: `${window.location.host}/campaigns/5257/brief/9820`,
  };
};

const getContractInstanceFromTemplate = (
  templateHtml: string,
  replacements: Record<string, string>
) => {
  let result = templateHtml;

  Object.keys(replacements).forEach((key) => {
    result = result.replace(`[[${key}]]`, replacements[key]);
  });

  return result;
};

interface BriefPreviewProps {
  hideHeader?: boolean;
  participant_group_id: number;
  participant_id: number;
}

export default function BriefPreview({
  hideHeader,
  participant_group_id,
  participant_id,
}: BriefPreviewProps) {
  const { campaign_id } = useParams();

  const [loading, setLoading] = useState(true);
  const [campaign, setCampaign] = useState<Campaign>(null);

  const [googleDocUrl, setGoogleDocUrl] = useState(null);

  const [uploadingToDrive, setUploadingToDrive] = useState(false);

  const [participantOffers, setParticipantOffers] =
    useState<ParticipantOffer[]>(null);

  const [activeContractSection, setActiveContractSection] =
    useState<BriefSection>(null);

  const [selectedNav, setSelectedNav] = useState<string>(SubNavItems[0].value);

  const { can, user } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      const campaignResponse = await campaigns.get(campaign_id);

      const campaign = campaignResponse.data;

      const { contract_template_id } = campaign;

      let contractTemplate;

      if (contract_template_id) {
        const contractTemplateResponse =
          await getContractTemplate(contract_template_id);

        contractTemplate = contractTemplateResponse.data;
      }

      let brief;

      if (participant_id) {
        const participantBriefs = await getParticipantBriefs({
          participant_campaign_id_eq: parseInt(campaign_id, 10),
        });

        brief = participantBriefs.find(
          (brief) => brief.participant_id === participant_id
        );

        const participantBriefSections = await getParticipantBriefIndex({
          participant_brief_id: brief.id,
        });

        brief.sections = participantBriefSections || [];

        const participantOffers = await getParticipantOffers(participant_id);

        setParticipantOffers(participantOffers);
      } else {
        const briefs = await getCampaignBrief(campaign_id);

        brief = participant_group_id
          ? briefs.find(
              (brief) => brief.participant_group_id === participant_group_id
            )
          : campaign.brief;

        const campaignBriefSections = await campaign_brief_sections.get(
          brief.id
        );

        brief.sections = campaignBriefSections || [];
      }

      campaign.brief = brief;

      setCampaign({ ...campaign, contract: contractTemplate });

      setLoading(false);
    };

    fetchData();
  }, []);

  if (loading) {
    return <LoadingSpinner className="w-full h-[90vh]" />;
  }

  const {
    brief,
    contract,
    deliverables,
    owner_user: manager,
    participants,
    payment_terms: paymentTerms,
    products,
  } = campaign;

  const { description, sections = [] } = brief;

  const mainSection = sections.find(
    ({ custom, kind }) => kind === selectedNav && !custom
  );

  const customSections = sections.filter(
    ({ custom, kind }) => kind === selectedNav && custom
  );

  const creativeDirection = sections.find(
    ({ kind }) => kind === "creative_direction"
  );

  const { assets = [] } = creativeDirection || {};

  let allSections;

  if (selectedNav === "about") {
    allSections = [
      {
        kind: "about",
      },
    ];
  } else if (selectedNav === "guidelines") {
    allSections = [
      sections.find(({ kind }) => kind === "do"),
      sections.find(({ kind }) => kind === "dont"),
    ];
  } else {
    allSections = mainSection
      ? [mainSection, ...customSections]
      : customSections;
  }

  const participant = participant_id
    ? participants.find(({ id }) => id === participant_id)
    : null;

  if (selectedNav === "payment") {
    sections.unshift({
      body: paymentTerms,
    } as BriefSection);
  }

  const handleAcceptOffer = () => {};

  const handleApprove = () => {};

  const handleEdit = async () => {
    if (!participant) {
      return;
    }

    const dealMemo = contract.sections.find(
      ({ kind }) => kind === "deal_memo"
    ).body;

    const htmlContent = `<html><body>
    <a href="${window.location.href}?hasAccepted=true" target="_blank">Accept changes and mark as ready for review by Fohr</a>
    ${dealMemo.replace("’", "'")}
    </body></html>`;

    const { firstName, lastName } = participant.creator;

    setUploadingToDrive(true);

    const response = await uploadContract({
      htmlContent,
      participant: `${firstName} ${lastName}`,
    });

    setUploadingToDrive(false);

    const { docUrl } = response;

    setGoogleDocUrl(docUrl);

    window.open(docUrl, "_blank").focus();

    setActiveContractSection(null);
  };

  const handleSetActiveContractSection = (briefSection: BriefSection) => {
    const replacements = getReplacements(campaign, participant, user);

    const contract = getContractInstanceFromTemplate(
      briefSection.body,
      replacements
    );

    setActiveContractSection({ ...briefSection, body: contract });
  };

  let subNavItems = getSubNavItems(campaign).filter(
    ({ label }) => !["Details", "Images"].includes(label)
  );

  subNavItems = subNavItems.filter(({ value }) => {
    if (value === "contract") {
      return can(Permission.VIEW_CAMPAIGN_BRIEFS_CONTRACTS);
    }

    if (value === "payment") {
      return can(Permission.VIEW_CAMPAIGN_BRIEFS_PAYMENT_TERMS);
    }

    return true;
  });

  const subNavItemsWithContent = subNavItems.filter(({ value }) => {
    console.log("subNavItems", subNavItems);

    if (value === "guidelines") {
      return (
        sections.find(({ kind }) => kind === "do") ||
        sections.find(({ kind }) => kind === "dont")
      );
    }

    if (value === "contract") {
      return true;
    }

    return sections.find(({ kind }) => kind === value);
  });

  const offerPrice = participantOffers && participantOffers[0]?.selected_price;

  console.log("Nav", selectedNav, assets, creativeDirection);

  console.log("brief", brief);

  return (
    <div>
      <BgCoverImage imageUrl={brief.cover_image.original} />
      <div className="relative z-10 px-[64px] -mt-[400px]">
        {!hideHeader ? (
          <>
            <div className="mb-[32px] pb-[14px] flex flex-row justify-between w-full pt-[32px]">
              <Link
                to={
                  user
                    ? `/campaigns/${campaign_id}${participant_id ? `/participants/${participant_id}/brief` : ""}`
                    : `/campaigns/${campaign_id}/participant-review`
                }
                className="h-[40px] rounded-lg bg-white flex items-center gap-2 border border-neutral_400 cursor-pointer hover:border-neutral_600 px-4 w-max"
              >
                <FIcon
                  icon="undo"
                  size={18}
                  color="#000F45"
                  className="-mt-1"
                />
                <p className="text-sm text-dark_night_sky">Back</p>
              </Link>
              {brief?.submit_application && (
                <div className="flex flex-row gap-0 items-center bg-black rounded-lg border border-white px-4 py-1 w-max gap-2 h-[40px]">
                  <p className="text-sm text-white">Acceptance Deadline:</p>
                  <p className="font-light text-sm text-white">
                    {brief?.submit_application
                      ? moment(brief?.submit_application).format("MMMM D, YYYY")
                      : null}
                  </p>
                </div>
              )}
            </div>
            <div className="mb-[64px]">
              <CampaignPageHeader centered campaign={campaign} />
            </div>
          </>
        ) : null}

        <div className="bg-white rounded-2xl shadow-lg mb-[40px]">
          <div className="px-[40px]">
            <FSubNav
              className={`pt-4`}
              items={subNavItemsWithContent.map((item) => ({
                ...item,
                active: item.value === selectedNav,
              }))}
              selectedValue={selectedNav}
              onChange={(value) => setSelectedNav(value as string)}
            />
          </div>
          <div className="flex p-[40px]">
            <div className="flex flex-1 flex-col gap-8 pr-6 w-[calc(100%-300px)]">
              {allSections.map((section, index) => {
                const { kind } = section;

                const body = kind === "about" ? description : section.body;

                return (
                  <div className="ql-snow" key={index}>
                    <div
                      className="ql-editor no-padding"
                      dangerouslySetInnerHTML={{ __html: body }}
                    />
                  </div>
                );
              })}
              {selectedNav === "about" ? (
                <BriefPreviewTimeline brief={brief} campaign={campaign} />
              ) : null}
              {selectedNav === "products"
                ? products
                    ?.sort(
                      (a, b) =>
                        new Date(b.updated_at).getTime() -
                        new Date(a.updated_at).getTime()
                    )
                    .map((product, index) => (
                      <div className="" key={index}>
                        <BriefProductPost key={product.id} product={product} />
                      </div>
                    ))
                : null}
              {selectedNav === "deliverables"
                ? deliverables
                    ?.sort(
                      (a, b) =>
                        new Date(b.updated_at).getTime() -
                        new Date(a.updated_at).getTime()
                    )
                    .map((deliverable) => (
                      <CmsBriefDeliverablePost
                        deliverable={deliverable}
                        key={deliverable.id}
                        isReadOnly
                        influencers={participants}
                      />
                    ))
                : null}
              {selectedNav === "moodboard" ? (
                <div className="grid grid-cols-5 gap-4 auto-rows-fr">
                  {assets.map((asset, index) => {
                    const { image_url } = asset;

                    console.log("Asset", asset);

                    const content = { photo_url: image_url };

                    return (
                      <div className="h-[200px] relative">
                        <ContentPreview
                          key={index}
                          content={content}
                          showFullAssetInModal
                        />
                      </div>
                    );
                  })}
                </div>
              ) : null}
              {selectedNav === "contract" && contract ? (
                <>
                  {contract.sections
                    ?.sort(
                      (a, b) =>
                        new Date(b.updated_at).getTime() -
                        new Date(a.updated_at).getTime()
                    )
                    .map((section) => (
                      <div>
                        <BriefContractSection
                          key={section.id}
                          onClick={handleSetActiveContractSection}
                          section={section}
                        />
                        {googleDocUrl && section.kind === "deal_memo" ? (
                          <div>
                            <p>Pending document on Google Drive:</p>
                            <a
                              className="text-dark_night_sky font-medium underline hover:underline normal-case"
                              href={googleDocUrl}
                              target="_blank"
                            >
                              {googleDocUrl}
                            </a>
                          </div>
                        ) : null}
                      </div>
                    ))}
                </>
              ) : null}
              {selectedNav === "payment" && brief.invoice_template ? (
                <div className="flex w-fit flex-row items-center gap-2 px-4 py-2 justify-between ml-2 relative bg-highlight_red rounded-lg">
                  <a
                    className="text-[15px] text-dark_night_sky underline font-medium"
                    href={brief.invoice_template}
                    target="_blank"
                    download
                  >
                    Uploaded Template
                  </a>
                </div>
              ) : null}
            </div>
            <div className="w-[300px]">
              <div className="border border-neutral_400 bg-white rounded-lg shadow-md px-[24px] py-[32px]">
                <h3 className="text-[20px]">Offer</h3>
                {offerPrice ? (
                  <h3 className="my-[16px] text-[22px] text-[#1F8855]">
                    ${offerPrice.toLocaleString()}
                  </h3>
                ) : null}
                {participant_id ? (
                  <FButton
                    label="Accept Offer"
                    onClick={handleAcceptOffer}
                    primary
                    width="100%"
                  />
                ) : null}

                {brief?.submit_application && (
                  <div className="mt-[8px]">
                    <p className="mb-[4px] mt-[16px] text-[14px] opacity-50">
                      Acceptance Deadline
                    </p>
                    <p className="font-light text-[16px]">
                      {brief?.submit_application
                        ? moment(brief?.submit_application).format(
                            "MMMM D, YYYY"
                          )
                        : null}
                    </p>
                  </div>
                )}

                <p className="mb-[4px] mt-[16px] text-[14px] opacity-50">
                  Campaign Manager
                </p>
                <div className="flex flex-row gap-2 items-center">
                  <p className="font-light text-[16px]">{manager?.full_name}</p>
                  {manager?.email && (
                    <a
                      href={`mailto:${manager.email}`}
                      className="flex items-center gap-1 font-light text-[16px]"
                    >
                      <FIcon
                        icon="email-bold"
                        size={16}
                        color="black"
                        className="-mt-[2px]"
                      />
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {activeContractSection ? (
        <Modal
          title={activeContractSection.title}
          isOpen
          hasForm
          actionLabel="Confirm"
          onAction={() => {
            setActiveContractSection(null);
          }}
          onClose={() => {
            setActiveContractSection(null);
          }}
          className="h-[500px] overflow-auto w-[850px] pb-0"
          children={
            <div>
              <div className="ql-editor">
                <div
                  dangerouslySetInnerHTML={{
                    __html: activeContractSection.body,
                  }}
                />
              </div>
              <div className="flex justify-end space-x-2">
                <FButton
                  onClick={() => {
                    setActiveContractSection(null);
                  }}
                  label="Cancel"
                  width="100%"
                  height="40px"
                />
                <FButton
                  onClick={handleEdit}
                  label="Edit"
                  loading={uploadingToDrive}
                  primary
                  width="100%"
                  height="40px"
                />
                <FButton
                  onClick={handleApprove}
                  primary
                  label="Approve"
                  width="100%"
                  height="40px"
                />
              </div>
            </div>
          }
        />
      ) : null}
    </div>
  );
}
