import React, { useCallback } from "react";

import { Sort } from "@types";
import SinglePageLayoutWithData from "@layouts/SinglePageLayoutWithData";

import { getAccountList } from "@api/user";

// Default sorting configuration
const defaultSort: Sort = {
  key: "created_at",
  direction: "asc",
};

const AccountsAdmin = () => {
  // Fetch accounts with sorting, filtering, and pagination
  const getAccounts = useCallback(
    async (page?: number, sort?: Sort, params = { search: null }) => {
      const { search } = params;
      const sortToUse = sort || defaultSort;

      // Adjust sort key for status column
      if (sortToUse.key === "status") {
        sortToUse.key = "expires_at";
      }

      const query = { email_address_cont: search };

      const data = await getAccountList(page, sortToUse, query);

      return {
        ...data,
        items: data.accounts,
      };
    },
    []
  );

  const tableColumns = [
    {
      key: "email_address",
      label: "Email",
      isLink: true,
      linkURL: "/admin/accounts/{id}",
    },
    {
      key: "created_at",
      label: "Created At",
      isDate: true,
    },
    {
      key: "expires_at",
      label: "Expires At",
      isDate: true,
    },
  ];

  return (
    <>
      <SinglePageLayoutWithData
        entityName="brand"
        pageTitle="Accounts"
        pageName="Accounts"
        tableOnly
        tableColumns={tableColumns}
        getItems={getAccounts}
      />
    </>
  );
};

export default AccountsAdmin;
