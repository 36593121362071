import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ProfileLayout from "@layouts/ProfileLayout";
import { ProfileContext } from "@contexts/index";
import apiClient from "@apiClient";
import useAuth from "@hooks/useAuth";
import LoadingSpinner from "@components/LoadingSpinner";

import PROFILE_ROUTES from "./routes";

const { profiles } = apiClient;

export default function Profile() {
  const { slug } = useParams<{ slug: string }>();
  const [profile, setProfile] = useState(null);
  const [contentHighlight, setContentHighlight] = useState(null);
  const [topContent, setTopContent] = useState(null);
  const [responses, setResponses] = useState([]);

  const [loaded, setLoaded] = useState(false);

  const [collapsed, setCollapsed] = useState(false);

  const { user } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      setLoaded(false);

      const overviewResponse = await profiles.overview_data(slug);

      const topContent = await profiles.top_content(slug);

      setProfile(overviewResponse.data);
      setContentHighlight(topContent.data);
      setTopContent(topContent.data);

      setLoaded(true);
    };

    fetchData();
  }, [slug]);

  return loaded ? (
    <ProfileContext.Provider
      value={{
        collapsed,
        setCollapsed,
        profile,
        responses,
        contentHighlight,
        topContent,
        setProfile,
        setResponses,
      }}
    >
      <ProfileLayout
        highlightRight={false}
        subNavItems={
          user
            ? PROFILE_ROUTES
            : PROFILE_ROUTES.filter(({ label }) => label !== "Responses")
        }
        user={user}
      ></ProfileLayout>
    </ProfileContext.Provider>
  ) : (
    <LoadingSpinner className="h-screen w-full" />
  );
}
