import React from "react";
import FIcon from "../FIcon";

interface Props {
  className?: string;
  label?: string;
  isChecked?: boolean;
  onChange?: (isChecked: boolean) => void;
}

const FSwitch = ({
  className = "",
  label = "",
  isChecked,
  onChange,
}: Props) => {
  return (
    <label
      className={`inline-flex items-center cursor-pointer gap-8 ${className}`}
    >
      <input
        type="checkbox"
        value=""
        className="sr-only peer"
        checked={isChecked}
        onChange={(e: any) => {
          onChange(e.target.checked);
        }}
      />
      <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
        {label}
      </span>
      <div
        className="relative w-[44px] h-[24px] bg-neutral_200 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full 
                peer-checked:after:border-white after:content-['']  after:absolute after:top-0.5 after:start-[2px] 
                after:bg-white after:border after:border-neutral_300
                after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-dark_night_sky"
      ></div>
    </label>
  );
};

export default FSwitch;
