interface FButtonGroupProps {
  options: { value: string; label: string }[];
  selectedValue: string;
  onSelectedValue: (value: string) => void;
  buttonWidth?: string;
}

const FButtonGroup = ({
  options,
  selectedValue,
  onSelectedValue,
  buttonWidth,
}: FButtonGroupProps) => (
  <div className="flex gap-0.5">
    {options.map(({ value, label }, index) => {
      const isSelected = value === selectedValue;

      const isFirst = index === 0;
      const isLast = index === options.length - 1;

      return (
        <button
          className={`h-[40px] flex-1 ${isSelected ? "bg-dark_night_sky" : "bg-neutral_200"} text-${isSelected ? "white" : "default_weak"} ${isSelected ? "rounded mr-[-2px]" : ""} ${isLast ? "rounded-r" : ""} ${isFirst ? "rounded-l" : ""} ${buttonWidth ? `w-[${buttonWidth}]` : ""}`}
          key={value}
          onClick={() => onSelectedValue(value)}
        >
          {label}
        </button>
      );
    })}
  </div>
);

export default FButtonGroup;
