import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FDropdown from "@components/FDropdown";
import FIcon from "@components/FIcon";
import useAuth from "@hooks/useAuth";
import { Permission } from "@constants/roles";
import { toast } from "react-hot-toast";
import apiClient from "@apiClient";
import CreateOrUpdateCampaignModal from "./CreateOrUpdateCampaignModal";
import { Campaign } from "@types";

const { campaigns } = apiClient;

interface TitleSectionProps {
  participant_id: string | undefined;
  titleOptions: { label: string; value: string | number }[];
  activeBrief: any;
  handleTitleChange: (value: string) => void;
  groupBriefDetails: {
    participantFirstName?: string;
    groupBriefName?: string;
    groupBriefId?: string;
  };
  campaign_id: string | undefined;
  campaign: Campaign;
}

export const TitleSection: React.FC<TitleSectionProps> = ({
  participant_id,
  titleOptions,
  activeBrief,
  handleTitleChange,
  groupBriefDetails,
  campaign_id,
  campaign,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { can } = useAuth();
  const isSettings = location.pathname.includes("settings");
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const activeBriefTitle =
    titleOptions.find((option) => option.value === activeBrief?.id)?.label ||
    "Brief";

  const cardDropDownOptions = [
    can(Permission.UPDATE_CAMPAIGNS) ? { label: "Edit", value: "edit" } : null,
    can(Permission.CREATE_CAMPAIGNS)
      ? { label: "Duplicate", value: "duplicate" }
      : null,
    can(Permission.DESTROY_CAMPAIGNS)
      ? { label: "Archive", value: "archive" }
      : null,
  ].filter(Boolean);

  const handleDropdownSelect = async (value: string) => {
    if (value === "duplicate") {
      try {
        const response = await campaigns.duplicate(Number(campaign_id));
        if (response.success && response.data) {
          toast.success("Campaign duplicated successfully");
          window.location.reload();
        } else {
          toast.error("Failed to duplicate campaign");
        }
      } catch (error) {
        toast.error("Failed to duplicate campaign");
      }
    } else if (value === "archive") {
      try {
        await campaigns.update(Number(campaign_id), { status: "archived" });
        toast.success("Campaign archived successfully");
        window.location.href = "/campaigns";
      } catch (error) {
        toast.error("Failed to archive campaign");
      }
    } else if (value === "edit") {
      setIsEditModalVisible(true);
    }
  };

  const handleCampaignEdited = (updatedCampaign: Campaign) => {
    setIsEditModalVisible(false);
    window.location.reload();
  };

  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="flex flex-row justify-between items-center">
          <h1>{isSettings ? `${activeBriefTitle} Settings` : "Brief"}</h1>
          <div className="flex items-center gap-4">
            {isSettings ? (
              <Link
                className="flex flex-row items-center gap-1 whitespace-nowrap"
                to={`/campaigns/${campaign_id}/brief/${activeBrief.id}`}
              >
                <FIcon icon="back" size={18} color="#000F45" />
                Back
              </Link>
            ) : null}
          </div>
        </div>

        <div className="flex flex-row justify-between items-center w-full pb-8">
          <div className="flex flex-row gap-4 justify-between w-full items-center">
            {!isSettings && !participant_id && titleOptions.length > 1 ? (
              <div className="text-2xl font-medium w-full flex flex-row gap-2">
                <FDropdown
                  options={titleOptions}
                  transparent
                  width="auto"
                  className="min-w-[250px] bg-white"
                  height={"40"}
                  selectedValue={activeBrief?.id}
                  onChange={(value) => handleTitleChange(value as string)}
                />
              </div>
            ) : null}
            {!isSettings ? (
              <button
                className="ml-2 text-sm px-2 py-1 text-neutral_600 hover:underline flex flex-row items-center gap-2"
                onClick={() =>
                  navigate(
                    `/campaigns/${campaign_id}/${participant_id && groupBriefDetails.groupBriefId ? `brief/${groupBriefDetails.groupBriefId}/settings` : `brief/${activeBrief.id}/settings`}`
                  )
                }
              >
                <FIcon
                  icon="settings"
                  size={18}
                  color="#000F45"
                  className="-mt-[2px]"
                />
                Settings
              </button>
            ) : null}
          </div>
          {participant_id && (
            <Link
              to={`/campaigns/${campaign_id}/brief/${groupBriefDetails.groupBriefId}`}
              className="h-[40px] px-4 flex items-center border border-light_border rounded-sm whitespace-nowrap"
            >
              <p className="text-[15px] font-medium">
                View Group Brief for {groupBriefDetails.participantFirstName}
              </p>
            </Link>
          )}
        </div>
      </div>

      {isEditModalVisible && (
        <CreateOrUpdateCampaignModal
          entity={campaign}
          onClose={() => setIsEditModalVisible(false)}
          onUpdated={handleCampaignEdited}
        />
      )}
    </>
  );
};
