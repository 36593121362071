import { updateCampaign } from "@api/Campaign/Campaigns";
import FButton from "@components/FButton";
import { Campaign } from "@types";
import { useState } from "react";
import toast from "react-hot-toast";

interface SubmitForApprovalOverlayProps {
  campaign: Campaign;
  onSubmittedForReview: () => void;
  onEdit: () => void;
}

const SubmitForApprovalOverlay = ({
  campaign,
  onSubmittedForReview,
  onEdit,
}: SubmitForApprovalOverlayProps) => {
  const [isSubmittingForReview, setSubmittingForReview] = useState(false);

  const handleSubmitForReview = async () => {
    setSubmittingForReview(true);

    try {
      await updateCampaign(campaign.id, {
        submitted_for_finance_review: true,
      });

      onSubmittedForReview();
    } catch {
      toast.error("Error submitting for review");
    } finally {
      setSubmittingForReview(false);
    }
  };

  const { submitted_for_finance_review } = campaign;

  return (
    <div className="absolute left-0 top-0 h-full w-full z-[100] bg-[#00000080] backdrop-blur-[5px] flex items-center justify-center">
      <div className="flex items-center gap-4">
        <FButton
          primary
          disabled={submitted_for_finance_review}
          loading={isSubmittingForReview}
          label={`${submitted_for_finance_review ? "Submitted" : "Submit"} for Finance Review`}
          onClick={handleSubmitForReview}
        />
        {!submitted_for_finance_review ? (
          <FButton label="Edit" onClick={onEdit} />
        ) : null}
      </div>
    </div>
  );
};

export default SubmitForApprovalOverlay;
