import React, { useEffect, useMemo, useState } from "react";
import { CampaignContext } from "@contexts/index";
import { useParams } from "react-router-dom";
import {
  Campaign,
  CampaignBrief,
  Content,
  Participant,
  ParticipantGroup,
} from "@types";

import apiClient from "@apiClient";

import LoadingSplash from "@components/LoadingSplash";

import EventSideNav from "@components/Event/EventSideNav";
import EventTopNav from "@components/Event/EventTopNav";
import ParticipantsOverview from "@pages/campaigns/participant-review/ParticipantsOverview";

import LoadingSpinner from "@components/LoadingSpinner";
import Toaster from "@components/Toaster";

import UpdateParticipantStatusModal from "./components/UpdateStatusModal";
import { getParticipantSampleContent } from "@api/Campaign/CampaignParticipants";
import { maxBy, size } from "lodash";
import { searchPosts } from "@api/Search";

const { campaigns, campaign_brief } = apiClient;

const TOP_SAMPLE_CONTENT_COUNT = 5;

const getRepresentativeSampleContent = (
  sampleContentsForParticipants: Content[][],
  result: Content[] = [],
  roundIndex = 0
) => {
  const maxRoundItem = maxBy(sampleContentsForParticipants, (contents) =>
    size(contents)
  );

  const contentForRound = sampleContentsForParticipants.reduce(
    (result, contents) => {
      const itemForRound = (contents || [])[roundIndex];

      if (!itemForRound?.photo_url || itemForRound.is_video) {
        return result;
      }

      return [...result, itemForRound];
    },
    []
  );

  const allContent = [...result, ...contentForRound];

  if (size(allContent) >= 5) {
    return allContent.slice(0, 5);
  }

  if (roundIndex >= size(maxRoundItem)) {
    return allContent;
  }

  return getRepresentativeSampleContent(
    sampleContentsForParticipants,
    allContent,
    roundIndex + 1
  );
};

const getSampleContent = (participants: Participant[]): Promise<Content[]> =>
  Promise.all(
    participants.map(async (participant) => {
      const response = await getParticipantSampleContent(participant.id);

      return response.data.contents;
    })
  ).then((sampleContentsForParticipants) => {
    const representativeSampleContent = getRepresentativeSampleContent(
      sampleContentsForParticipants
    );

    return representativeSampleContent;
  });

const ParticipantReview = () => {
  const { campaign_id, participant_id } = useParams<{
    campaign_id: string;
    participant_id: string;
  }>();

  const [loading, setLoading] = useState(true);
  const [campaign, setCampaign] = useState<Campaign>(null);
  const [briefs, setBriefs] = useState<CampaignBrief[]>([]);

  const [isLoadingComplete, setIsLoadingComplete] = useState(false);

  const [viewType, setViewType] = useState<string>("carousel");

  const [selectedGroup, setSelectedGroup] = useState<string>(null);

  const [selectedApprovalState, setSelectedApprovalState] =
    useState<string>(null);

  const [transitionDirection, setTransitionDirection] =
    useState<string>("right");
  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  const [isUpdatingStatus, setUpdatingStatus] = useState(false);

  const [sampleContent, setSampleContent] = useState([]);

  const { participants = [], participant_groups: participantGroups = [] } =
    campaign || {};

  const groupOptions = useMemo(() => {
    const allGroupsOption = {
      label: `All Groups (${participants.length})`,
      value: null,
    };

    const options = participantGroups.map((group: ParticipantGroup) => {
      const count = participants.filter(
        (participant: Participant) =>
          participant.participant_group_id === group.id &&
          (selectedApprovalState === null ||
            participant.status === selectedApprovalState)
      ).length;

      return {
        label: `${group.name} (${count})`,
        value: group.id.toString(),
      };
    });

    return [allGroupsOption, ...options];
  }, [participantGroups, participants, selectedApprovalState]);

  const currentIndex = groupOptions.findIndex(
    ({ value }) => value === selectedGroup
  );

  useEffect(() => {
    const fetchCampaign = async () => {
      setLoading(true);

      const response = await campaigns.get(campaign_id);

      console.log("Response", response.data);

      const { participants } = response.data;

      try {
        const sampleContents = await getSampleContent(participants);

        const diff = TOP_SAMPLE_CONTENT_COUNT - size(sampleContents);

        if (diff > 0) {
          const topContentResponse = await searchPosts({
            creator_ids: participants.map(({ creator_id }) => creator_id),
            page_size: diff,
            post_type: "image",
          });

          const contents = topContentResponse.data.results;

          contents.forEach((content) => {
            sampleContents.push(content);
          });
        }

        const campaignBriefs = await campaign_brief.get(campaign_id);

        setBriefs(campaignBriefs);
        setSampleContent(sampleContents);
      } catch (e) {
        console.error(e);
        setSampleContent([]);
      }

      const participantsForReview = participants.filter(({ status }) =>
        status.startsWith("review_")
      );

      setCampaign({ ...response.data, participants: participantsForReview });

      setLoading(false);
    };

    fetchCampaign();
  }, [campaign_id]);

  const handleGroupUp = () => {
    setSelectedGroup(groupOptions[Math.max(currentIndex - 1, 0)].value);
    setTransitionDirection("up"); // Assuming 'left' means moving to the previous item
  };

  const handleGroupDown = () => {
    setSelectedGroup(
      groupOptions[Math.min(currentIndex + 1, groupOptions.length - 1)].value
    );

    setTransitionDirection("down"); // Assuming 'right' means moving to the next item
  };

  const handleParticipantStatusUpdated = (status: string) => {
    const newParticipants = participants.map((participant) =>
      selectedItems.includes(participant.id)
        ? { ...participant, status }
        : participant
    );

    setCampaign({
      ...campaign,
      participants: newParticipants,
    });

    setSelectedItems([]);
    setUpdatingStatus(false);
  };

  if (loading) {
    return <LoadingSpinner className="h-screen" />;
  }

  return (
    <CampaignContext.Provider
      value={{ briefs, campaign, setBriefs, setCampaign }}
    >
      <Toaster />
      <div className="relative z-50 w-full h-screen flex-row">
        {!isLoadingComplete && (
          <LoadingSplash
            title={campaign?.title}
            content={sampleContent}
            onLoadingComplete={() => setIsLoadingComplete(true)}
          />
        )}
        <EventSideNav
          eventPageTitle="Creator Review"
          selectedApprovalState={selectedApprovalState}
          setSelectedApprovalState={setSelectedApprovalState}
        />
        {!participant_id ? (
          <EventTopNav
            viewType={viewType}
            onViewTypeChange={setViewType}
            onGroupUp={handleGroupUp}
            onGroupDown={handleGroupDown}
            onSelectedGroup={setSelectedGroup}
            groupOptions={groupOptions}
            selectedGroup={selectedGroup}
            selectedParticipantIds={selectedItems}
            onUpdateStatus={() => setUpdatingStatus(true)}
          />
        ) : null}
        <div className="flex flex-col h-full w-full">
          <ParticipantsOverview
            transitionDirection={transitionDirection}
            selectedGroup={selectedGroup}
            currentState={selectedApprovalState}
            selectedItems={selectedItems}
            viewType={viewType}
            onChangeSelectedItems={setSelectedItems}
          />
        </div>
        {isUpdatingStatus ? (
          <UpdateParticipantStatusModal
            onClose={() => setUpdatingStatus(false)}
            participants={selectedItems.map((id) =>
              participants.find((participant) => participant.id === id)
            )}
            onUpdated={handleParticipantStatusUpdated}
          />
        ) : null}
      </div>
    </CampaignContext.Provider>
  );
};

export default ParticipantReview;
