import { deleteComment, updateComment } from "@api/Campaign/ContentReview";
import FButton from "@components/FButton";
import { ContentReviewComment } from "@types";
import { useState } from "react";
import toast from "react-hot-toast";

import useAuth from "@hooks/useAuth";
import { Permission } from "@constants/roles";
import CommentInput from "@components/comments/CommentInput";
import Comment from "@components/comments/Comment";

interface ContentReviewCommentsProps {
  comments: ContentReviewComment[];
  onCommentDeleted: (commentId: number) => void;
  onCommentUpdated: (commentId: number, body: string) => void;
}

const ContentReviewComments = ({
  comments,
  onCommentDeleted,
  onCommentUpdated,
}: ContentReviewCommentsProps) => {
  const [commentEditedId, setCommentEditedId] = useState<number>(null);

  const [pendingComment, setPendingComment] = useState<string>(null);
  const [isEditCommentSubmitted, setIsEditCommentSubmitted] = useState(false);

  const [deletingCommentId, setDeletingCommentId] = useState<number>(null);

  const { can } = useAuth();

  // Sort comments by created_at in descending order (most recent first)
  const sortedComments = [...comments].sort(
    (a, b) =>
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
  );

  return sortedComments.map((comment) => {
    const handleSaveCommentUpdates = async () => {
      if (!pendingComment) {
        setIsEditCommentSubmitted(true);

        return;
      }

      try {
        await updateComment(commentEditedId, { body: pendingComment });

        onCommentUpdated(commentEditedId, pendingComment);

        setCommentEditedId(null);

        toast.success("Comment updated");
      } catch (e) {
        toast.error("Error updating comment");
      }
    };

    const handleEditComment = (comment) => {
      setCommentEditedId(comment.id);

      setPendingComment(comment.body);
    };

    const handleDeleteComment = async (comment) => {
      setDeletingCommentId(comment.id);

      try {
        await deleteComment(comment.id);

        toast.success("Comment deleted");
      } catch (e) {
        toast.error("Error deleting comment");
      } finally {
        setDeletingCommentId(null);
      }

      onCommentDeleted(comment.id);
    };

    if (commentEditedId === comment.id) {
      return (
        <div className="mb-8">
          <CommentInput
            pendingComment={pendingComment}
            isSubmitted={isEditCommentSubmitted}
            onChange={setPendingComment}
            onSubmit={handleSaveCommentUpdates}
          />
          <FButton label="Cancel" onClick={() => setCommentEditedId(null)} />
        </div>
      );
    }

    return (
      <Comment
        comment={comment}
        key={comment.id}
        isDeleting={deletingCommentId === comment.id}
        onDelete={
          can(Permission.DESTROY_CONTENT_COMMENTS)
            ? () => handleDeleteComment(comment)
            : null
        }
        onEdit={() => handleEditComment(comment)}
      />
    );
  });
};

export default ContentReviewComments;
