import React from "react";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import "@styles/chart.css";

import Legend from "./components/Legend";

const getSubtitle = (text: string, color: string) =>
  `<span style="color: ${color} ; font-size: 22px">${text}</span>`;

const OPTIONS = {
  chart: {
    height: 240,
  },
  tooltip: {
    pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
  },
  subtitle: {
    floating: true,
    useHTML: true,
    verticalAlign: "middle",
    y: 35,
  },
  plotOptions: {
    pie: {
      startAngle: -90,
      endAngle: 90,
      center: ["50%", "75%"],
      dataLabels: {
        enabled: false,
      },
    },
  },
  series: [
    {
      type: "pie",
      name: "Percentage",
      innerSize: "50%",
    },
  ],
};

interface HalfPieChartProps {
  data: { name: string; percentage: number }[];
  colors: string[];
  subtitle: string;
}

const HalfPieChart = ({ data, colors, subtitle }: HalfPieChartProps) => {
  const seriesData = data.map(({ name, percentage }) => [name, percentage]);

  const options = {
    ...OPTIONS,
    colors,
    subtitle: {
      ...OPTIONS.subtitle,
      text: getSubtitle(subtitle, colors[0]),
    },
    series: [
      {
        ...OPTIONS.series[0],
        data: seriesData,
      },
    ],
  };

  return (
    <div>
      <div className="flex flex-col items-center justify-center">
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
      <Legend
        labels={data.map((item, index) => ({
          color: colors[index],
          label: `${item.name} ${Math.round(item.percentage)}%`,
        }))}
      />
    </div>
  );
};

export default HalfPieChart;
