// prettier-ignore
import React from "react";
import { formatLargeNumber } from "./number_utilities";
import { isArray } from "lodash";

export const defaultInvoicePaymentCopy = (projectNumber: string | number) => {
  return `<p>We invite you to submit invoices upon completion of all deliverables and requirements outlined in your contract or according to the agreed-upon invoicing schedule described in this campaign brief. If you have any questions before sending your invoice, please contact your campaign manager directly. Billing inquiries should be addressed to <a href="mailto:billing@fohr.co">billing@fohr.co</a> and your campaign manager.</p>
<p>Please be sure to reference the Project Number ${projectNumber}.</p>
<p><strong>All invoices must be submitted to <a href="mailto:billing@fohr.co">billing@fohr.co</a>.</strong> In addition to the invoice, influencers and agencies located in the United States must submit a <a href="https://www.irs.gov/pub/irs-pdf/fw9.pdf" target="_blank">W9 form</a>. Influencers and agencies outside the United States will submit a <a href="https://www.irs.gov/pub/irs-pdf/fw8ben.pdf" target="_blank">W-8BEN form</a>.</p>
<p>In addition to the Project Number, it is essential that invoices include several additional pieces of information in order for us to process your invoice efficiently and on a timely basis. These are our "acceptance criteria."</p>
<p>The details we require are:</p>
<ul><li>Project Number</li><li>Influencer's legal name</li><li>Agency legal name, if applicable</li><li>Email address</li><li>Invoice date</li><li>Invoice amount</li><li>Campaign name (the name referenced in this campaign brief page)</li><li>Campaign manager's name</li><li>PDF Format</li></ul>
<p>Invoices that meet the above acceptance criteria will be paid 60 days after receipt or according to the payment terms specified in this campaign brief. Invoices not meeting the acceptance criteria will be rejected and you will be asked to re-submit your invoice with the required information. Invoices submitted before the completion of deliverables and contract requirements will be rejected. Payments are remitted in United States Dollars, and Fohr is not responsible for any fees, including those arising from foreign currency exchange.</p>`;
};

export const defaultPaymentCopy =
  "<p>Payment of Compensation will be made by Fohr to Influencer according to the payment schedule set forth in the Influencer Services Deal Memo and accompanying Standard Terms and Conditions (found in the 'Contracts' tab)</p>";

export const displayCounts = (post) => {
  const data = post.data || post;

  const { hashtags, geotags, mentions, media_type } = data;

  const hashtagCount = hashtags
    ? isArray(hashtags)
      ? hashtags.length
      : hashtags.split(" ").length
    : 0;

  const hashtagPlural = hashtagCount !== 1 ? "Hashtags" : "Hashtag";

  const geotagCount = geotags
    ? isArray(geotags)
      ? geotags.length
      : geotags.split(" ").length
    : 0;
  const geotagPlural = geotagCount !== 1 ? "Geotags" : "Geotag";

  const mentionCount = mentions
    ? isArray(mentions)
      ? mentions.length
      : mentions.split(" ").length
    : 0;
  const mentionPlural = mentionCount !== 1 ? "Mentions" : "Mention";

  // Only render if there's actual content to display
  if (
    !media_type &&
    hashtagCount === 0 &&
    geotagCount === 0 &&
    mentionCount === 0
  ) {
    return null;
  }

  return (
    <div className="flex mt-[3px] flex-row justify-start items-center text-sm text-neutral_600 capitalize">
      {media_type && (
        <p className="leading-[14px] h-[14px] pr-2">{media_type}</p>
      )}
      {hashtagCount > 0 && (
        <p className="border-neutral_500 px-2 leading-[14px] h-[14px]">
          {hashtagCount} {hashtagPlural}
        </p>
      )}
      {geotagCount > 0 && (
        <p className="border-neutral_500 px-2 leading-[14px] h-[14px]">
          {geotagCount} {geotagPlural}
        </p>
      )}
      {mentionCount > 0 && (
        <p className="border-neutral_500 px-2 leading-[14px] h-[14px]">
          {mentionCount} {mentionPlural}
        </p>
      )}
    </div>
  );
};

// Recursive function to find changes in nested objects
export const findChanges = (
  currentValues,
  initialVals,
  changedVals,
  keyPath = ""
) => {
  Object.keys(currentValues).forEach((key) => {
    const currentPath = keyPath ? `${keyPath}.${key}` : key;
    if (currentValues[key] !== initialVals[key]) {
      if (
        currentValues[key] &&
        typeof currentValues[key] === "object" &&
        !Array.isArray(currentValues[key])
      ) {
        changedVals[key] = changedVals[key] || {};
        findChanges(
          currentValues[key],
          initialVals[key] || {},
          changedVals[key],
          currentPath
        );
      } else {
        changedVals[currentPath] = currentValues[key];
      }
    }
  });
};

export const findNestedChanges = (newData, originalData, changes) => {
  Object.keys(newData).forEach((key) => {
    const newValue = newData[key];
    const originalValue = originalData[key];

    if (
      typeof newValue === "object" &&
      newValue !== null &&
      !Array.isArray(newValue) &&
      typeof originalValue === "object" &&
      originalValue !== null
    ) {
      const nestedChanges = {};
      findNestedChanges(newValue, originalValue, nestedChanges);
      if (Object.keys(nestedChanges).length > 0) {
        changes[key] = nestedChanges;
      }
    } else if (!isEqual(newValue, originalValue)) {
      changes[key] = newValue;
    }
  });
};

// Helper function to check equality, including handling of null and empty string
const isEqual = (value1, value2) => {
  if (value1 === value2) return true;
  if (value1 === "" && value2 === null) return true;
  if (value1 === null && value2 === "") return true;
  if (Array.isArray(value1) && Array.isArray(value2)) {
    return JSON.stringify(value1) === JSON.stringify(value2);
  }
  return false;
};

export const reformData = (data) => {
  const result = {};
  for (const key in data) {
    if (key.includes(".")) {
      const keys = key.split(".");
      keys.reduce((acc, part, index) => {
        if (index === keys.length - 1) {
          acc[part] = data[key];
        } else {
          acc[part] = acc[part] || {};
        }
        return acc[part];
      }, result);
    } else {
      result[key] = data[key];
    }
  }
  return result;
};

// NEED THE FOLLOWERS

export const calculateTotalFollowers = (data) => {
  const totalFollowers = data.reduce(
    (acc, participant) => acc + (participant.creator.overall_followers || 0),
    0
  );
  return formatLargeNumber(totalFollowers);
};

export const dominantCategories = () => {
  // This function needs to be implemented based on your data structure
  // For now, returning a placeholder
  return "Category 1, Category 2, & Category 3";
};

// export const dominantCategories = (data) => {
// 	const categoryCounts = data.reduce((acc, card) => {
// 		card.categories.forEach((category) => {
// 			if (acc[category]) {
// 				acc[category]++;
// 			} else {
// 				acc[category] = 1;
// 			}
// 		});
// 		return acc;
// 	}, {});

// 	// Convert the object into an array of [category, count] and sort by count descending
// 	const sortedCategories = Object.entries(categoryCounts).sort(
// 		(a, b) => Number(b[1]) - Number(a[1])
// 	);

// 	// Take the top three categories
// 	const topThreeCategories = sortedCategories
// 		.slice(0, 3)
// 		.map((item) => item[0]);

// 	// Format the categories based on their count
// 	if (topThreeCategories.length === 2) {
// 		return `${topThreeCategories[0]} & ${topThreeCategories[1]}`;
// 	} else if (topThreeCategories.length >= 3) {
// 		return `${topThreeCategories[0]}, ${topThreeCategories[1]}, & ${topThreeCategories[2]}`;
// 	} else {
// 		return topThreeCategories.join(", ");
// 	}
// };

export const tableRationale = () => (
  <p>Table Rationale</p>
  // <div className="flex flex-col mb-4">
  // 	<h2 className="text-[20px] font-semibold capitalize">{groupKey} Group</h2>

  // 	<div className="mt-2">
  // 		<p className="text-[15px] text-neutral_600">
  // 			Your
  // 			<span className="capitalize"> {groupKey} </span> group has{" "}
  // 			<span className="font-bold">
  // 				{filteredGroupData.length} creator
  // 				{filteredGroupData.length > 1 ? "s" : ""}
  // 			</span>
  // 			{filteredGroupData.length > 1 ? " combining for " : " with "}
  // 			<span className="font-bold">
  // 				{calculateTotalFollowers(filteredGroupData, groupKey)} followers
  // 			</span>
  // 			{", "}
  // 			focused most on{" "}
  // 			<span className="font-bold">
  // 				{dominantCategories(filteredGroupData)}
  // 			</span>
  // 		</p>
  // 	</div>
  // </div>
);

export const carouselRationale = (
  <p>Carousel Rationale</p>
  // filteredCardData.length === 0 ? (
  // 	<div className="flex h-full flex-col gap-4 carousel-card-sizing items-left justify-center pr-[20px]">
  // 		<h4 className="capitalize">
  // 			{currentState === null ? "All" : currentState} //{"  "}
  // 			{selectedGroup === null ? "All Groups" : selectedGroup}
  // 		</h4>
  // 		<div className="pr-5">
  // 			<h2 className="text-3xl leading-[1.4]">There is nobody here.</h2>
  // 		</div>
  // 	</div>
  // ) : (
  // 	<div className="flex h-full flex-col gap-4 carousel-card-sizing items-left justify-center pr-[20px]">
  // 		<h4 className="capitalize">
  // 			{currentState === null ? "All" : currentState} //{"  "}
  // 			{selectedGroup === null ? "All Groups" : selectedGroup}
  // 		</h4>
  // 		<div className="pr-6">
  // 			<h2 className="text-[30px] leading-[1.4]">
  // 				Your{" "}
  // 				<span className="">
  // 					{currentState === null ? "entire" : currentState} {selectedGroup}{" "}
  // 				</span>{" "}
  // 				group has{" "}
  // 				<span className="font-bold">
  // 					{filteredCardData.length} creator
  // 					{filteredCardData.length > 1 ? "s" : ""}
  // 				</span>
  // 				{filteredCardData.length > 1 ? " combining for " : " with "}
  // 				<span className="font-bold">
  // 					{calculateTotalFollowers(filteredCardData, selectedGroup)}{" "}
  // 					followers
  // 				</span>
  // 				{", "}
  // 				focused most on{" "}
  // 				<span className="font-bold">
  // 					{dominantCategories(filteredCardData)}
  // 				</span>
  // 			</h2>
  // 		</div>
  // 	</div>
);
