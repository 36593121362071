import FButton from "@components/FButton";
import { FInput } from "@components/FInputs";

interface PermalinkInputProps {
  pendingUrl: string;
  isSubmitted: boolean;
  onChange: (value: string) => void;
  onSubmit: () => void;
}

const PermalinkInput = ({
  pendingUrl,
  isSubmitted,
  onChange,
  onSubmit,
}: PermalinkInputProps) => {
  return (
    <div className="flex items-end gap-2 mb-4 w-[600px]">
      <FInput
        label="Add permalink URL for off-platform sample content"
        className="w-full"
        value={pendingUrl}
        validate={isSubmitted}
        onChange={onChange}
        required
        width="100%"
        placeholder="Paste URL here"
      />
      <FButton onClick={onSubmit} primary label="Submit" />
    </div>
  );
};

export default PermalinkInput;
