import React, { useContext } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import MultiPageLayout from "@layouts/MultiPageLayout";
import FButton from "@components/FButton";

import useAuth from "@hooks/useAuth";

import toast from "react-hot-toast";
import AmbassadorsList from "./AmbassadorsList";
import { AmbassadorsContext } from "@contexts/index";
import { AMBASSADOR_REVIEW_STATUSES } from "./constants";
import { has } from "lodash";

const subNavItems = AMBASSADOR_REVIEW_STATUSES.map((status) => ({
  ...status,
  label: status.value === "review" ? "Applicants" : status.label,
  link: status.value,
}));

const AmbassadorsContent = () => {
  const { brand, overallTotals } = useContext(AmbassadorsContext);

  const navigate = useNavigate();

  const { user } = useAuth();

  const handleShareLandingPage = () => {
    navigator.clipboard
      .writeText(
        `${window.location.host}/${user.brand_name}/ambassador-onboarding`
      )
      .then(
        () => {
          toast.success("URL copied to clipboard!");
        },
        (err) => {
          toast.error("Failed to copy URL: ", err);
        }
      );
  };

  const subNavItemsWithCounts = subNavItems.map((navItem) => {
    const { label, value } = navItem;

    const total = has(overallTotals, value) ? overallTotals[value] : null;

    return {
      ...navItem,
      label: total === null ? label : `${label} (${total})`,
    };
  });

  return (
    <MultiPageLayout
      pageName="Ambassadors"
      subNavItems={subNavItemsWithCounts}
      headerButtons={
        <div className="flex flex-row gap-2">
          {brand?.amp_description ? (
            <>
              <FButton
                iconLeft={{ name: "settings", size: 20, color: "black" }}
                label="Settings"
                onClick={() => {
                  navigate("/ambassadors/onboarding-settings");
                }}
              />
              <FButton
                primary
                label="Share Your Page"
                width="w-[140px]"
                onClick={handleShareLandingPage}
              />
            </>
          ) : null}
        </div>
      }
    >
      <div className="pt-[40px]">
        <Routes>
          <Route index element={<Navigate replace to="review" />} />
          {subNavItems.map((item) => (
            <Route
              key={item.value}
              path={`${item.value}/:ambassador_id?`}
              element={<AmbassadorsList key={item.value} status={item.value} />}
            />
          ))}
        </Routes>
      </div>
    </MultiPageLayout>
  );
};

export default AmbassadorsContent;
