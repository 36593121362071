import React, { useEffect, ReactNode, useContext, useRef } from "react";

import { LayoutContext } from "@contexts/index";
import ContentHighlight from "@components/media/content-highlight";
import { Content } from "@types";
import { Creator } from "@apiTypes";
import FButton from "@components/FButton";
import FDropdown, { FDropdownOption } from "@components/FDropdown";
import NavigationButtons from "@components/buttons/NavigationButtons";
import ProfileNav from "@components/Profile/ProfileNav";
import useAuth from "@hooks/useAuth";

export interface CreatorForReview extends Creator {
  status: string;
}

interface CreatorReviewModalContainer {
  onClose: () => void;
  children: ReactNode;
  className?: string;
  profile: CreatorForReview;
  sampleContent: Partial<Content>[];
  activeContentIndex?: number;
  setActiveContentIndex?: (index: number) => void;
  statuses: FDropdownOption[];
  onUpdateStatus: (value: string) => void;
  currentIndex: number;
  onGoToIndex: (index: number) => void;
  participants: CreatorForReview[];
  isCollapsed: boolean;
  onChangeCollapsed: (isCollapsed: boolean) => void;
  topRightContent?: ReactNode;
}

const CreatorReviewModalContainer: React.FC<CreatorReviewModalContainer> = ({
  onClose,
  children,
  className,
  profile,
  sampleContent,
  activeContentIndex,
  setActiveContentIndex,
  statuses,
  onUpdateStatus,
  currentIndex,
  onGoToIndex,
  participants,
  isCollapsed,
  onChangeCollapsed,
  topRightContent,
}) => {
  const { collapsedNavWidth, fullNavWidth, collapsed } =
    useContext(LayoutContext);

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const { user } = useAuth();

  // Handle the escape key
  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleEscape);
    return () => document.removeEventListener("keydown", handleEscape);
  }, [onClose]);

  // Prevent body scroll when modal is open
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const navWidth = collapsed ? collapsedNavWidth : fullNavWidth;

  const navActions = profile ? (
    <div className="w-full px-[40px] relative z-50">
      <div className="py-[14px] border-b w-full flex flex-row justify-between border-light_border">
        <FButton
          className="border-neutral_400 hover:border-neutral_600"
          label="Back"
          iconLeft={{ name: "undo", size: 18 }}
          onClick={onClose}
          style={{ background: "transparent" }}
        />
        <div className="flex flex-row items-center justify-end gap-4">
          {topRightContent}
          <FDropdown
            options={statuses}
            selectedValue={profile.status}
            onChange={onUpdateStatus}
            width="max-content"
          />
          <NavigationButtons
            currentIndex={currentIndex}
            items={participants}
            onGoToIndex={onGoToIndex}
          />
        </div>
      </div>
    </div>
  ) : null;

  return (
    <div className="absolute left-0 top-0 grid grid-cols-[245px_1fr] h-full bg-light_red w-full z-9999">
      <div className="flex flex-col w-[245px] bg-transparent" />
      <div className="w-full relative">
        <div
          className={`bg-light_red absolute z-[19] w-full inset-0 bg-dark_night_sky bg-opacity-70 flex justify-end`}
          onClick={onClose}
        >
          <div
            className={`shadow-2xl w-full max-w-[calc(100vw-${navWidth})] ${className} overflow-y-scroll`}
            onClick={(e) => e.stopPropagation()}
            ref={scrollContainerRef}
          >
            <div
              className={`grid ${
                isCollapsed ? "grid-cols-[40px_1fr]" : "grid-cols-[370px_1fr]"
              }`}
            >
              <ContentHighlight
                data={sampleContent}
                limit={null}
                highlightRight={false}
                collapsed={isCollapsed}
                onChangeCollapsed={onChangeCollapsed}
                currentSlide={activeContentIndex}
                onSlideChange={(index) => setActiveContentIndex(index)}
              />
              <div
                className={`w-full overflow-x-auto mb-[24px] ${user ? "" : "pl-[235px]"}`}
              >
                {navActions}
                {profile ? (
                  <div className="flex gap-4 pr-8">
                    <ProfileNav
                      profile={profile}
                      user={user}
                      isValidProfileHash={false}
                      readOnly
                    />
                  </div>
                ) : null}
                <div className="px-[40px]">{children}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatorReviewModalContainer;
