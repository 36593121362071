import React from "react";
import FIcon from "@components/FIcon";

interface BgCoverImageProps {
  imageUrl: string;
}

const BgCoverImage = ({ imageUrl }: BgCoverImageProps) => {
  const isValid =
    imageUrl && !imageUrl.includes("missing_default") && imageUrl.trim() !== "";

  return (
    <div className="w-full z-0 h-[400px] relative">
      {isValid ? (
        <>
          <div
            className="absolute inset-0 bg-center bg-cover"
            style={{ backgroundImage: `url(${imageUrl})` }}
            role="img"
            aria-label="Cover image"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-[rgba(255,250,249,1)] via-[rgba(255,250,249,0.8)] to-[rgba(255,250,249,0.1)]" />
        </>
      ) : (
        <div className="w-full h-full flex items-center justify-center">
          <FIcon icon="image" size={40} color="#000F45" />
        </div>
      )}
    </div>
  );
};

export default BgCoverImage;
