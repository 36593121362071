import { ProfileContext } from "@contexts/index";
import React, { useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import FButton from "./FButton";
import { CampaignBrief } from "@types";

interface Props {
  activeBrief: CampaignBrief;
  className?: string;
  selectedValue?: string | number;
  items: {
    label: string;
    value?: any;
    link?: string;
    active?: boolean;
    disabled?: boolean;
  }[];
  onChange?: (value: string | number) => void;
}

const AddedNav = ({
  activeBrief,
  className = "",
  items = [],
  onChange,
  selectedValue,
}: Props) => {
  const { isBriefNavFixed } = useContext(ProfileContext);

  const { campaign_id } = useParams();

  const navigate = useNavigate();

  const getItemColors = (item) => {
    if (item.disabled) {
      return "text-neutral_400";
    } else if (item.active || item.value === selectedValue) {
      return "cursor-pointer opacity-100";
    } else {
      return "cursor-pointer opacity-50 hover:opacity-75";
    }
  };

  const {
    campaign_id: campaignId,
    participant_id: participantId,
    participant_group_id,
  } = activeBrief;

  const handleGoToPreview = () => {
    if (participantId) {
      navigate(`/participant-brief/${campaign_id}/${participantId}`);
    } else {
      navigate(
        `/brief-preview/${campaignId}${participant_group_id ? `/${participant_group_id}` : ""}`
      );
    }
  };

  const isSettings = location.pathname.includes("settings");

  return (
    <div
      className={`${className} max-w-[250px] pt-24 ${
        isBriefNavFixed !== undefined
          ? isBriefNavFixed
            ? "fixed top-0"
            : "sticky top-0"
          : "sticky top-0"
      }`}
    >
      <div className="mb-6">
        <FButton
          label="Preview"
          onClick={handleGoToPreview}
          className="hover:bg-white min-w-[150px]"
        />
      </div>
      {!isSettings ? (
        <div className="w-full">
          <div className="flex items-start flex-col h-full font text-[15px] font-normal  text-base gap-1">
            {items.map((item, index) => {
              const key = `${item.value}-${index}`;
              if (item.link) {
                return (
                  <Link
                    key={key}
                    to={item.link}
                    className={`py-[10px] text-center text-[15px] whitespace-nowrap relative overflow-visible ${getItemColors(
                      item
                    )}`}
                    onClick={() => onChange && onChange(item.value)}
                  >
                    {item.label}
                  </Link>
                );
              } else {
                return (
                  <div key={key} className="relative">
                    {(item.active || item.value === selectedValue) && (
                      <div className="absolute z-10 bg-dark_night_sky h-[20px] w-[2px] top-[12px] -left-[15px]" />
                    )}
                    <p
                      className={`py-[10px] text-[15px] text-center whitespace-nowrap ${getItemColors(
                        item
                      )}`}
                      onClick={() => {
                        if (item.disabled) return;
                        if (onChange) onChange(item.value);
                      }}
                    >
                      {item.label}
                    </p>
                  </div>
                );
              }
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default AddedNav;
