import React, { useContext, useEffect, useState } from "react";

import ViewershipOverTime from "./ViewershipOverTime";
import { ProfileContext } from "@contexts/index";
import LoadingSpinner from "@components/LoadingSpinner";
import FDropdown from "@components/FDropdown";
import { capitalize } from "lodash";
import StatCardWithGradientBar from "./components/StatCardWithGradientBar";
import ChipsSection from "./components/PillsSection";
import { ViewsData } from "@types";
import { getViewsData } from "@api/Profile";
import useAuth from "@hooks/useAuth";
import ContentGuard from "./components/ContentGuard";

const getStats = (viewsData: ViewsData) => {
  const {
    total_views,
    average_views,
    viral_posts_count,
    viral_driven_views,
    viewership_benchmark,
  } = viewsData;

  return [
    {
      name: "Total",
      value: total_views,
      tier_average: 0,
    },
    {
      name: "Average",
      value: average_views,
      tier_average: viewership_benchmark,
    },
    {
      name: "# Viral Posts",
      value: viral_posts_count,
      tier_average: 1,
    },
    {
      name: "Viral Driven Views",
      value: viral_driven_views,
      tier_average: 0.11,
    },
  ];
};

export default function ProfileBasics() {
  const { profile, topContent = [] } = useContext(ProfileContext);

  const [loaded, setLoaded] = useState(false);

  const [selectedPlatform, setSelectedPlatform] = useState("");

  const [viewsData, setViewsData] = useState<ViewsData>(null);

  const { user } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      if (!user) {
        setLoaded(true);

        setViewsData({
          total_views: 0,
          average_views: 0,
          viral_posts_count: 0,
          viral_post_views: 0,
          viral_driven_views: 0,
          viewership_benchmark: 0,
        });

        return;
      }

      setLoaded(false);

      const viewsDataResponse = await getViewsData(
        profile.slug,
        selectedPlatform
      );

      setViewsData(viewsDataResponse.data);

      setLoaded(true);
    };

    fetchData();
  }, [selectedPlatform, profile.slug, user]);

  if (!profile || !loaded) {
    return (
      <div className="mt-[200px]">
        <LoadingSpinner />
      </div>
    );
  }

  const { platforms } = profile;

  return (
    <div className="mt-6 w-full relative">
      <div className="flex justify-between items-center mb-4 py-2 w-full">
        <span className="text-xl">Views</span>
        <div className="flex gap-2">
          <FDropdown
            width="100%"
            options={[
              ...platforms.map(({ name }) => ({
                value: name,
                label: name === "tiktok" ? "TikTok" : capitalize(name),
              })),
            ]}
            selectedValue={selectedPlatform}
            onChange={(value) => setSelectedPlatform(value as string)}
          />
        </div>
      </div>
      <div className="">
        <div className="flex gap-4 justify-between mb-10">
          {getStats(viewsData).map(({ name, value, tier_average }) => (
            <StatCardWithGradientBar
              key={name}
              title={name}
              value={value}
              tier_average={tier_average}
            />
          ))}
        </div>
        <ViewershipOverTime
          content={topContent}
          platforms={platforms}
          selectedPlatform={selectedPlatform}
        />

        {user ? (
          <div className="grid grid-cols-3 gap-4">
            <ChipsSection
              items={profile.top_sponsored_brands}
              title="Top Sponsored Brands Mentioned"
            />
            <ChipsSection
              items={profile.top_organic_brands}
              title="Top Organic Brands Mentioned"
            />
            <ChipsSection
              items={profile.top_post_categories}
              title="Top Post Categories"
            />
          </div>
        ) : null}
      </div>
      <ContentGuard />
    </div>
  );
}
