const ApplicationSubmitted = () => {
  return (
    <div className="flex flex-col justify-center h-full sm:h-[400px]">
      <span className="text-center">
        Your application has been submitted! Make sure your social profiles stay
        public during this time. Stay tuned—updates will be heading to your
        inbox soon!
      </span>
    </div>
  );
};

export default ApplicationSubmitted;
