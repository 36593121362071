import { ApiResponse, CreatorFromSearch } from "@apiTypes";
import apiWrapper from "@apiWrapper";
import { AdvancedSearchParams } from "@pages/search/types";

import { getSearchParts } from "./Search";
import { getSearchString } from "@utils/history";
import { Ambassador, AmbassadorTotals } from "types/ambassador";

export const searchCreators = (
  params: Partial<AdvancedSearchParams>
): Promise<
  ApiResponse<{
    overall_totals: AmbassadorTotals;
    results: Ambassador[];
    total: number;
  }>
> => {
  const { searchTerm, ...rest } = params;

  const paramsToUse = {
    ...rest,
    ...getSearchParts(searchTerm),
  };

  return apiWrapper(
    `/search/ambassadors?${getSearchString(paramsToUse)}`,
    "GET"
  );
};

export const searchPosts = (params: {
  hashtags?: string[];
  mentions?: string[];
  any?: string[];
  platforms?: string[];
  categories?: string[];
  creator_ids?: number[];
  p?: number;
}): Promise<
  ApiResponse<{
    results: CreatorFromSearch[];
    total: number;
  }>
> => apiWrapper(`/search/ambassador_content?${getSearchString(params)}`, "GET");
