import React from "react";

interface ButtonProps {
  className?: string;
  options: {
    label: string;
    value: number | string;
  }[];
  defaultValue?: string | number;
  selectedValue?: string | number;
  onChange: (arg: string | number) => void;
  style?: any;
}

const FButtonSelect = ({
  className = "",
  options = [],
  selectedValue,
  onChange,
  style = {},
}: ButtonProps) => {
  const handleClick = (value: string | number) => {
    onChange(value);
  };

  return (
    <div style={style}>
      <div
        className={`inline-flex border border-neutral_400 rounded-lg overflow-hidden h-[40px] ${className}`}
      >
        {options.map((o, i) => {
          const isSelected = o.value === selectedValue;

          return (
            <React.Fragment key={o.value}>
              <div
                className={`flex  all-center whitespace-nowrap py-[12px] px-[16px] cursor-pointer
                  text-[14px] ${isSelected ? "bg-white font-medium text-black" : "font-normal text-[rgba(0,0,0,0.6)] hover:text-black"}`}
                onClick={() => handleClick(o.value)}
              >
                {o.label}
              </div>
              {options[i + 1] && (
                <div className="inline-block h-[40px] w-[1px] bg-neutral_400"></div>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default FButtonSelect;
