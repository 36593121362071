import {
  deleteParticipantContent,
  getCampaignParticipantContent,
} from "@api/Campaign/CampaignParticipantContent";

import BlankState from "@components/BlankState";
import FButton from "@components/FButton";
import FDropdown from "@components/FDropdown";
import LoadingSpinner from "@components/LoadingSpinner";
import GeneralPostModal from "@components/Modals/GeneralPostModal";
import ResultsGrid from "@components/ResultsGrid";

import { CampaignContext } from "@contexts/index";
import { CampaignContent, Participant } from "@types";
import { isEmpty } from "lodash";
import { useContext, useEffect, useState } from "react";
import AddParticipantContentModal from "./components/AddParticipantContentModal";
import toast from "react-hot-toast";
import moment from "moment";
import ViewStatsScreenshotModal from "./components/ViewStatsScreenshotModal";
import { POST_CATEGORIES } from "@api/Search";

const getPostWithParticipant = (
  post: CampaignContent,
  participants: Participant[]
) => {
  const participant = participants.find(({ id }) => id === post.participant_id);

  const { creator } = participant || {};

  const { firstName, lastName, slug, profile_image_url } = creator || {};

  const { insights, media_product_type, published_at, reach_count } = post;

  const {
    insight_categories = [],
    insight_impressions,
    insight_interactions,
    insight_likes_count,
    insight_comments_count
  } = insights || {};

  const categories = insight_categories
    .map((id) => POST_CATEGORIES[id])
    .join(", ");

  return {
    ...post,
    full_name: `${firstName} ${lastName}`,
    slug,
    profile_image: profile_image_url,
    published_at: moment(published_at).format("MMM D, YYYY"),
    categories,
    views_count: insight_impressions || post.data.views_count || post.data.unique_stats.views_count,
    interactions: insight_interactions || post.data.interactions,
    likes_count: insight_likes_count || post.data.likes_count,
    comments_count: insight_comments_count || post.data.comments_count,
    followers: post.impressions,
    profile_link: slug ? `/card/${slug}` : null,
    username: post.social_username,
    content_type: media_product_type,
    reach: reach_count || post.data.reach_count,
    // TODO: this is to avoid having the dropdown cut off by content card. Figure out how to handle zIndex in cards differently
    hoverZIndex: 1,
  };
};

const getPosts = (
  participantContents: CampaignContent[],
  participants: Participant[]
) =>
  participantContents.map((content) =>
    getPostWithParticipant(content, participants)
  );

const AttributedContent = () => {
  const { campaign } = useContext(CampaignContext);

  const [loading, setLoading] = useState(false);

  const { participants } = campaign;

  const [participantContents, setParticipantContents] = useState<
    CampaignContent[]
  >([]);

  const [activePost, setActivePost] = useState<CampaignContent>(null);

  const [activeStatsScreenshot, setActiveStatsScreenshot] =
    useState<CampaignContent>(null);

  const [isAddContentModalVisible, setAddContentModalVisible] = useState(false);

  const [selectedParticipant, setSelectedParticipant] =
    useState<Participant>(null);

  useEffect(() => {
    const loadContent = async () => {
      setLoading(true);

      const participantContentsResponse = await getCampaignParticipantContent(
        campaign.id
      );

      const participantContents =
        participantContentsResponse.data.participant_contents;

      setParticipantContents(participantContents);
      setLoading(false);
    };

    loadContent();
  }, []);

  const handleAddContentManually = () => setAddContentModalVisible(true);

  const handleNewContentCreated = (content: CampaignContent) => {
    setParticipantContents([content, ...participantContents]);

    setAddContentModalVisible(false);
  };

  const handleDeleteParticipantContent = async (id: number) => {
    try {
      await deleteParticipantContent(id);

      toast.success("Content deleted");

      setParticipantContents(
        participantContents.filter((content) => content.id !== id)
      );
    } catch {
      toast.error("Error deleting participant content");
    }
  };

  const posts = getPosts(
    participantContents.filter(
      ({ participant_id }) =>
        !selectedParticipant || participant_id === selectedParticipant.id
    ),
    participants
  );

  return (
    <div className="pt-8">
      {loading ? (
        <LoadingSpinner className="w-full h-full mt-[20%]" />
      ) : (
        <>
          <div className="flex items-center justify-between mt-4">
            <div className="flex items-center gap-4">
              <h2 className="text-2xl">All Content</h2>
            </div>
            <div className="flex items-center gap-4">
              <FDropdown
                placeholder="Select participant"
                zIndex={99999}
                hasNoDefault
                options={[
                  { value: null, label: "All Participants" },
                  ...participants.map(({ id, creator }) => ({
                    value: id,
                    label: `${creator.firstName} ${creator.lastName}`,
                  })),
                ]}
                selectedValue={selectedParticipant?.id}
                onChange={(id) =>
                  setSelectedParticipant(
                    participants.find((participant) => participant.id === id)
                  )
                }
              />
              {selectedParticipant ? (
                <FButton
                  label="Add content manually"
                  primary
                  onClick={handleAddContentManually}
                />
              ) : null}
            </div>
          </div>
          {!isEmpty(posts) ? (
            <ResultsGrid
              cardData={posts}
              cardType="content"
              onCardClick={(content) => setActivePost(content)}
              onDelete={handleDeleteParticipantContent}
              onViewStatsScreenshot={(post) => setActiveStatsScreenshot(post)}
            />
          ) : (
            <BlankState title="No content found" subtitle="" icon="content" />
          )}
          {activePost && (
            <GeneralPostModal
              post={getPostWithParticipant(activePost, participants)}
              isOpen
              onClose={() => setActivePost(null)}
            />
          )}
          {isAddContentModalVisible && selectedParticipant ? (
            <AddParticipantContentModal
              onClose={() => setAddContentModalVisible(false)}
              onCreated={handleNewContentCreated}
              participant={selectedParticipant}
            />
          ) : null}
          {activeStatsScreenshot ? (
            <ViewStatsScreenshotModal
              post={activeStatsScreenshot}
              onClose={() => setActiveStatsScreenshot(null)}
            />
          ) : null}
        </>
      )}
    </div>
  );
};

export default AttributedContent;
