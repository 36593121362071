import { formatLargeNumber } from "@utils/number_utilities";
import GradientBar from "./GradientBar";
import { isNumber } from "lodash";

interface StatCardWithGradientBarProps {
  title: string;
  value: number;
  tier_average: number;
  isPercentage?: boolean;
  hideComparison?: boolean;
}

const StatCardWithGradientBar = ({
  title,
  value,
  tier_average,
  isPercentage,
  hideComparison,
}: StatCardWithGradientBarProps) => {
  const difference = tier_average ? value - tier_average : 0;

  const relativeDifference = difference / tier_average;

  return (
    <div className="flex-1 px-4">
      <p className="font-xs text-default_weak mb-0.5">{title}</p>
      <p className="text-xl mb-2">
        {isNumber(value) ? formatLargeNumber(value) : "-"}
        {isPercentage ? "%" : ""}
      </p>
      {!hideComparison ? (
        <>
          {!isNaN(relativeDifference) ? (
            <div className="flex font-xs gap-1">
              {difference !== 0 ? (
                <span className={difference > 0 ? "text-dill" : "text-red"}>
                  {difference > 0 ? "+" : ""}
                  {Math.round(relativeDifference * 100)}%
                </span>
              ) : null}
              <span className="text-default_weak">
                {difference === 0
                  ? "same as"
                  : difference > 0
                    ? "above"
                    : "below"}{" "}
                tier
              </span>
            </div>
          ) : (
            <p className="text-default_weak mb-9">No data available</p>
          )}
          <GradientBar relativeDifference={relativeDifference} />
        </>
      ) : null}
    </div>
  );
};

export default StatCardWithGradientBar;
